<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light position-fixed w-100" style="
    z-index: 999999999;
">
      <a class="navbar-brand" href="#">vCIP</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" style="justify-content: flex-end" id="navbarNavAltMarkup">
        <div class="navbar-nav" style="column-gap: 30px">
          <a class="nav-item nav-link active" href="#">
            <span class="material-symbols-outlined"> border_color </span>
            <span>Apply</span>
          </a>
          <a class="nav-item nav-link" href="#">
            <span class="material-symbols-outlined"> group </span>
            <span>Users</span>
          </a>
          <a class="nav-item nav-link" href="#">
            <span class="material-symbols-outlined"> article </span>
            <span>Docs</span>
          </a>
          <a class="nav-item nav-link" href="#">
            <span class="material-symbols-outlined"> insert_chart </span>
            <span>Reports</span>
          </a>
          <a class="nav-item nav-link" href="#">
            <span class="material-symbols-outlined"> local_police </span>
            <span>Policy</span>
          </a>
          <a class="nav-item nav-link" href="#">
            <span class="material-symbols-outlined" @click="logout"> logout </span>
            <span>Logout</span>
          </a>
        </div>
      </div>
    </nav>

    <div class="row pb-4 py-2 ">
   
      <div class="col-md-8 mt87">
        <section class="form-section position-fixed w75">
          <div class="d-flex flex-row gap-5 justify-between align-items-center pt-2">
            <!-- Room ID - {{ roomId }} -->
            <!-- <button color="primary" id="toggleid" @click="toggleRoom" style="margin-top: 28px" class="btn btn-primary">
              {{ hasJoined ? "Leave Room" : "Join Room" }}
            </button>
            <button @click="captureScreen()" class="btn btn-primary">Capture</button> -->
          </div>
          <div v-if="roomJoined">
            You have joined room successfully
          </div>
          <canvas ref="screenshotCanvas" style="display: none"></canvas>

          <img ref="screenshotImage" alt="Screenshot" v-if="captured" />
          <div>
            <vue-webrtc id="call-canvas" :roomId="roomId" ref="webrtc" v-on:share-started="shareStarted" class="w-full"
              cameraHeight="300" v-on:share-stopped="leftRoom" v-on:left-room="leftRoom" v-on:joined-room="joinedRoom"
              width="100%" />
          </div>

          <div class="d-flex flex-row gap-5 justify-between align-items-center">
            <!-- Room ID - {{ roomId }} -->
            <button color="primary" id="toggleid" @click="toggleRoom" style="margin-top: 28px" class="btn btn-primary">
              {{ hasJoined ? "Leave Room" : "Join Room" }}
            </button>
            <button @click="captureScreen()" class="btn btn-primary">Capture</button>
          </div>
        </section>
      </div>
      <div class="col-md-4 d-flex flex-column gap-2 overflow-y-auto mt87">
        <div class="accordion pt-2" id="accordionExample">
          <div class="accordion-item">
            <div class="accordion-header" id="personalDetailsHeading">
              <div class="vcip-options" @click="toggleFlexDiv">
                <div :class="{
                  'selected-step': selectedSteps.Acceptance,
                  'unselected-step': !selectedSteps.Acceptance,
                }">
                  <div class="d-flex flex-row justify-content-between gap55">
                    <div style="width: 111px">1.&nbsp;&nbsp; Acceptance</div>
                    <div>
                      <i class="fas fa-chevron-down"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="personalDetailsCollapse" class="accordion-collapse collapse show"
              aria-labelledby="personalDetailsHeading" data-bs-parent="#accordionExample">
              <div class="">
                <div class="d-flex flex-row gap-5" :style="{ display: flexDivDisplay }">
                  <div class="form-box">
                    <div class="login-page2">
                      <div class="login-box">
                        <div class="">
                          <!-- <div class="vcip-heading d-flex">
                                  Acceptance check
                                </div>
                                <div class="acceptane-options-line">
                                  <div class="acceptance-items">
                                    <div class="circle active-circle">
                                      <div class="inner-circle"></div>
                                    </div>
                                  </div>
                                  <div class="acceptance-items">
                                    <div class="circle">
                                      <div class="inner-circle"></div>
                                    </div>
                                  </div>
                                  <div class="acceptance-items">
                                    <div class="circle">
                                      <div class="inner-circle"></div>
                                    </div>
                                  </div>
                                </div>
                                <div class="acceptance-options d-flex">
                                  <div class="active-btn">1</div>
                                  <div>2</div>
                                  <div>3</div>
                                </div> -->
                          <div class="vcip-options-div">
                            <!-- <div class="d-flex flex-row justify-content-between px-3 py-3 mx-1"> -->
                              <!-- <div class="vcip-options" v-if="!caseData2?.acceptanceCheck?.lightCheck">
  <div class="vcip-options-items">
    <span :class="[
      'right-icon',
      caseData2?.acceptanceCheck?.lightCheck !== 'Good' ? 'bg-warning' : '',
      'material-symbols-outlined'
    ]">{{ caseData2?.acceptanceCheck?.lightCheck !== 'Good' ? 'clear' : '' }}</span>
    Light check
  </div>
</div>

<div class="vcip-options" v-if="caseData2?.acceptanceCheck?.lightCheck">
  <div class="vcip-options-items">
    <span class="right-icon bg-good"><span class="material-symbols-outlined">
        done
      </span></span>
    Light check
  </div>
  <div class="vcip-options-items good">
    {{ caseData2?.acceptanceCheck?.lightCheck }}
    &nbsp;&nbsp;
    <span class="material-symbols-outlined">
      chevron_right
    </span>
  </div>
</div> -->


<!-- <div class="vcip-options-items">
  <span :class="[
    'right-icon',
    caseData2?.acceptanceCheck?.lightCheck === 'Good' ? 'bg-good' : 'bg-warning'
  ]">
    <span class="material-symbols-outlined">
      {{ caseData2?.acceptanceCheck?.lightCheck === 'Good' ? 'done' : 'clear' }}
    </span>
  </span>
  Light check
</div>
<div :class="[
  'vcip-options-items',
  'gap-3',
  caseData2?.acceptanceCheck?.lightCheck === 'Good' ? 'good' : 'recheck'
]">
  {{ caseData2?.acceptanceCheck?.lightCheck }}

  <span class="material-symbols-outlined">chevron_right</span>
</div> -->






                            <!-- </div> -->
                            <!-- <div class="vcip-options" v-if="!caseData2?.acceptanceCheck?.lightCheck">
  <div class="vcip-options-items">
    Light check
  </div>
</div> -->

<!-- <div class="vcip-options" v-if="caseData2?.acceptanceCheck?.audioVideoCheck" @click="handleClick()">
  <div class="vcip-options-items">
    <span :class="[
      'right-icon',
      caseData2?.acceptanceCheck?.audioVideoCheck === 'Good' ? 'bg-good' : 'bg-warning',
    ]">
      <span class="material-symbols-outlined">
        {{ caseData2?.acceptanceCheck?.audioVideoCheck === 'Good' ? 'done' : 'exclamation' }}
      </span>
    </span>
    Audio & Video check
  </div>
  <div :class="[
    'vcip-options-items',
    caseData2?.acceptanceCheck?.audioVideoCheck === 'Good' ? 'good' : 'recheck',
  ]">
    {{ caseData2?.acceptanceCheck?.audioVideoCheck }}
    &nbsp;&nbsp;
    <span class="material-symbols-outlined">
      chevron_right
    </span>
  </div>
</div> -->

<!-- <div class="vcip-options" v-if="!caseData2?.acceptanceCheck?.lightCheck" @click="handleClick()">
  <div class="vcip-options-items">
    Light check
  </div>
</div>

<div class="vcip-options" v-if="caseData2?.acceptanceCheck?.lightCheck">
  <div class="vcip-options-items">
    <span class="right-icon" :class="[
      caseData2?.acceptanceCheck?.lightCheck === 'Good' ? 'bg-good' : 'bg-warning'
    ]">
      <span class="material-symbols-outlined">
        {{ caseData2?.acceptanceCheck?.lightCheck === 'Good' ? 'done' : 'clear' }}
      </span>
    </span>
    Light check
  </div>
  <div :class="[
    'vcip-options-items',
    caseData2?.acceptanceCheck?.audioVideoCheck === 'Good' ? 'good' : 'recheck',
  ]">
    {{ caseData2?.acceptanceCheck?.lightCheck }}
    &nbsp;&nbsp;
    <span class="material-symbols-outlined">
      chevron_right
    </span>
  </div>
</div> -->

<div class="vcip-options" v-if="!caseData2?.acceptanceCheck?.lightCheck">
  <div class="vcip-options-items">
    Light check
  </div>
</div>

<div class="vcip-options" v-if="caseData2?.acceptanceCheck?.lightCheck">
  <div class="vcip-options-items">
    <span class="right-icon" :class="[
      caseData2?.acceptanceCheck?.lightCheck === 'Good' ? 'bg-good' : 'bg-warning'
    ]">
      <span class="material-symbols-outlined">
        {{ caseData2?.acceptanceCheck?.lightCheck === 'Good' ? 'done' : 'clear' }}
      </span>
    </span>
    Light check
  </div>
  <div :class="[
    'vcip-options-items',
    caseData2?.acceptanceCheck?.lightCheck === 'Good' ? 'good' : 'recheck',
  ]">
    {{ caseData2?.acceptanceCheck?.lightCheck }}
    &nbsp;&nbsp;
    <span class="material-symbols-outlined">
      chevron_right
    </span>
  </div>
</div>


<div class="vcip-options" v-if="!caseData2?.acceptanceCheck?.audioVideoCheck" @click="handleClick()">
  <div class="vcip-options-items">
    Audio & Video check
  </div>
</div>

<div class="vcip-options" v-if="caseData2?.acceptanceCheck?.audioVideoCheck">
  <div class="vcip-options-items">
    <span class="right-icon" :class="[
      caseData2?.acceptanceCheck?.audioVideoCheck === 'Good' ? 'bg-good' : 'bg-warning'
    ]">
      <span class="material-symbols-outlined">
        {{ caseData2?.acceptanceCheck?.audioVideoCheck === 'Good' ? 'done' : 'clear' }}
      </span>
    </span>
    Audio & Video check
  </div>
  <div :class="[
    'vcip-options-items',
    caseData2?.acceptanceCheck?.audioVideoCheck === 'Good' ? 'good' : 'recheck',
  ]">
    {{ caseData2?.acceptanceCheck?.audioVideoCheck }}
    &nbsp;&nbsp;
    <span class="material-symbols-outlined">
      chevron_right
    </span>
  </div>
</div>

<div class="vcip-options" v-if="!caseData2?.acceptanceCheck?.backgroundCheck">
  <div class="vcip-options-items">
    Background check
  </div>
</div>

<div class="vcip-options" v-if="caseData2?.acceptanceCheck?.backgroundCheck">
  <div class="vcip-options-items">
    <span class="right-icon" :class="[
      caseData2?.acceptanceCheck?.backgroundCheck === 'Good' ? 'bg-good' : 'bg-warning'
    ]">
      <span class="material-symbols-outlined">
        {{ caseData2?.acceptanceCheck?.backgroundCheck === 'Good' ? 'done' : 'clear' }}
      </span>
    </span>
    Background check
  </div>
  <div :class="[
    'vcip-options-items',
    caseData2?.acceptanceCheck?.backgroundCheck === 'Good' ? 'good' : 'recheck',
  ]">
    {{ caseData2?.acceptanceCheck?.backgroundCheck }}
    &nbsp;&nbsp;
    <span class="material-symbols-outlined">
      chevron_right
    </span>
  </div>
</div>


<div class="vcip-options" v-if="!caseData2?.acceptanceCheck?.latLongCheck">
  <div class="vcip-options-items">
    Lat long check
  </div>
</div>

<div class="vcip-options" v-if="caseData2?.acceptanceCheck?.latLongCheck">
  <div class="vcip-options-items">
    <span class="right-icon" :class="[
      caseData2?.acceptanceCheck?.latLongCheck === 'Good' ? 'bg-good' : 'bg-warning'
    ]">
      <span class="material-symbols-outlined">
        {{ caseData2?.acceptanceCheck?.latLongCheck === 'Good' ? 'done' : 'clear' }}
      </span>
    </span>
    Lat long check
  </div>
  <div :class="[
    'vcip-options-items',
    caseData2?.acceptanceCheck?.latLongCheck === 'Good' ? 'good' : 'recheck',
  ]">
    {{ caseData2?.acceptanceCheck?.latLongCheck }}
    &nbsp;&nbsp;
    <span class="material-symbols-outlined">
      chevron_right
    </span>
  </div>
</div>

<div class="vcip-options">
                        <div class="login-button">
                          <button class="login-form-button thumbnail" @click="navigateToIdentyCheck()"
                            v-if="areAllAcceptanceChecksDone">
                            Next
                          </button>
                        </div>
                        <div class="login-button">
                          <button class="login-form-button thumbnail" v-if="!areAllAcceptanceChecksDone"
                            @click="navigateToLiveAcceptanceCheck(1)">
                            Proceed
                          </button>
                        </div>
                      </div>


                            <!-- <div class="vcip-options" v-if="!caseData2?.acceptanceCheck?.latLongCheck">
                              <div class="vcip-options-items">
                                Lat long check
                              </div>
                            </div>

                            <div class="vcip-options" v-if="caseData2?.acceptanceCheck?.latLongCheck">
                              <div class="vcip-options-items">
                                <span class="right-icon bg-good"><span class="material-symbols-outlined">
                                    done
                                  </span></span>
                                Lat long check
                              </div>
                              <div class="vcip-options-items good">
                                Good &nbsp;&nbsp;
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </div>
                            </div> -->
                            <!-- <div class="vcip-options d-none" @click="vkyc()">
                              <div class="vcip-options-items">Vkyc check</div>
                            </div> -->
                          </div>
                        </div>
                      </div>
            
                    </div>
                    <div class="loader-container" v-if="loader">
                      <div class="loader"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <div class="accordion-header" id="personalDetailsHeading">
              <div class="vcip-options" :class="{ 'selected-step': selectedSteps.Identity }" @click="toggleFlexDiv2">
                <div :class="{
                  'selected-step': selectedSteps.Identity,
                  'unselected-step': !selectedSteps.Identity,
                }">
                  <div class="d-flex flex-row justify-content-between gap57">
                    <div style="width: 111px">2.&nbsp;&nbsp; Identity</div>
                    <div>
                      <i class="fas fa-chevron-down"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="personalDetailsCollapse" class="accordion-collapse collapse show"
              aria-labelledby="personalDetailsHeading" data-bs-parent="#accordionExample">
              <div class="">
                <div class="d-flex flex-row gap-5" :style="{ display: flexDivDisplay2 }">
                  <div class="login-page3">
                    <header class="">
                      <!-- <div class="vcip-heading d-flex">
                              Identity check
                            </div> -->
                      <!-- <div class="acceptane-options-line">
                              <div class="acceptance-items">
                                <div class="circle active-circle active-check">
                                  <div>✔</div>
                                </div>
                              </div>
                              <div class="acceptance-items">
                                <div class="circle active-circle">
                                  <div class="inner-circle"></div>
                                </div>
                              </div>
                              <div class="acceptance-items">
                                <div class="circle">
                                  <div class="inner-circle"></div>
                                </div>
                              </div>
                            </div>
                            <div class="acceptance-options d-flex">
                              <div class="active-btn">1</div>
                              <div class="active-btn">2</div>
                              <div>3</div>
                            </div> -->
                      <div class="">
                        <div class="vcip-options-div">
                          <div>
                            <div class="vcip-options" v-if="!caseData3?.identityCheck?.uploadedPanQuality">
                              <div class="vcip-options-items">Uploaded PAN quality</div>
                            </div>

                            <div class="vcip-options" v-if="caseData3?.identityCheck?.uploadedPanQuality">
                              <div class="vcip-options" v-if="!caseData3?.identityCheck?.uploadedPanQuality">
                                <div class="vcip-options-items">Uploaded PAN quality</div>
                              </div>

 
                                <div class="vcip-options-items">
                                  <span :class="[
                                    'right-icon',
                                    caseData3?.identityCheck?.uploadedPanQuality !== 'Good' ? 'bg-warning' : 'bg-good'
                                  ]">
                                    <span class="material-symbols-outlined">
                                      {{ caseData3?.identityCheck?.uploadedPanQuality !== 'Good' ? 'clear' : 'done' }}
                                    </span>
                                  </span>
                                  Uploaded PAN quality
                                </div>
                                <div :class="[
                                  'vcip-options-items gap-3  ',
                                  caseData3?.identityCheck?.uploadedPanQuality === 'Good' ? 'good' : 'recheck',
                                ]">
                                  {{
                                    caseData3?.identityCheck?.uploadedPanQuality
                                  }}

                                  <span class="material-symbols-outlined">chevron_right</span>
                                </div>
                              </div>

                            </div>

                
                          <!-- <div class="vcip-options">
              <div class="vcip-options-items">Uploaded PAN quality</div>
            </div> -->

                          <div>
                            <div class="vcip-options" v-if="!caseData3?.identityCheck?.livePhotographQuality">
                              <div class="vcip-options-items">
                                Live photograph quality
                              </div>
                            </div>

                            <div class="vcip-options" v-if="caseData3?.identityCheck?.livePhotographQuality">
                              <div class="vcip-options-items">
                                <span :class="[
                                  'right-icon',
                                  caseData3?.identityCheck?.livePhotographQuality !== 'Good' ? 'bg-warning' : 'bg-good'
                                ]">
                                  <span class="material-symbols-outlined">
                                    {{ caseData3?.identityCheck?.livePhotographQuality !== 'Good' ? 'clear' : 'done' }}
                                  </span>
                                </span>
                                Live photograph quality
                              </div>
                              <div :class="[
                                'vcip-options-items',
                                caseData3?.identityCheck?.livePhotographQuality === 'Good' ? 'good' : 'recheck',
                              ]">
                                {{
                                  caseData3?.identityCheck?.livePhotographQuality
                                }}
                                &nbsp;&nbsp;
                                <span class="material-symbols-outlined">chevron_right</span>
                              </div>
                            </div>
                          </div>

                          <!-- <div class="vcip-options">
              <div class="vcip-options-items">Customer liveliness</div>
            </div> -->
                          <div>
                     
                            <div class="vcip-options" v-if="!caseData3?.identityCheck?.customerLiveliness">
                              <div class="vcip-options-items">
                                Customer liveliness
                              </div>
                            </div>

                         
                            <div class="vcip-options" v-if="caseData3?.identityCheck?.customerLiveliness">
                              <div class="vcip-options-items">
                               
                                <span :class="[
                                  'right-icon',
                                  caseData3?.identityCheck?.customerLiveliness !== 'Good' ? 'bg-warning' : 'bg-good'
                                ]">
                                  <span class="material-symbols-outlined">
                                    {{ caseData3?.identityCheck?.customerLiveliness !== 'Good' ? 'clear' : 'done' }}
                                  </span>
                                </span>
                                Customer liveliness
                              </div>
                              <div :class="[
                                'vcip-options-items',
                                caseData3?.identityCheck?.customerLiveliness === 'Good' ? 'good' : 'recheck',
                              ]">
                                
                                {{
                                  caseData3?.identityCheck?.customerLiveliness
                                }}
                                &nbsp;&nbsp;
                      
                                <span class="material-symbols-outlined">chevron_right</span>
                              </div>
                            </div>
                          </div>

                          <!-- <div class="vcip-options">
              <div class="vcip-options-items">Live photograph quality</div>
            </div> -->
                          <div>
                         
                            <div class="vcip-options" v-if="!caseData3?.identityCheck?.liveCapturedPanQuality">
                              <div class="vcip-options-items">
                                Live captured PAN quality
                              </div>
                            </div>

                            <div class="vcip-options" v-if="caseData3?.identityCheck?.liveCapturedPanQuality">
                              <div class="vcip-options-items">
                         
                                <span :class="[
                                  'right-icon',
                                  caseData3?.identityCheck?.liveCapturedPanQuality !== 'Good' ? 'bg-warning' : 'bg-good'
                                ]">
                                  <span class="material-symbols-outlined">
                                    {{ caseData3?.identityCheck?.liveCapturedPanQuality !== 'Good' ? 'clear' : 'done' }}
                                  </span>
                                </span>
                                Live captured PAN quality
                              </div>
                              <div :class="[
                                'vcip-options-items',
                                caseData3?.identityCheck?.liveCapturedPanQuality === 'Good' ? 'good' : 'recheck',
                              ]">
                              
                                {{
                                  caseData3?.identityCheck?.liveCapturedPanQuality
                                }}
                                &nbsp;&nbsp;
                              
                                <span class="material-symbols-outlined">chevron_right</span>
                              </div>
                            </div>
                          </div>

                          <!-- <div class="vcip-options">
              <div class="vcip-options-items">Live captured PAN quality</div>
            </div> -->

                          <div>
                           
                            <div class="vcip-options" v-if="!caseData3?.identityCheck?.faceMatch">
                              <div class="vcip-options-items">Face match</div>
                            </div>

                          
                            <div class="vcip-options" v-if="caseData3?.identityCheck?.faceMatch">
                              <div class="vcip-options-items">
                           
                                <span :class="[
                                  'right-icon',
                                  caseData3?.identityCheck?.faceMatch !== 'Good' ? 'bg-warning' : 'bg-good'
                                ]">
                                  <span class="material-symbols-outlined">
                                    {{ caseData3?.identityCheck?.faceMatch !== 'Good' ? 'clear' : 'done' }}
                                  </span>
                                </span>
                                Face match
                              </div>
                              <div :class="[
                                'vcip-options-items',
                                caseData3?.identityCheck?.faceMatch === 'Good' ? 'good' : 'recheck',
                              ]">
                           
                                {{ caseData3?.identityCheck?.faceMatch }}
                                &nbsp;&nbsp;
                            
                                <span class="material-symbols-outlined">chevron_right</span>
                              </div>
                            </div>
                          </div>

                          <!-- <div class="vcip-options">
              <div class="vcip-options-items">Face match</div>
            </div> -->
                          <div>
                       
                            <div class="vcip-options" v-if="!caseData3?.identityCheck?.randomQuestionnaire">
                              <div class="vcip-options-items">Random questionnaire</div>
                            </div>

                            <div class="vcip-options" v-if="caseData3?.identityCheck?.randomQuestionnaire">
                              <div class="vcip-options-items">
                            
                                <span :class="[
                                  'right-icon',
                                  caseData3?.identityCheck?.randomQuestionnaire !== 'Good' ? 'bg-warning' : 'bg-good'
                                ]">
                                  <span class="material-symbols-outlined">
                                    {{ caseData3?.identityCheck?.randomQuestionnaire !== 'Good' ? 'clear' : 'done' }}
                                  </span>
                                </span>
                                Random questionnaire
                              </div>
                              <div   style="cursor: pointer;" :class="[
                                'vcip-options-items',
                                caseData3?.identityCheck?.randomQuestionnaire === 'Good' ? 'good' : 'recheck',
                              ]" @click="openPopup">
                           
                                {{ caseData3?.identityCheck?.randomQuestionnaire }}
                                &nbsp;&nbsp;
                               
                                <span class="material-symbols-outlined">chevron_right</span>
                              </div>
                            </div>
                          </div>


                          <!-- <div class="vcip-options">
              <div class="vcip-options-items">Random questionnaire</div>
            </div> -->
                        </div>
                      </div>
                    </header>
                    <main>
                      <div>
                        <div class="login-button" v-if="!caseData3?.identityCheck">
                          <button class="login-form-button thumbnail" @click="navigateToLiveIdentityCheck">
                            Proceed
                          </button>
                        </div>
                        <div class="login-button" v-if="caseData3?.identityCheck">
                          <button class="login-form-button thumbnail " @click="navigateToIdentyCheck3()">
                            Next
                          </button>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <div class="accordion-header" id="personalDetailsHeading">
              <div class="vcip-options" :class="{ 'selected-step': selectedSteps.Risk }" @click="toggleFlexDiv3">
                <div :class="{
                  'selected-step': selectedSteps.Risk,
                  'unselected-step': !selectedSteps.Risk,
                }">
                  <div class="d-flex flex-row justify-content-between gap56">
                    <div style="width: 111px">3.&nbsp;&nbsp; Risk</div>
                    <div>
                      <i class="fas fa-chevron-down"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="personalDetailsCollapse" class="accordion-collapse collapse show"
              aria-labelledby="personalDetailsHeading" data-bs-parent="#accordionExample">
              <div class="">
                <div class="d-flex flex-row gap-5" :style="{ display: flexDivDisplay3 }">
                  <div class="form-box">
                    <div class="login-page4">
                      <div class="login-box">
                        <div>
                          <!-- <div class="vcip-heading d-flex">Risk mapping check</div> -->
                          <!-- <div class="acceptane-options-line">
    
                <div class="acceptance-items">
                  <div class="circle active-circle active-check">
                    <div>✔</div>
                  </div>
                </div>
                <div class="acceptance-items">
                  <div class="circle active-circle active-check">
                    <div>✔</div>
                  </div>
                </div>
    
                <div class="acceptance-items">
                  <div class="circle">
                    <div class="inner-circle"></div>
                  </div>
                </div>
              </div>
              <div class="acceptance-options d-flex">
                <div class="active-btn">1</div>
                <div>2</div>
                <div>3</div>
              </div> -->
                          <div class="vcip-options-div">
                            <div>
                              <div class="vcip-options" v-if="
                                !caseData4?.riskMappingCheck
                                  ?.ovdsOcrCorrectness
                              ">
                                <div class="vcip-options-items">
                                  OVDs OCR correctness
                                </div>
                              </div>

                              <div class="vcip-options" v-if="
                                caseData4?.riskMappingCheck
                                  ?.ovdsOcrCorrectness
                              ">
                                <div class="vcip-options-items">
                                  <span class="right-icon bg-good"><span class="material-symbols-outlined">
                                      done
                                    </span></span>
                                  OVDs OCR correctness
                                </div>
                                <div class="vcip-options-items good">
                                  Good &nbsp;&nbsp;
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </div>
                              </div>
                            </div>

                            <!-- <div class="vcip-options">
                    <div class="vcip-options-items">OVDs OCR correctness</div>
                  </div> -->

                            <div>
                              <div class="vcip-options" v-if="!caseData4?.riskMappingCheck?.ovdsMasking">
                                <div class="vcip-options-items">
                                  OVDs masking
                                </div>
                              </div>

                              <div class="vcip-options" v-if="caseData4?.riskMappingCheck?.ovdsMasking">
                                <div class="vcip-options-items">
                                  <span class="right-icon bg-good"><span class="material-symbols-outlined">
                                      done
                                    </span></span>
                                  OVDs masking
                                </div>
                                <div class="vcip-options-items good">
                                  Good &nbsp;&nbsp;
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </div>
                              </div>
                            </div>

                            <!-- <div class="vcip-options">
                  <div class="vcip-options-items">OVDs masking</div>
                </div> -->

                            <div>
                              <div class="vcip-options" v-if="
                                !caseData4?.riskMappingCheck?.xmlValidityCheck
                              ">
                                <div class="vcip-options-items">
                                  XML validity check
                                </div>
                              </div>

                              <div class="vcip-options" v-if="
                                caseData4?.riskMappingCheck?.xmlValidityCheck
                              ">
                                <div class="vcip-options-items">
                                  <span class="right-icon bg-good"><span class="material-symbols-outlined">
                                      done
                                    </span></span>
                                  XML validity check
                                </div>
                                <div class="vcip-options-items good">
                                  Good &nbsp;&nbsp;
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </div>
                              </div>
                            </div>

                            <!-- <div class="vcip-options">
                  <div class="vcip-options-items">XML validity check</div>
                </div> -->

                            <div>
                              <div class="vcip-options" v-if="
                                !caseData4?.riskMappingCheck?.panVerification
                              ">
                                <div class="vcip-options-items">
                                  PAN verification
                                </div>
                              </div>

                              <div class="vcip-options" v-if="
                                caseData4?.riskMappingCheck?.panVerification
                              ">
                                <div class="vcip-options-items">
                                  <span class="right-icon bg-good"><span class="material-symbols-outlined">
                                      done
                                    </span></span>
                                  PAN verification
                                </div>
                                <div class="vcip-options-items good">
                                  Good &nbsp;&nbsp;
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="vcip-options">
                  <div class="vcip-options-items">PAN verification</div>
                </div> -->
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="login-button" v-if="!caseData4?.riskMappingCheck">
                          <button class="login-form-button thumbnail " @click="updateCaseDetails4()">
                            Proceed
                          </button>
                        </div>
                        <div class="login-button" v-if="caseData4?.riskMappingCheck">
                          <button class="login-form-button thumbnail " @click="navigateToIdentyCheck4()">
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="modalVisible" class="modal">
      <div class="modal-content">
        <span class="close" @click="closePopup">&times;</span>
        <span><h1 style="
    font-size: 21px;
">Random Questions</h1></span>
        <table  id="customers">
          <thead>
            <tr>
              <!-- <th>ID</th> -->
              <th>Questions</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in modalData" :key="index">
              <!-- <td>{{ item.id }}</td> -->
              <td>{{ item.title }}</td>
          <td :class="{ 'good': item.answer === 'Good', 'recheck': item.answer === 'recheck' }">{{ item.answer }}</td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <footer>
      <div class="navbar-nav-footer">
        <a class="nav-item nav-link active" href="#">
          <span class="material-symbols-outlined"> border_color </span>
          <span>Apply</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> group </span>
          <span>Users</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> article </span>
          <span>Docs</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> insert_chart </span>
          <span>Reports</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> local_police </span>
          <span>Policy</span>
        </a>
      </div>
    </footer>
  </div>
</template>
<script>
import axios from "axios";
import { VueWebRTC } from "vue-webrtc";
import { io } from "socket.io-client";
import toWav from "audiobuffer-to-wav";
export default {
  name: "vcipDetails",
  components: {
    "vue-webrtc": VueWebRTC,
  },
  data() {
    return {
      agree: false,
      hasJoined: false,
      userStream: {},
      mediaRecorder: {},
      chunks: [],
      captured: false,
      progressStep: "live-acceptance",
      step: -1,
      selectedSteps: {
        Acceptance: false,
        Identity: false,
        Risk: false,
      },
      loader: false,
      caseData: null,
      flexDivDisplay: "flex!important",
      flexDivDisplay2: "none!important",
      flexDivDisplay3: "none!important",
      caseData2: {},
      loader2: false,
      acceptanceCheck: {
        lightCheck: "Good",
        audioVideoCheck: "Recheck",
        backgroundCheck: "Good",
        latLongCheck: "Good",
      },
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      show: false,
      toastMessage: "",
      lightQuality: "",
      lightStatus: "",
      roomId: "",
      roomJoined: false,
      videoId: "",
      personalDetails: {
        name: "",
        lastname: "",
        dob: "",
      },
      url: "",
      lightWarning: "",
      permanentAddress: {
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        pincode: "",
        city: "",
      },
      settings: {},
      latitude: null,
      longitude: null,
      audioContext: {},
      mediaSource: null,
      audioBuffer: null,
      videoElement: null,
      videoWarning: "",
      loader3: false,
      sourceNode: null,
      enableChat: false,
      caseData3: {},
      caseData4: {},
      modalVisible: false,
      modalData: [],
      riskMappingCheck: {
        ovdsOcrCorrectness: "Good",
        ovdsMasking: "Good",
        xmlValidityCheck: "Good",
        panVerification: "Good",
      },
    };
  },


  computed: {
    hasSelectedStep() {
      return Object.values(this.selectedSteps).some((step) => step);
    },
    areAllAcceptanceChecksDone() {
      return (
        this.caseData2?.acceptanceCheck?.lightCheck &&
        this.caseData2?.acceptanceCheck?.audioVideoCheck &&
        this.caseData2?.acceptanceCheck?.backgroundCheck &&
        this.caseData2?.acceptanceCheck?.latLongCheck
      );
    },
  },

  created() {
    // this.roomId = this.$route.query.roomid
    // this.socket = io("http://socket.algofolks.com:3549/", {
    //   transports: ["websocket"],
    // });
    this.fetchCaseById4();
    this.fetchCaseById3();
    this.fetchCaseById();
    this.getDataById();
    this.getData();
    this.liveAcceptanceCheck();
    let step = this.$route?.query?.step;
    this.roomId = this.$route?.query?.id;
    this[`step${step}`] = true;
    if (step == 2) {
      this.acceptanceCheck.audioVideoCheck = "Good";
    }
    this.roomId = this.$route.query.roomid
    this.socket = io("http://localhost:3600/", {
      transports: ["websocket"],
    });


    // this.socket.emit("joinRoom", this.roomId);

    // Listen for incoming messages
    this.socket.on("captureImage", (socketId) => {
      console.log("captureImage call");
      if (this.socket.id == socketId) this.startScreenCapture();
    });
  },
  mounted() {
    this.toggleRoom()
  },
  methods: {
    navigateToAdditionalDetails() {
      this.$router.push("/additionalDetails");
    },
    pushData(e) {
      this.chunks.push(e.data);
    },
    toggleRoom() {
      this.roomJoined = true;
      if (this.roomId) {
        try {
          if (this.hasJoined) {
            this.$refs.webrtc.leave();
            this.hasJoined = false;
          } else {
            this.socket.emit("joinRoom", this.roomId);
            setTimeout(() => (this.roomJoined = false), 1000);
            try {
              this.$refs.webrtc.join().then(() => {
                this.userStream = this.$refs.webrtc.videoList[0].stream;
                this.mediaRecorder = new MediaRecorder(this.userStream);
                this.mediaRecorder.ondataavailable = (e) => this.pushData(e);
                this.mediaRecorder.onstop = () => this.onStop();
                this.hasJoined = true;
              });
            } catch (error) {
              alert("Camera Not Found");
              return;
            }
          }
        } catch (e) {
          alert(e);
        }
      } else {
        console.log("room id not found")
      }
    },
    captureScreen() {
      alert("capturing screeen")
      this.socket.emit("capture", {
        socketid: this.socket.id,
        roomId: this.roomId,
      });
    },
    startScreenCapture() {
      const webrtc = this.$refs.webrtc;

      if (webrtc.capture) {
        try {
          // Capture the screenshot using the capture method
          const screenshotDataURL = webrtc.capture();

          // Display the screenshot as an image
          const screenshotImage = this.$refs.screenshotImage;
          screenshotImage.src = screenshotDataURL;
          this.captured = true
        } catch (error) {
          console.error("Error capturing screenshot:", error);
        }
      } else {
        console.warn(
          "The `capture` method is not available in this vue-webrtc component."
        );
      }
    },

    toggleFlexDiv() {
      this.flexDivDisplay =
        this.flexDivDisplay === "flex!important"
          ? "none!important"
          : "flex!important"; // Toggle the display property
    },
    toggleFlexDiv2() {
      this.flexDivDisplay2 =
        this.flexDivDisplay2 === "flex!important"
          ? "none!important"
          : "flex!important"; // Toggle the display property
    },
    toggleFlexDiv3() {
      this.flexDivDisplay3 =
        this.flexDivDisplay3 === "flex!important"
          ? "none!important"
          : "flex!important"; // Toggle the display property
    },


    async vkyc() {
      this.loader2 = true;
      const endpoint = `vkyc?roomid=${this.$route.query.id}`;
      try {
        this.loader2 = true;
        this.$router.push(endpoint);
      } catch (error) {
        this.loader2 = true;
        console.log(error);
      }
    },


    openPopup() {
      this.modalVisible = true;
      const data = JSON.parse(localStorage.getItem("RandomQueAnswer"));
      this.modalData = data;
      if (data) {
        this.modalData = data;
      }
    },

    navigateToLiveIdentityCheck() {
      const id = this.$route.query.roomid;
      this.id = id;
      this.progressStep = "identity-check";
    },



    async getData() {
      try {
        // this.loader = true;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.get(`${apiUrl}/setting/getSetting`);
        this.settings = response?.data?.settings[0];
        // console.log(response)
      } catch (error) {
        console.log(error);
      }
    },

    navigateToIdentyCheck3() {
      this.flexDivDisplay = "none!important";
      this.flexDivDisplay2 = "none!important";
      this.flexDivDisplay3 = "flex!important";
    },


    async updateCaseDetails4() {
      const updateData = {
        riskMappingCheck: this.riskMappingCheck,
      };

      try {
        const id = this.$route.query.roomid;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.put(
          `${apiUrl}/case/update/${id}`,
          updateData
        );
        console.log("Update response:", response);
        this.flexDivDisplay = "none!important";
        this.flexDivDisplay2 = "none!important";
        this.flexDivDisplay3 = "flex!important";
        this.fetchCaseById();
      } catch (error) {
        console.error("Update error:", error);
      }
    },






    onLiveComplete() {
      this.progressStep = "identity-check";
      this.fetchCaseById();
      this.fetchCaseById3();
      this.fetchCaseById4();
    },
    navigateToIdentityCheck() {
      this.$router.push("/identityCheck");
    },
    navigateToIdentyCheck() {
      // const id = this.$route.query.id;
      // this.$router.push("/acceptanceCheck?id=" + id);
      this.progressStep = "identity-check";

      this.flexDivDisplay = "none!important";
      this.flexDivDisplay2 = "flex!important";
      this.flexDivDisplay3 = "none!important";
    },

    async navigateToAcceptanceCheck() {
      this.loader = true;
      const id = this.$route.query.roomid;
      const scriptElement = document.querySelector("script[api-url]");
      const api = scriptElement.getAttribute("api-url");
      const apiUrl = `${api}/case/update/${id}`;
      const updateData = {
        selectedSteps: this.selectedSteps,
        status: `Selected Stap Done`,
      };

      try {
        const response = await axios.put(apiUrl, updateData);
        const endpoint = this.selectedSteps.Acceptance
          ? `acceptanceCheck?id=${id}`
          : this.selectedSteps.Identity
            ? `identityCheck?id=${id}`
            : `riskMappingCheck?id=${id}`;
        // const endpoint = `acceptanceCheck?id=${id}`;
        const data = {
          endpoint,
          id: id,
        };
        await axios.post(`${api}/case/sendVkycLink`, data);

        if (response.data.message === "Case successfully updated") {
          this.loader = false;
          this.$router.push(endpoint);
          // this.navigateToIdentyCheck()
        } else {
          this.loader = false;
          console.error("Update failed:", response.data.message);
        }
      } catch (error) {
        this.loader = false;
        console.error("API call error:", error);
      }
    },

    toggleStep(step) {
      this.selectedSteps[step] = !this.selectedSteps[step];
    },

    navigateToLiveAcceptanceCheck(step) {
      // const id = this.$route.query.id;
      // this.id = id;
      //this.$router.push(`/liveAcceptanceCheck?id=${id}&step=${step}`);
      this.step = step;
    },
    handleClick() {
      if (this.caseData2?.acceptanceCheck?.audioVideoCheck === "Recheck") {
        this.navigateToLiveAcceptanceCheck(2);
      }
    },

    navigateToIdentyCheck2() {
      const id = this.$route.query.roomid;
      const endpoint = this.caseData2?.selectedSteps?.Identity
        ? `identityCheck?id=${id}`
        : this.caseData2?.selectedSteps?.Risk
          ? `riskMappingCheck?id=${id}`
          : `auditCheck?id=${id}`;
      this.$router.push(endpoint);
    },

    async fetchCaseById() {
      try {
        const id = this.$route.query.roomid;
        const scriptElement = document.querySelector("script[api-url]");
        const api = scriptElement.getAttribute("api-url");
        const apiUrl = `${api}/case/getById/${id}`;
        const response = await axios.post(apiUrl);
        this.caseData2 = response.data;
        console.log("Case data:", this.caseData2);
        this.flexDivDisplay = "flex!important";
        this.flexDivDisplay2 = "none!important";
        this.flexDivDisplay3 = "none!important";
      } catch (error) {
        console.error("Error fetching case data:", error);
      }
    },

    async liveAcceptanceCheck() {
      const updateData = {
        status: "VKYC_Ongoing",
      };
      try {
        const id = this.$route.query.roomid;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.put(
          `${apiUrl}/case/update/${id}`,
          updateData
        );
        console.log(response);
      } catch (error) {
        console.error("Update error:", error);
      }
    },
    handleEvent() {
      this.enableChat = false;
    },
    closePopUp() {
      this.enableChat = false;
    },

    closePopup() {
      this.modalVisible = false;
    },
    async navigateToStep(stepNumber) {
      if (stepNumber == "2") {
        this.loader3 = true;
        await this.analyzeLightQuality(document.getElementById(this.videoId));
        this.lightStatus =
          parseInt(this.lightQuality) > parseInt(this.settings.lightThreshold)
            ? "Good"
            : "Bad";
        this.lightWarning = this.lightStatus == "Bad" ? "Poor Lighting" : "";
        this.acceptanceCheck.lightCheck = this.lightStatus;
        this.loader3 = false;
        if (this.lightStatus == "Bad") return;
      } else if (stepNumber == "3") {
        this.loader3 = true;
        await this.cutAudio();
        // if (this.audioWarning) return;
        console.log("step3");
      } else if (stepNumber == "4") {
        await this.cutVideo();
        if (this.videoWarning) return;
        console.log("step4");
      } else if (stepNumber == "5") {
        console.log("step5");
      }
      this[`step${stepNumber - 1}`] = false;
      this[`step${stepNumber}`] = true;
    },
    toggleChatbox() {
      this.enableChat = !this.enableChat;
    },
    initializeAudioContext() {
      // Initialize the Web Audio API
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
    },
    async cutAudio() {
      // Get the video element
      const video = document.getElementById(this.videoId);

      // Create an audio context
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();

      if (this.sourceNode) {
        // Disconnect and clear the existing source node
        this.sourceNode.disconnect();
        this.sourceNode = null;
      }

      try {
        // Create a source node from the video
        this.sourceNode = audioContext.createMediaElementSource(video);
      } catch (error) {
        console.log(error);
      }

      // Create a gain node to control the volume
      const gainNode = audioContext.createGain();

      // Connect the source to the gain node
      this.sourceNode.connect(gainNode);

      // Connect the gain node to the destination (output)
      gainNode.connect(audioContext.destination);

      // Set the start and end time for the audio clip (in seconds)
      const startTime = 10; // example start time
      const endTime = 15; // example end time

      // Schedule the stop of the audio after the specified duration
      gainNode.gain.setValueAtTime(1, audioContext.currentTime);
      gainNode.gain.linearRampToValueAtTime(
        0,
        audioContext.currentTime + (endTime - startTime)
      );

      // Set the video source and start playing
      video.src = this.videoSource;
      video.currentTime = startTime;
      video.play();
      await this.uploadAudio();
    },
    async uploadAudio() {
      const startTime = 10; // example start time
      const endTime = 15; // example end time
      return new Promise((resolve) => {
        setTimeout(async () => {
          // Get the audio buffer from the audio context
          const sampleRate = this.audioContext.sampleRate;
          const duration = 10; // seconds
          const numChannels = 1; // mono

          const bufferSize = sampleRate * duration;
          const audioBuffer = this.audioContext.createBuffer(
            numChannels,
            bufferSize,
            sampleRate
          );

          for (let channel = 0; channel < numChannels; channel++) {
            const nowBuffering = audioBuffer.getChannelData(channel);
            nowBuffering.set(this.audioContext, channel);
          }

          this.audioBuffer = audioBuffer;
          await this.uploadToServer();
          this.loader3 = false;
          resolve();
        }, (endTime - startTime) * 1000);
      });
    },
    async uploadToServer() {
      const wav = toWav(this.audioBuffer);
      const blob = new Blob([new Uint8Array(wav)], { type: "audio/wav" });
      const formData = new FormData();
      formData.append("audio_file", blob, "audio.wav");
      try {
        // Make an HTTP request to your Python server
        const response = await axios.post(
          "https://vcipocr.algofolks.com/audio_detection",
          formData
        );

        if (response.data.voice_quality_ratio < 50) {
          this.audioWarning = "Poor audio quality";
        } else {
          this.audioWarning = "";
        }

        // Handle the response from the server (if needed)
        console.log(response.data);
      } catch (error) {
        // Handle errors
        console.error("Error uploading file:", error);
      }
    },
    saveAudio() {
      if (this.audioBuffer) {
        const wav = toWav(this.audioBuffer);
        const blob = new Blob([new Uint8Array(wav)], { type: "audio/wav" });

        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = "audio.wav";

        // Trigger a click on the link to start the download
        downloadLink.click();
      }
    },
    async cutVideo() {
      const videoElement = document.getElementById(this.videoId);
      const videoHeight = videoElement.videoHeight;
      const videoWidth = videoElement.videoWidth;

      const quality = videoElement.getVideoPlaybackQuality();
      if (
        videoWidth < 400 ||
        videoHeight < 400 ||
        quality.corruptedVideoFrames > 50
      ) {
        this.videoWarning = "Poor video quality";
      } else {
        this.videoWarning = "";
      }
      console.log(quality);
    },
    sendToServer(clippedVideo) {
      console.log(clippedVideo);
    },
    // toggleRoom() {
    //   this.roomJoined = true;
    //   if (this.roomId) {
    //     try {
    //       if (this.hasJoined) {
    //         this.$refs.webrtc.leave();
    //         this.hasJoined = false;
    //       } else {
    //         // this.socket.emit("joinRoom", this.roomId);
    //         // setTimeout(() => (this.roomJoined = false), 1000);
    //         try {
    //           this.$refs.webrtc.join().then(() => {
    //             this.userStream = this.$refs.webrtc?.videoList[0]?.stream;
    //             // this.analyzeLightQuality(document.getElementById(this.userStream.id))
    //             this.videoId = this.userStream.id;
    //             this.initializeAudioContext();
    //             this.mediaRecorder = new MediaRecorder(this.userStream);
    //             this.mediaRecorder.ondataavailable = (e) => this.pushData(e);
    //             this.mediaRecorder.onstop = () => this.onStop();
    //             this.hasJoined = true;
    //           });
    //         } catch (error) {
    //           alert("Camera Not Found");
    //           return;
    //         }
    //       }
    //     } catch (e) {
    //       alert(e);
    //     }
    //   } else {
    //     console.log("room id not found");
    //   }
    // },


    analyzeLightQuality(videoElement) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const width = videoElement.height;
      const height = videoElement.height;

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(videoElement, 0, 0, width, height);

      const imageData = ctx.getImageData(0, 0, width, height);
      const pixelData = imageData.data;
      let totalBrightness = 0;

      for (let i = 0; i < pixelData.length; i += 4) {
        const r = pixelData[i];
        const g = pixelData[i + 1];
        const b = pixelData[i + 2];
        const brightness = (r + g + b) / 3;
        totalBrightness += brightness;
      }

      const averageBrightness = totalBrightness / (width * height);
      this.lightQuality = `${averageBrightness.toFixed(2)}`;
    },
    getCurrentLocation() {
      return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
            },
            (error) => {
              reject(error);
            }
          );
        } else {
          reject(new Error("Geolocation is not supported in this browser."));
        }
      });
    },
    async updateCaseDetails() {
      const position = await this.getCurrentLocation();

      const updateData = {
        acceptanceCheck: this.acceptanceCheck,
        latitude: position.latitude,
        longitude: position.longitude,
        status: "VKYC_Completed",
      };

      try {
        const id = this.$route.query.roomid;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.put(
          `${apiUrl}/case/update/${id}`,
          updateData
        );
        this.$refs.webrtc.leave();
        this.navigateToAcceptanceCheckDone(id);
        console.log("Update response:", response);
      } catch (error) {
        console.error("Update error:", error);
      }
    },
    async uploadPan(url) {
      const formData = new FormData();
      const blob = this.base64toBlob(url, "image/jpeg");

      formData.append("panImage", blob);
      formData.append("panNameRatio", "");
      const scriptElement = document.querySelector("script[python-api-url]");
      const pythonApiUrl = scriptElement.getAttribute("python-api-url");
      const response = await axios.post(`${pythonApiUrl}/panUpload`, formData);
      if (response?.data?.error) {
        this.toastMessage = "Invalid Pan";
        return "error";
      }
      console.log(response);
    },
    base64toBlob(base64String, contentType) {
      const dataURI = "data:image/jpeg;base64,";
      const base64DataUri = base64String.replace(dataURI, "");
      const byteCharacters = atob(base64DataUri);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    },
    navigateToAcceptanceCheckDone(id) {
      this.$router.push("/acceptanceCheck?id=" + id);
    },

    async getDataById() {
      try {
        const id = this.$route.query.roomid;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/case/getById/${id}`);
        const data = response.data;
        console.log("API Response:", data);
        this.personalDetails.name = data.personalDetails.name;
        this.personalDetails.lastname = data.personalDetails.lastname;
        this.personalDetails.dob = data.personalDetails.dob;
        this.permanentAddress.addressLine1 = data.permanentAddress.addressLine1;
        this.permanentAddress.addressLine2 = data.permanentAddress.addressLine2;
        this.permanentAddress.addressLine3 = data.permanentAddress.addressLine3;
        this.permanentAddress.pincode = data.permanentAddress.pincode;
        this.permanentAddress.city = data.permanentAddress.city;
        this.url = data.otp;
      } catch (error) {
        console.error("API Error:", error);
      }
    },




    async fetchCaseById3() {
      try {
        const id = this.$route.query.roomid;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/case/getById/${id}`);
        this.caseData3 = response.data;
        console.log("Case data:", this.caseData3);
        this.flexDivDisplay = "none!important";
        this.flexDivDisplay2 = "flex!important";
        this.flexDivDisplay3 = "none!important";
      } catch (error) {
        console.error("Error fetching case data:", error);
      }
    },


    async fetchCaseById4() {
      try {
        const id = this.$route.query.roomid;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/case/getById/${id}`);
        this.caseData4 = response.data;
        console.log("Case data:", this.caseData4);
      } catch (error) {
        console.error("Error fetching case data:", error);
      }
    },

    logout() {
      localStorage.clear()
      //await this.$keycloak?.logout()
      this.$router.push('/')
    },


  },


};
</script>

<style scoped>
h2 {
  font-weight: 600;
  font-size: 1rem;
  margin-top: 2rem;
  color: black;
}

.form-label {
  margin-bottom: 0.2rem;
}

.form-control {
  background-color: white;
  border: 1px solid #b7b7b7;
}

.process-chart-mobile {
  display: none;
}

.navbar {
  padding: 0.5rem 3rem;
}

.navbar-brand {
  font-size: xx-large;
  font-weight: 600;
}

.nav-item {
  align-items: center;
  font-size: small;
}

.form-section {
  position: relative;
  padding: 25px 200px;
  background-color: #073991;
  min-height: 100vh;
}

.heading-title-circle,
.heading-title {
  padding-right: 2rem;
}

.b-w-msg {
  text-align: start;
  font-size: x-large;
  font-weight: bold;
  margin-top: 3rem;
}

.row {
  justify-content: center;
}

.page-form {
  padding-block: 2rem;
  padding-inline: 4rem;
}

.form-heading {
  padding-left: 20px;
  background-color: white;
  border-radius: 8px;
}

.progress {
  height: 3px;
  margin: -27px 2rem 20px 2rem;
  background-color: rgb(184, 183, 183);
}

.progress-bar {
  width: 50%;
  background-color: black;
}

.process-chart {
  padding: 15px;
}

.input-2 {
  display: flex;
}

.b-box {
  margin: 0px auto;
  width: 30%;
  margin-bottom: 20px;
}

.heading-title {
  color: black;
  list-style: none;
  display: flex;
  justify-content: space-between;
  font-size: x-large;
  font-weight: 600;
}

.form-check-label {
  color: black;
  /* Change the color to your preference */
}

.heading-title-circle {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.vcip {
  margin-left: 4rem;
}

.btn.btn-primary {
  width: 100%;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  margin-top: 1.5rem;
}

.nav-item {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

form {
  padding: 2rem;
  padding-top: 0rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading-title-circle li {
  width: 17px;
  height: 17px;
  background-color: white;
  border: 2px solid grey;
  border-radius: 50%;
  z-index: 1;
}

.heading-title-circle li.active {
  border: 5px solid black;
  border-radius: 50%;
}

.v-t {
  margin-top: 10px;
  margin-bottom: 2px;
  text-align: start;
}

.form-check {
  display: flex;
  gap: 10px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.input-box .input-1 {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  color: #202124;
  border: 1px solid rgb(0, 0, 0, 0.6);
  padding: 15px;
  transition: 250ms;
}

.btn.btn-primary {
  margin-bottom: 0.85rem;
}

#agreeCheckbox:checked {
  accent-color: #202124;
}

.checkbox-label {
  margin-bottom: 1rem;
}

footer {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  h2 {
    font-weight: bold;
    font-size: large;
    color: black;
    margin-block: 2rem;
  }

  .form-section {
    position: relative;
    padding: 0px 0px;
    background-color: white;
    min-height: auto;
  }

  .process-chart {
    display: none;
  }

  .form-container {
    min-height: 70vh;
    margin-top: 9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  form {
    padding-inline: 0.5rem;
  }

  .b-w-msg {
    margin-top: 1rem;
  }

  .v-t {
    margin-top: 1rem;
  }

  .heading-title-circle,
  .heading-title {
    padding-left: 1.2rem;
  }

  .process-chart-mobile {
    display: block;
    position: fixed;
    background-color: rgb(244, 244, 244);
    z-index: 1;
    width: 100%;
    top: 0;
  }

  .heading-title-circle,
  .heading-title {
    padding-right: 0rem;
    padding-left: 1rem;
  }

  .heading-title li {
    font-size: xx-large;
    padding: 1rem;
    margin-right: -1.5rem;
  }

  .heading-title-circle li {
    width: 17px;
    margin-left: 1rem;
    margin-right: -0.5rem;
  }

  .progress.mobile {
    height: 3px;
    margin: -27px 19rem 20px 2rem;
    background-color: rgb(184, 183, 183);
    width: 90%;
  }

  .progress-bar.mobile {
    width: 100%;
  }

  .btn-submit-div {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    right: 2rem;
  }

  .heading-title-circle .li3 {
    margin-left: 2rem;
  }

  .form-check-label {
    font-size: 1.1rem;
  }

  .form-check {
    margin-block: 1rem;
  }

  .btn.btn-primary {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .vcip {
    margin-left: -6rem;
  }

  footer {
    display: block;
    height: 100%;
  }

  .submit-btn-checbox {
    margin-top: 15rem;
  }

  .navbar-nav-footer {
    display: flex;
    column-gap: 22px;
    justify-content: space-between;
    flex-direction: row;
    position: fixed;
    padding: 1rem;
    bottom: 0;
    background-color: rgb(244, 244, 244);
    width: 100%;
  }

  .navbar-nav-footer .nav-link {
    color: grey;
  }

  .navbar-nav-footer .nav-link :nth-child(2) {
    font-size: small;
    font-weight: bold;
  }

  .navbar-nav-footer .nav-link.active {
    color: black;
  }

  .material-symbols-outlined {
    font-size: 34px;
  }
}

.unselected-step {
  color: gray;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.form-section {
  position: relative;
  padding: 0px 3px;
  background-color: #073991;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
}

.login-page {
  background-color: #f4f4f4;
  /* border: 1px solid rgb(192, 192, 192); */
  padding: 11px !important;
  border-radius: 10px;
  position: relative;
  /* height: 10vh; */
  box-shadow: 0 0 10px rgba(174, 173, 173, 0.2);
  /* Box shadow */
}

.vcip-heading {
  font-size: x-large;
  font-weight: bold;
  text-align: start;
  margin-bottom: 20px;
}

.vcip-options.selected-step {
  background: #5b5fd9;
  color: white;
}

.login-form-button {
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  text-align: center;
  font-size: large;
  border-radius:6px;
  padding:5.4px;
}


@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
  }

  vcip-options {
    padding: 15px 20px;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  z-index: 999;
  /* Ensure the overlay is on top */
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
}

.form-section {
  background-color: #e8e7e700;
  min-height: auto;
}

.form-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page2 {
  background-color: #f4f4f4;
  /* border: 1px solid rgba(192, 192, 192, 0); */
  /* padding: 30px;
    border-radius: 10px; */
  position: relative;
  width: 31.6vw;
  height:46vh;
 
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  /* z-index: 99999; */
}

.login-page3 {
  background-color: #f4f4f4;
  /* border: 1px solid rgb(192 192 192 / 14%); */
  /* padding: 30px;
    border-radius: 10px; */
  position: relative;
  width: 100vw;
  height: 61vh;


  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  /* z-index: 99999; */
}

.login-page4 {
  background-color: #f4f4f4;
  /* border: 1px solid rgb(192 192 192 / 14%); */
  /* padding: 30px;
    border-radius: 10px; */
  position: relative;
  width: 31.6vw;
  height: 46vh;


  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  /* z-index: 99999; */
}

.vcip-heading {
  font-size: x-large;
  font-weight: bold;
  text-align: start;
  margin-bottom: 20px;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.active-circle {
  background-color: #6a68db;
}

.active-btn {
  color: #6a68db;
}

.inner-circle {
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 50%;
}

.acceptane-options-line {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
}

.acceptance-options {
  color: gray;
  font-weight: bold;
  justify-content: space-between;
  margin: 0px 10px;
  border-top: 2px solid grey;
  margin-top: -5px;
  padding-top: 10px;
}

.login-form-button {
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
  /* width: 100%; */
  text-align: center;
  font-size: large;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    min-width: 100vw;
    margin-top: 0px;
  }

  .vcip-options {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
  }
}

.vcip-options {
  padding: 15px 20px !important;
  display: flex;
  justify-content: space-between;
}

.vcip-options-items {
  display: flex;
  align-items: center;
  /* padding: 1px 20px !important; */
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 18;
  font-size: 20px;
}

.recheck {
  color: #f7b519;
}

.good {
  color: #009688;
}

.red {
  color: red;
}

.bg-recheck {
  background-color: #f7b519;
}

.bg-good {
  background-color: #009688;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.right-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  margin-right: 10px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  z-index: 999;
  /* Ensure the overlay is on top */
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gap55 {
  gap: 231px;
}

.gap57 {
  gap: 231px;
}

.gap56 {
  gap: 231px;
}

.login-page-9 {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 30px;
  border-radius: 10px;
  position: relative;
  width: 45vw;
  height: 60vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  /* z-index: 99999; */
}

.navbar {
  padding: 0.5rem 3rem;
}

.navbar-brand {
  font-size: xx-large;
  font-weight: 600;
}

.nav-item {
  align-items: center;
  font-size: small;
}

.vcip-options[data-v-af49e32e] {
  /* margin: -1px 0px; */
  display: flex;
  background-color: #fcfcfc;
  padding: 15px;
  /* border-radius: 8px; */
  /* border-bottom: 2px solid #d4d4d4; */
  /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); */
  justify-content: space-between;
}

.login-page[data-v-3fa77605] {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 11px;
  padding-bottom: 160px !important;

  border-radius: 10px;
  position: relative;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vcip-heading[data-v-af49e32e] {
  font-size: x-large;
  font-weight: bold;
  text-align: start;
  margin-bottom: 20px;
  margin-top: 10px;
  padding-left: 10px;
}

.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.video-list[data-v-41044b41] {
  background: whitesmoke;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: inherit;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  text-align: right;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-bottom: 1px solid #8080804a;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ffc107;
  color: white;
}

.w25 {
  width: 31.8% !important;
}

.w75 {
  width: 65% !important;
}

.mt87
{
  margin-top:87px;
}

.thumbnail:hover {
/* background-color: rgba(72, 72, 72, 0.579); */
opacity: 0.5;
background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
  /* width: 100%; */
  text-align: center;
  font-size: large;
}
</style>
