<template>
  <div>
    <TopNav />

    <section class="form-section" id="" v-if="isAuditor">
      <div class="login-page">
        <div class="login-box">
          <div class="d-flex flex-row gap-3 position-relative">
            <div class="w75 overflow-fixed position-fixed form-heading1 shadow" style="overflow: hidden; height: 100%;">
              <div class="vcip-heading">
                <div class="d-flex flex-column justify-between position-fixed V1Test">
                  <div class="text-lorem">
                    VCIP would be done for following criteria - {{ isAuditor }}
                  </div>
                  <div>
                    <div class="i-b d-flex justify-content-between V2Test">
                      <div class="i-b-l">
                        <div class="i-name d-flex text-size" style="font-size: 14px">
                          <p>Name :</p>
                          {{ personalDetails.name }}&nbsp;{{
                            personalDetails.lastname
                          }}&nbsp;&nbsp;
                          <p>Pincode :</p>
                          &nbsp;{{ permanentAddress.pincode }}&nbsp;&nbsp;
                          <p>Address :</p>
                          &nbsp;{{ permanentAddress.addressLine1 }} ,
                          {{ permanentAddress.city }}
                        </div>
                        <div v-if="!stored" class="sdsd">
                        <img src="../assets/permission.jpg" alt="Permissions Required" class="fddf" style="height: 400px">
                      </div>
                        <!-- <div class="i-id"> <strong>Pincode :</strong>{{permanentAddress.pincode }}</div> -->
                      </div>
                      <!-- <div class="i-b-r"><strong>Address :</strong>
            {{ permanentAddress.addressLine1 }} , {{ permanentAddress.city }}
          </div> -->
                      <canvas height="300" width="300" ref="canvasElement" v-show="show"></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-100" v-if="progressStep == 'live-acceptance'" style="padding-top: 3rem;">
                <live-acceptance @onLiveComplete="onLiveComplete" :startStep="step"
                  v-if="progressStep == 'live-acceptance'" ref="liveAcceptance"
                  @toggleRoom2Active="room2Active = true"></live-acceptance>

                <!-- <livenessCheck   ref="livenessCheck"/> -->

              </div>
              <div id="permissionPopup" class="popup">
                <div class="popup-content">
                  <span class="close-btn" @click="closePermissionPopup">&times;</span>
                  <div class="popup-header">
                    <h3 class="popup-title">Permission Required</h3>
                    <p class="popup-subtitle">To proceed, please allow the necessary permissions.</p>
                  </div>
                  <p id="popupMessage" class="popup-message"></p>
                  <button @click="closePermissionPopup" class="btn btn-primary popup-close-btn">Close</button>
                </div>
              </div>

              <div class="w-100" v-show="progressStep == 'identity-check'" style="padding-top: 3rem;">
                <live-IdentityCheck @toggleRoom2Active="room2Active = false" @onLiveComplete="onLiveComplete"
                  v-show="progressStep == 'identity-check'" ref="liveIdentity" ></live-IdentityCheck>
              </div>




              <div class="w-100" v-show="progressStep == 'RiskIdentityCheck'" style="padding-top: 3rem;">
                <RiskIdentityCheck @toggleRoom2Active="room2Active = false" @onLiveComplete="onLiveComplete"
                  v-show="progressStep == 'RiskIdentityCheck'" ref="RiskIdentityCheck"></RiskIdentityCheck>
              </div>

              <!-- 
                  <div class="w-100 py-4 " v-show="progressStep == 'livenessCheck'">
               <livenessCheck   ref="livenessCheck"/> -->
              <!-- </div>  -->
            </div>
            <div class="d-flex flex-column gap-3 w25 overflow-auto position-absolute pb-4"
              style="overflow-y: auto; right: 2px; z-index: -9">
              <!-- <div class="vcip-options" :class="{'selected-step': selectedSteps.Acceptance}" @click="toggleStep('Acceptance')">
              <div 
                :class="{
                  'selected-step': selectedSteps.Acceptance,
                  'unselected-step': !selectedSteps.Acceptance,
                }"
                
              >
                1.&nbsp;&nbsp; Acceptance
              </div>
            </div> -->
              <!-- <div class="i mt-2"> -->
              <!-- <div class="i-h">RE information</div> -->

              <!-- <div
                  class="i-b d-flex justify-content-between flex-column px-2"
                >
                  <div class="i-b-l">
                    <div class="i-name">
                      <strong>Name :</strong> {{ personalDetails.name }}
                      {{ personalDetails.lastname }}
                    </div>
                    <div class="i-id">
                      <strong>Pincode :</strong>{{ permanentAddress.pincode }}
                    </div>
                  </div>
                  <div class="i-b-r">
                    <strong>Address :</strong>
                    {{ permanentAddress.addressLine1 }} ,
                    {{ permanentAddress.city }}
                  </div>
                  <canvas
                    height="300"
                    width="300"
                    ref="canvasElement"
                    v-show="show"
                  ></canvas>
                </div> -->

              <!-- <div class="chatIcon">
          <i
            @click="toggleChatbox()"
            class="fas fa-envelope fa-message"
            style="font-size: 40px; color: aliceblue; cursor: pointer"
          ></i>
        </div>
        <div v-if="enableChat" @event="handleEvent" class="popup">
          <Chat />
        </div> -->
              <!-- <div class="loader-container" v-if="loader">
                  <div class="loader"></div>
                </div> -->
              <!-- </div> -->

              <div class="accordion form-heading12 shadow" id="accordionExample">
                <div class="accordion-item paddingTop">
                  <div class="accordion-header" id="personalDetailsHeading">
                    <div class="vcip-options" :class="{ 'selected-step': selectedSteps.Acceptance }"
                      @click="toggleFlexDiv">
                      <div :class="{
                        'selected-step': selectedSteps.Acceptance,
                        'unselected-step': !selectedSteps.Acceptance,
                      }">
                        <div class="d-flex flex-row justify-content-between gap55 V1Test" style="cursor: pointer;">
                          <div style="width: 111px">Acceptance</div>
                          <div>
                            <i class="fas fa-chevron-down"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="personalDetailsCollapse" class="accordion-collapse collapse show"
                    aria-labelledby="personalDetailsHeading" data-bs-parent="#accordionExample">
                    <div class="">
                      <div class="d-flex flex-row gap-5" :style="{ display: flexDivDisplay }">
                        <div class="form-box">
                          <div class="login-page2">
                            <div class="login-box">
                              <div class="">
                                <div class="vcip-options-div V2Test">
                                  <!-- <div>
                                    <div
                                      class="vcip-options"
                                      v-if="
                                        !caseData2?.acceptanceCheck?.lightCheck
                                      "
                                    >
                                      <div class="vcip-options-items">
                                        Light check
                                      </div>
                                    </div>

                                    <div
                                      class="vcip-options"
                                      v-if="
                                        caseData2?.acceptanceCheck?.lightCheck
                                      "
                                    >
                                      <div class="vcip-options-items">
                                        <span class="right-icon bg-good"
                                          ><span
                                            class="material-symbols-outlined"
                                          >
                                            done
                                          </span></span
                                        >
                                        Light check
                                      </div>
                                      <div class="vcip-options-items good">
                                        {{
                                          caseData2?.acceptanceCheck?.lightCheck
                                        }}
                                        &nbsp;&nbsp;
                                        <span class="material-symbols-outlined">
                                          chevron_right
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="vcip-options"
                                    v-if="
                                      !caseData2?.acceptanceCheck
                                        ?.audioVideoCheck
                                    "
                                  >
                                    <div class="vcip-options-items">
                                      Audio & Video check
                                    </div>
                                  </div>

                                  <div
                                    class="vcip-options"
                                    v-if="
                                      caseData2?.acceptanceCheck
                                        ?.audioVideoCheck
                                    "
                                    @click="handleClick()"
                                  >
                                    <div class="vcip-options-items">
                                      <span
                                        :class="[
                                          'right-icon',
                                          caseData2?.acceptanceCheck
                                            ?.audioVideoCheck === 'Good'
                                            ? 'bg-good'
                                            : 'bg-warning',
                                        ]"
                                        ><span
                                          class="material-symbols-outlined"
                                        >
                                          {{
                                            caseData2?.acceptanceCheck
                                              ?.audioVideoCheck == "Good"
                                              ? "done"
                                              : "exclamation"
                                          }}
                                        </span></span
                                      >
                                      Audio & Video check
                                    </div>
                                    <div
                                      :class="[
                                        'vcip-options-items',
                                        caseData2?.acceptanceCheck
                                          ?.audioVideoCheck === 'Good'
                                          ? 'good'
                                          : 'recheck',
                                      ]"
                                    >
                                      {{
                                        caseData2?.acceptanceCheck
                                          ?.audioVideoCheck
                                      }}
                                      &nbsp;&nbsp;
                                      <span class="material-symbols-outlined">
                                        chevron_right
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="vcip-options"
                                    v-if="
                                      !caseData2?.acceptanceCheck
                                        ?.backgroundCheck
                                    "
                                  >
                                    <div class="vcip-options-items">
                                      Background check
                                    </div>
                                  </div>

                                  <div
                                    class="vcip-options"
                                    v-if="
                                      caseData2?.acceptanceCheck
                                        ?.backgroundCheck
                                    "
                                  >
                                    <div class="vcip-options-items">
                                      <span class="right-icon bg-good"
                                        ><span
                                          class="material-symbols-outlined"
                                        >
                                          done
                                        </span></span
                                      >
                                      Background check
                                    </div>
                                    <div class="vcip-options-items good">
                                      Good &nbsp;&nbsp;
                                      <span class="material-symbols-outlined">
                                        chevron_right
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="vcip-options"
                                    v-if="
                                      !caseData2?.acceptanceCheck?.latLongCheck
                                    "
                                  >
                                    <div class="vcip-options-items">
                                      Lat long check
                                    </div>
                                  </div>

                                  <div
                                    class="vcip-options"
                                    v-if="
                                      caseData2?.acceptanceCheck?.latLongCheck
                                    "
                                  >
                                    <div class="vcip-options-items">
                                      <span class="right-icon bg-good"
                                        ><span
                                          class="material-symbols-outlined"
                                        >
                                          done
                                        </span></span
                                      >
                                      Lat long check
                                    </div>
                                    <div class="vcip-options-items good">
                                      Good &nbsp;&nbsp;
                                      <span class="material-symbols-outlined">
                                        chevron_right
                                      </span>
                                    </div>
                                  </div> -->
                                  <div class="vcip-options" v-if="!caseData2?.acceptanceCheck?.lightCheck">
                                    <div class="vcip-options-items">
                                      Light check
                                    </div>

                                    <div @click="actest" :class="{ disabled: actestDivDisabled, loading: acisLoading }" :style="{ display: acdisplayValue }">
                                      <div v-if="!acisLoading" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="vcip-options" v-if="caseData2?.acceptanceCheck?.lightCheck">
                                    <div class="vcip-options-items">
                                      <span class="right-icon" :class="[
                                        caseData2?.acceptanceCheck?.lightCheck === 'Good' ? 'bg-good' : 'bg-warning',
                                      ]">
                                        <span class="material-symbols-outlined">
                                          {{
                                            caseData2?.acceptanceCheck?.lightCheck === "Good" ? "done" : "clear"
                                          }}
                                        </span>
                                      </span>
                                      Light check
                                    </div>

                                    <div :class="[
                                      'vcip-options-items',
                                      caseData2?.acceptanceCheck?.lightCheck === 'Good' ? 'good' : 'recheck',
                                    ]">
                                      {{
                                        caseData2?.acceptanceCheck?.lightCheck
                                      }}
                                      &nbsp;&nbsp;
                                      <span class="material-symbols-outlined">
                                        chevron_right
                                      </span>
                                    </div>

                                    <!-- Add a Recheck button if lightCheck is 'Bad' -->
                                    <div v-if="caseData2?.acceptanceCheck?.lightCheck === 'Bad'" @click="actest" :class="{ disabled: actestDivDisabled, loading: acisLoading }" :style="{ display: acdisplayValue }">
                                      <div v-if="!acisLoading" class="testCSS">
                                        Recheck
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                  <div class="vcip-options" v-if="
                                    !caseData2?.acceptanceCheck
                                      ?.audioVideoCheck
                                  " @click="handleClick()">
                                    <div class="vcip-options-items">
                                      Audio & Video check
                                    </div>
                                    <div @click="actest2" :class="{
                                      disabled: actestDivDisabled1,
                                      loading: acisLoading1,
                                    }" :style="{ display: acdisplayValue1 }">
                                      <div v-if="!acisLoading1" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                  <div class="vcip-options" v-if="
                                    caseData2?.acceptanceCheck
                                      ?.audioVideoCheck
                                  ">
                                    <div class="vcip-options-items">
                                      <span class="right-icon" :class="[
                                        caseData2?.acceptanceCheck
                                          ?.audioVideoCheck === 'Good'
                                          ? 'bg-good'
                                          : 'bg-warning',
                                      ]">
                                        <span class="material-symbols-outlined">
                                          {{
                                            caseData2?.acceptanceCheck
                                              ?.audioVideoCheck === "Good"
                                              ? "done"
                                              : "clear"
                                          }}
                                        </span>
                                      </span>
                                      Audio & Video check
                                    </div>
                                    <div :class="[
                                      'vcip-options-items',
                                      caseData2?.acceptanceCheck
                                        ?.audioVideoCheck === 'Good'
                                        ? 'good'
                                        : 'recheck',
                                    ]">
                                      {{
                                        caseData2?.acceptanceCheck
                                          ?.audioVideoCheck
                                      }}
                                      &nbsp;&nbsp;
                                      <span class="material-symbols-outlined">
                                        chevron_right
                                      </span>
                                    </div>
                                  </div>

                                  <div class="vcip-options" v-if="
                                    !caseData2?.acceptanceCheck
                                      ?.backgroundCheck
                                  ">
                                    <div class="vcip-options-items">
                                      Background check
                                    </div>
                                    <div @click="actest3" :class="{
                                      disabled: actestDivDisabled2,
                                      loading: acisLoading2,
                                    }" :style="{ display: acdisplayValue2 }">
                                      <div v-if="!acisLoading2" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>



                                  </div>

                                  <div class="vcip-options" v-if="
                                    caseData2?.acceptanceCheck
                                      ?.backgroundCheck
                                  ">
                                    <div class="vcip-options-items">
                                      <span class="right-icon" :class="[
                                        caseData2?.acceptanceCheck
                                          ?.backgroundCheck === 'Good'
                                          ? 'bg-good'
                                          : 'bg-warning',
                                      ]">
                                        <span class="material-symbols-outlined">
                                          {{
                                            caseData2?.acceptanceCheck
                                              ?.backgroundCheck === "Good"
                                              ? "done"
                                              : "clear"
                                          }}
                                        </span>
                                      </span>
                                      Background check
                                    </div>
                                    <div :class="[
                                      'vcip-options-items',
                                      caseData2?.acceptanceCheck
                                        ?.backgroundCheck === 'Good'
                                        ? 'good'
                                        : 'recheck',
                                    ]">
                                      {{
                                        caseData2?.acceptanceCheck
                                          ?.backgroundCheck
                                      }}
                                      &nbsp;&nbsp;
                                      <span class="material-symbols-outlined">
                                        chevron_right
                                      </span>
                                    </div>
                                  </div>

                                  <div class="vcip-options" v-if="
                                    !caseData2?.acceptanceCheck?.latLongCheck
                                  ">
                                    <div class="vcip-options-items">
                                      Lat long check
                                    </div>
                                    <div @click="actest4" :class="{
                                      disabled: actestDivDisabled3,
                                      loading: acisLoading3,
                                    }" :style="{ display: acdisplayValue3 }">
                                      <div v-if="!acisLoading3" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="vcip-options" v-if="
                                    caseData2?.acceptanceCheck?.latLongCheck
                                  ">
                                    <div class="vcip-options-items">
                                      <span class="right-icon" :class="[
                                        caseData2?.acceptanceCheck
                                          ?.latLongCheck === 'Good'
                                          ? 'bg-good'
                                          : 'bg-warning',
                                      ]">
                                        <span class="material-symbols-outlined">
                                          {{
                                            caseData2?.acceptanceCheck
                                              ?.latLongCheck === "Good"
                                              ? "done"
                                              : "clear"
                                          }}
                                        </span>
                                      </span>
                                      Lat long check
                                    </div>
                                    <div :class="[
                                      'vcip-options-items',
                                      caseData2?.acceptanceCheck
                                        ?.latLongCheck === 'Good'
                                        ? 'good'
                                        : 'recheck',
                                    ]">
                                      {{
                                        caseData2?.acceptanceCheck?.latLongCheck
                                      }}
                                      &nbsp;&nbsp;
                                      <span class="material-symbols-outlined">
                                        chevron_right
                                      </span>
                                    </div>
                                  </div>

                                  <!-- <div class="vcip-options" @click="vkyc()">
                <div class="vcip-options-items">Vkyc check</div>
              </div>  -->
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="login-button">
                                <button class="login-form-button thumbnail d-none" @click="navigateToIdentyCheck()"
                                  v-if="areAllAcceptanceChecksDone">
                                  Next
                                </button>
                              </div>
                              <div class="login-button">
                                <button class="login-form-button thumbnail d-none" v-if="!areAllAcceptanceChecksDone"
                                  @click="navigateToLiveAcceptanceCheck(1)">
                                  Proceed
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="loader-container" v-if="loader">
                            <div class="loader"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion form-heading12 shadow" id="accordionExample">
                <div class="accordion-item paddingTop">
                  <div class="accordion-header" id="personalDetailsHeading">
                    <div class="vcip-options" :class="{ 'selected-step': selectedSteps.Identity }"
                      @click="toggleFlexDiv2">
                      <div :class="{
                        'selected-step': selectedSteps.Identity,
                        'unselected-step': !selectedSteps.Identity,
                      }">
                        <div class="d-flex flex-row justify-content-between gap57 V1Test" style="cursor: pointer;">
                          <div style="width: 111px">Identity</div>
                          <div>
                            <i class="fas fa-chevron-down"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="personalDetailsCollapse" class="accordion-collapse collapse show"
                    aria-labelledby="personalDetailsHeading" data-bs-parent="#accordionExample">
                    <div class="">
                      <div class="d-flex flex-row gap-5" :style="{ display: flexDivDisplay2 }">
                        <div class="login-page3">
                          <header class="">
                            <!-- <div class="vcip-heading d-flex">
                              Identity check
                            </div> -->
                            <!-- <div class="acceptane-options-line">
                              <div class="acceptance-items">
                                <div class="circle active-circle active-check">
                                  <div>✔</div>
                                </div>
                              </div>
                              <div class="acceptance-items">
                                <div class="circle active-circle">
                                  <div class="inner-circle"></div>
                                </div>
                              </div>
                              <div class="acceptance-items">
                                <div class="circle">
                                  <div class="inner-circle"></div>
                                </div>
                              </div>
                            </div>
                            <div class="acceptance-options d-flex">
                              <div class="active-btn">1</div>
                              <div class="active-btn">2</div>
                              <div>3</div>
                            </div> -->
                            <div class="">
                              <div class="vcip-options-div V2Test">
                                <!-- <div>
                                  <div class="vcip-options d-flex flex-row position-relative" v-if="
                                    !caseData3?.identityCheck
                                      ?.uploadedPanQuality
                                  ">
                                    <div class="vcip-options-items">
                                      Uploaded PAN quality
                                    </div>
                                    <div @click="test" :class="{
                                      disabled: testDivDisabled,
                                      loading: isLoading,
                                    }" :style="{ display: displayValue }">
                                      <div v-if="!isLoading" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="vcip-options" v-if="
                                    caseData3?.identityCheck
                                      ?.uploadedPanQuality
                                  ">
                                    <div class="vcip-options" v-if="
                                      !caseData3?.identityCheck
                                        ?.uploadedPanQuality
                                    ">
                                      <div class="vcip-options-items">
                                        Uploaded PAN quality
                                      </div>
                                    </div>

                                    <div class="vcip-options-items">
                                      <span :class="[
                                        'right-icon',
                                        caseData3?.identityCheck
                                          ?.uploadedPanQuality !== 'Good'
                                          ? 'bg-warning'
                                          : 'bg-good',
                                      ]">
                                        <span class="material-symbols-outlined">
                                          {{
                                            caseData3?.identityCheck
                                              ?.uploadedPanQuality !== "Good"
                                              ? "clear"
                                              : "done"
                                          }}
                                        </span>
                                      </span>
                                      Uploaded PAN quality
                                    </div>
                                    <div :class="[
                                      'vcip-options-items gap-3  ',
                                      caseData3?.identityCheck
                                        ?.uploadedPanQuality === 'Good'
                                        ? 'good'
                                        : 'recheck',
                                    ]">
                                      <div class="d-flex fle-row gap-2">
                                        <div> {{ caseData3?.identityCheck?.uploadedPanQuality }} </div>

                                        <div class="testCSS"
                                          :style="{ display: isButtonVisible0 && caseData3?.identityCheck?.uploadedPanQuality === 'recheck' ? 'flex' : 'none' }"
                                          @click="retest0">Recheck</div>
                                        <div class="loader1 position-absolute"
                                          :style="{ display: isLoading00 ? 'flex' : 'none' }">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>


                                      <span class="material-symbols-outlined">chevron_right</span>
                                    </div>
                                  </div>
                                </div> -->

                                <!-- <div class="vcip-options">
              <div class="vcip-options-items">Uploaded PAN quality</div>
            </div> -->

                                <div>
                                  <div class="vcip-options position-relative" v-if="
                                    !caseData3?.identityCheck
                                      ?.livePhotographQuality
                                  ">
                                    <div class="vcip-options-items">
                                      Live photograph quality
                                    </div>
                                    <div @click="test2" :class="{
                                      disabled: testDivDisabled1,
                                      loading: isLoading1,
                                    }" :style="{ display: displayValue1 }">
                                      <div v-if="!isLoading1" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="vcip-options" v-if="
                                    caseData3?.identityCheck
                                      ?.livePhotographQuality
                                  ">
                                    <div class="vcip-options-items">
                                      <span :class="[
                                        'right-icon',
                                        caseData3?.identityCheck
                                          ?.livePhotographQuality !== 'Good'
                                          ? 'bg-warning'
                                          : 'bg-good',
                                      ]">
                                        <span class="material-symbols-outlined">
                                          {{
                                            caseData3?.identityCheck
                                              ?.livePhotographQuality !== "Good"
                                              ? "clear"
                                              : "done"
                                          }}
                                        </span>
                                      </span>
                                      Live photograph quality
                                    </div>
                                    <div :class="[
                                      'vcip-options-items',
                                      caseData3?.identityCheck
                                        ?.livePhotographQuality === 'Good'
                                        ? 'good'
                                        : 'recheck',
                                    ]">
                                      <div class="d-flex fle-row gap-2">
                                        <div> {{ caseData3?.identityCheck?.livePhotographQuality }} </div>

                                        <div class="testCSS"
                                          :style="{ display: isButtonVisible && caseData3?.identityCheck?.livePhotographQuality === 'recheck' ? 'flex' : 'none' }"
                                          @click="retest">Recheck</div>
                                        <div class="loader1 position-absolute"
                                          :style="{ display: isLoading11 ? 'flex' : 'none' }">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>





                                      <!-- <span class="material-symbols-outlined">
                                          {{
                                            caseData3?.identityCheck
                                              ?.uploadedPanQuality !== "Good"
                                              ? "clear"
                                              : "done"
                                          }}
                                        </span> -->
                                      &nbsp;&nbsp;
                                      <span class="material-symbols-outlined">chevron_right</span>
                                    </div>
                                  </div>
                                </div>

                                <!-- <div class="vcip-options">
              <div class="vcip-options-items">Customer liveliness</div>
            </div> -->
                                <div>
                                  <div class="vcip-options position-relative" v-if="
                                    !caseData3?.identityCheck
                                      ?.customerLiveliness
                                  ">
                                    <div class="vcip-options-items">
                                      Customer liveliness
                                    </div>
                                    <div @click="test3" :class="{
                                      disabled: testDivDisabled2,
                                      loading: isLoading2,
                                    }" :style="{ display: displayValue2 }">
                                      <div v-if="!isLoading2" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="vcip-options" v-if="
                                    caseData3?.identityCheck
                                      ?.customerLiveliness
                                  ">
                                    <div class="vcip-options-items">
                                      <span :class="[
                                        'right-icon',
                                        caseData3?.identityCheck
                                          ?.customerLiveliness !== 'Good'
                                          ? 'bg-warning'
                                          : 'bg-good',
                                      ]">
                                        <span class="material-symbols-outlined">
                                          {{
                                            caseData3?.identityCheck
                                              ?.customerLiveliness !== "Good"
                                              ? "clear"
                                              : "done"
                                          }}
                                        </span>
                                      </span>
                                      Customer liveliness
                                    </div>
                                    <div :class="[
                                      'vcip-options-items',
                                      caseData3?.identityCheck
                                        ?.customerLiveliness === 'Good'
                                        ? 'good'
                                        : 'recheck',
                                    ]">
                                      <div class="d-flex fle-row gap-2">
                                        <div> {{ caseData3?.identityCheck?.customerLiveliness }} </div>

                                        <div class="testCSS"
                                          :style="{ display: isButtonVisible2 && caseData3?.identityCheck?.customerLiveliness === 'recheck' ? 'flex' : 'none' }"
                                          @click="retest1">Recheck</div>
                                        <div class="loader1 position-absolute"
                                          :style="{ display: isLoading22 ? 'flex' : 'none' }">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                      <span class="material-symbols-outlined">chevron_right</span>
                                    </div>
                                  </div>
                                </div>

                                <!-- <div class="vcip-options">
              <div class="vcip-options-items">Live photograph quality</div>
            </div> -->
                                <div>
                                  <div class="vcip-options position-relative" v-if="
                                    !caseData3?.identityCheck
                                      ?.liveCapturedPanQuality
                                  ">
                                    <div class="vcip-options-items">
                                      Live captured PAN quality
                                    </div>
                                    <div @click="test4" :class="{
                                      disabled: testDivDisabled3,
                                      loading: isLoading3,
                                    }" :style="{ display: displayValue3 }">
                                      <div v-if="!isLoading3" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="vcip-options" v-if="
                                    caseData3?.identityCheck
                                      ?.liveCapturedPanQuality
                                  ">
                                    <div class="vcip-options-items">
                                      <span :class="[
                                        'right-icon',
                                        caseData3?.identityCheck
                                          ?.liveCapturedPanQuality !== 'Good'
                                          ? 'bg-warning'
                                          : 'bg-good',
                                      ]">
                                        <span class="material-symbols-outlined">
                                          {{
                                            caseData3?.identityCheck
                                              ?.liveCapturedPanQuality !==
                                              "Good"
                                              ? "clear"
                                              : "done"
                                          }}
                                        </span>
                                      </span>
                                      Live captured PAN quality
                                    </div>
                                    <div :class="[
                                      'vcip-options-items',
                                      caseData3?.identityCheck
                                        ?.liveCapturedPanQuality === 'Good'
                                        ? 'good'
                                        : 'recheck',
                                    ]">
                                      <div class="d-flex fle-row gap-2">
                                        <div> {{ caseData3?.identityCheck?.liveCapturedPanQuality }} </div>

                                        <div class="testCSS"
                                          :style="{ display: isButtonVisible3 && caseData3?.identityCheck?.liveCapturedPanQuality === 'recheck' ? 'flex' : 'none' }"
                                          @click="retest2">Recheck</div>
                                        <div class="loader1 position-absolute"
                                          :style="{ display: isLoading33 ? 'flex' : 'none' }">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>

                                      <span class="material-symbols-outlined">chevron_right</span>
                                    </div>
                                  </div>
                                </div>

                                <!-- <div class="vcip-options">
              <div class="vcip-options-items">Live captured PAN quality</div>
            </div> -->

                                <div>
                                  <div class="vcip-options position-relative"
                                    v-if="!caseData3?.identityCheck?.faceMatch">
                                    <div class="vcip-options-items">
                                      Face match
                                    </div>
                                    <div @click="test5" :class="{
                                      disabled: testDivDisabled4,
                                      loading: isLoading4,
                                    }" :style="{ display: displayValue4 }">
                                      <div v-if="!isLoading4" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="vcip-options" v-if="caseData3?.identityCheck?.faceMatch">
                                    <div class="vcip-options-items">
                                      <span :class="[
                                        'right-icon',
                                        caseData3?.identityCheck
                                          ?.faceMatch !== 'Good'
                                          ? 'bg-warning'
                                          : 'bg-good',
                                      ]">
                                        <span class="material-symbols-outlined">
                                          {{
                                            caseData3?.identityCheck
                                              ?.faceMatch !== "Good"
                                              ? "clear"
                                              : "done"
                                          }}
                                        </span>
                                      </span>
                                      Face match
                                    </div>
                                    <div :class="[
                                      'vcip-options-items',
                                      caseData3?.identityCheck?.faceMatch ===
                                        'Good'
                                        ? 'good'
                                        : 'recheck',
                                    ]">
                                      <div class="d-flex fle-row gap-2">
                                        <div> {{ caseData3?.identityCheck?.faceMatch }} </div>

                                        <div class="testCSS"
                                          :style="{ display: isButtonVisible4 && caseData3?.identityCheck?.faceMatch === 'recheck' ? 'flex' : 'none' }"
                                          @click="retest3">Recheck</div>
                                        <div class="loader1 position-absolute"
                                          :style="{ display: isLoading44 ? 'flex' : 'none' }">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>

                                      <span class="material-symbols-outlined">chevron_right</span>
                                    </div>
                                  </div>
                                </div>

                                <!-- <div class="vcip-options">
              <div class="vcip-options-items">Face match</div>
            </div> -->
                                <div>
                                  <div class="vcip-options position-relative" v-if="
                                    !caseData3?.identityCheck
                                      ?.randomQuestionnaire
                                  ">
                                    <div class="vcip-options-items">
                                      Random questionnaire
                                    </div>
                                    <div @click="test6" :class="{
                                      disabled: testDivDisabled5,
                                      loading: isLoading5,
                                    }" :style="{ display: displayValue5 }">
                                      <div v-if="!isLoading5" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="vcip-options" v-if="
                                    caseData3?.identityCheck
                                      ?.randomQuestionnaire
                                  ">
                                    <div class="vcip-options-items">
                                      <span :class="[
                                        'right-icon',
                                        caseData3?.identityCheck
                                          ?.randomQuestionnaire !== 'Good'
                                          ? 'bg-warning'
                                          : 'bg-good',
                                      ]">
                                        <span class="material-symbols-outlined">
                                          {{
                                            caseData3?.identityCheck
                                              ?.randomQuestionnaire !== "Good"
                                              ? "clear"
                                              : "done"
                                          }}
                                        </span>
                                      </span>
                                      Random questionnaire
                                    </div>
                                    <div style="cursor: pointer" :class="[
                                      'vcip-options-items',
                                      caseData3?.identityCheck
                                        ?.randomQuestionnaire === 'Good'
                                        ? 'good'
                                        : 'recheck',
                                    ]" @click="openPopup">
                                      <div class="d-flex fle-row gap-2">
                                        <div> {{ caseData3?.identityCheck?.randomQuestionnaire }} </div>

                                        <div class="testCSS"
                                          :style="{ display: isButtonVisible5 && caseData3?.identityCheck?.randomQuestionnaire === 'recheck' ? 'flex' : 'none' }"
                                          @click="retest4">Recheck</div>
                                        <div class="loader1 position-absolute"
                                          :style="{ display: isLoading55 ? 'flex' : 'none' }">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>

                                      <span class="material-symbols-outlined">chevron_right</span>
                                    </div>
                                  </div>
                                </div>

                                <!-- <div class="vcip-options">
              <div class="vcip-options-items">Random questionnaire</div>
            </div> -->
                              </div>
                            </div>
                          </header>
                          <main>
                            <div class="d-none">
                              <div class="login-button" v-if="!caseData3?.identityCheck">
                                <button class="login-form-button thumbnail" @click="navigateToLiveIdentityCheck">
                                  Proceed
                                </button>
                              </div>
                              <div class="login-button" v-if="caseData3?.identityCheck">
                                <button class="login-form-button thumbnail" @click="navigateToIdentyCheck3()">
                                  Next
                                </button>
                              </div>
                            </div>
                          </main>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion form-heading12 shadow" id="accordionExample">
                <div class="accordion-item paddingTop">
                  <div class="accordion-header" id="personalDetailsHeading">
                    <div class="vcip-options" :class="{ 'selected-step': selectedSteps.Risk }" @click="toggleFlexDiv3">
                      <div :class="{
                        'selected-step': selectedSteps.Risk,
                        'unselected-step': !selectedSteps.Risk,
                      }">
                        <div class="d-flex flex-row justify-content-between gap55 V1Test" style="cursor: pointer;">
                          <div style="width: 111px">Risk</div>
                          <div>
                            <i class="fas fa-chevron-down"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="personalDetailsCollapse" class="accordion-collapse collapse show"
                    aria-labelledby="personalDetailsHeading" data-bs-parent="#accordionExample">
                    <div class="">
                      <div class="d-flex flex-row gap-5" :style="{ display: flexDivDisplay3 }">
                        <div class="form-box">
                          <div class="login-page2">
                            <div class="login-box">
                              <div class="">
                                <div class="vcip-options-div V2Test">
                                  <div class="vcip-options" v-if="
                                    !caseData4?.riskMappingCheck
                                      ?.ovdsOcrCorrectness
                                  ">
                                    <div class="vcip-options-items">
                                      Adhar verification
                                    </div>


                                    <div @click="rctest" :class="{
                                      disabled: rctestDivDisabled,
                                      loading: rcisLoading,
                                    }" :style="{ display: rcdisplayValue }">
                                      <div v-if="!rcisLoading" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>









                                  </div>

                                  <div class="vcip-options" v-if="
                                    caseData4?.riskMappingCheck
                                      ?.ovdsOcrCorrectness
                                  ">
                                    <div class="vcip-options-items">
                                      <span class="right-icon" :class="[
                                        caseData4?.riskMappingCheck
                                          ?.ovdsOcrCorrectness === 'Good'
                                          ? 'bg-good'
                                          : 'bg-warning',
                                      ]">
                                        <span class="material-symbols-outlined">
                                          {{
                                            caseData4?.riskMappingCheck
                                              ?.ovdsOcrCorrectness === "Good"
                                              ? "done"
                                              : "clear"
                                          }}
                                        </span>
                                      </span>
                                      Adhar verification
                                    </div>
                                    <div :class="[
                                      'vcip-options-items',
                                      caseData4?.riskMappingCheck
                                        ?.ovdsOcrCorrectness === 'Good'
                                        ? 'good'
                                        : 'recheck',
                                    ]">
                                      {{
                                        caseData4?.riskMappingCheck
                                          ?.ovdsOcrCorrectness
                                      }}
                                      &nbsp;&nbsp;
                                      <span class="material-symbols-outlined">
                                        chevron_right
                                      </span>
                                    </div>
                                  </div>


                                  <div class="vcip-options" v-if="
                                    !caseData4?.riskMappingCheck
                                      ?.xmlValidityCheck
                                  ">
                                    <div class="vcip-options-items">
                                      XML validity check
                                    </div>
                                    <div @click="rctest3" :class="{
                                      disabled: rctestDivDisabled2,
                                      loading: rcisLoading2,
                                    }" :style="{ display: rcdisplayValue2 }">
                                      <div v-if="!rcisLoading2" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>



                                  </div>

                                  <div class="vcip-options" v-if="
                                    caseData4?.riskMappingCheck
                                      ?.xmlValidityCheck
                                  ">
                                    <div class="vcip-options-items">
                                      <span class="right-icon" :class="[
                                        caseData4?.riskMappingCheck
                                          ?.xmlValidityCheck === 'Good'
                                          ? 'bg-good'
                                          : 'bg-warning',
                                      ]">
                                        <span class="material-symbols-outlined">
                                          {{
                                            !caseData4?.riskMappingCheck
                                              ?.xmlValidityCheck === "Good"
                                              ? "done"
                                              : "clear"
                                          }}
                                        </span>
                                      </span>
                                      XML validity check
                                    </div>
                                    <div :class="[
                                      'vcip-options-items',
                                      caseData4?.riskMappingCheck
                                        ?.xmlValidityCheck === 'Good'
                                        ? 'good'
                                        : 'recheck',
                                    ]">
                                      {{
                                        caseData4?.riskMappingCheck
                                          ?.xmlValidityCheck
                                      }}
                                      &nbsp;&nbsp;
                                      <span class="material-symbols-outlined">
                                        chevron_right
                                      </span>
                                    </div>
                                  </div>

                                  <div class="vcip-options" v-if="
                                    !caseData4?.riskMappingCheck
                                      ?.panVerification
                                  ">
                                    <div class="vcip-options-items">
                                      PAN verification
                                    </div>
                                    <div @click="rctest4" :class="{
                                      disabled: rctestDivDisabled2,
                                      loading: rcisLoading3,
                                    }" :style="{ display: rcdisplayValue3 }">
                                      <div v-if="!rcisLoading3" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="vcip-options" v-if="
                                    caseData4?.riskMappingCheck
                                      ?.panVerification
                                  ">
                                    <div class="vcip-options-items">
                                      <span class="right-icon" :class="[
                                        caseData4?.riskMappingCheck
                                          ?.panVerification === 'Good'
                                          ? 'bg-good'
                                          : 'bg-warning',
                                      ]">
                                        <span class="material-symbols-outlined">
                                          {{
                                            caseData4?.riskMappingCheck
                                              ?.panVerification === "Good"
                                              ? "done"
                                              : "clear"
                                          }}
                                        </span>
                                      </span>
                                      PAN verification
                                    </div>
                                    <div :class="[
                                      'vcip-options-items',
                                      caseData4?.riskMappingCheck
                                        ?.panVerification === 'Good'
                                        ? 'good'
                                        : 'recheck',
                                    ]">
                                      {{
                                        caseData4?.riskMappingCheck
                                          ?.panVerification
                                      }}
                                      &nbsp;&nbsp;
                                      <span class="material-symbols-outlined">
                                        chevron_right
                                      </span>
                                    </div>
                                  </div>


                                  <div class="vcip-options" v-if="
                                    !caseData4?.riskMappingCheck
                                      ?.ovdsMasking
                                  " @click="handleClick()">
                                    <div class="vcip-options-items">
                                      OVDs masking & OVDs OCR correctness
                                    </div>
                                    <div @click="rctest2" :class="{
                                      disabled: rctestDivDisabled1,
                                      loading: rcisLoading1,
                                    }" :style="{ display: rcdisplayValue1 }">
                                      <div v-if="!rcisLoading1" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                  <div class="vcip-options" v-if="
                                    caseData4?.riskMappingCheck
                                      ?.ovdsMasking
                                  ">
                                    <div class="vcip-options-items">
                                      <span class="right-icon" :class="[
                                        caseData4?.riskMappingCheck
                                          ?.ovdsMasking === 'Good'
                                          ? 'bg-good'
                                          : 'bg-warning',
                                      ]">
                                        <span class="material-symbols-outlined">
                                          {{
                                            caseData4?.riskMappingCheck
                                              ?.ovdsMasking === "Good"
                                              ? "done"
                                              : "clear"
                                          }}
                                        </span>
                                      </span>
                                      OVDs masking & OCR correctness
                                    </div>
                                    <div :class="[
                                      'vcip-options-items',
                                      caseData4?.riskMappingCheck
                                        ?.ovdsMasking === 'Good'
                                        ? 'good'
                                        : 'recheck',
                                    ]">
                                      {{
                                        caseData4?.riskMappingCheck
                                          ?.ovdsMasking
                                      }}
                                      &nbsp;&nbsp;
                                      <span class="material-symbols-outlined">
                                        chevron_right
                                      </span>
                                    </div>
                                  </div>


                                  <!-- <div class="vcip-options" @click="vkyc()">
                <div class="vcip-options-items">Vkyc check</div>
              </div>  -->
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="login-button">
                                <button class="login-form-button thumbnail d-none" @click="navigateToIdentyCheck()"
                                  v-if="areAllAcceptanceChecksDone">
                                  Next
                                </button>
                              </div>
                              <div class="login-button">
                                <button class="login-form-button thumbnail d-none" v-if="!areAllAcceptanceChecksDone"
                                  @click="navigateToLiveAcceptanceCheck(1)">
                                  Proceed
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="loader-container" v-if="loader">
                            <div class="loader"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>










              <!-- <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <div class="accordion-header" id="personalDetailsHeading">
                    <div
                      class="vcip-options"
                      :class="{ 'selected-step': selectedSteps.Risk }"
                      @click="toggleFlexDiv3"
                    >
                      <div
                        :class="{
                          'selected-step': selectedSteps.Risk,
                          'unselected-step': !selectedSteps.Risk,
                        }"
                      >
                        <div
                          class="d-flex flex-row justify-content-between gap56 V1Test"
                        >
                          <div style="width: 111px">Risk</div>
                          <div>
                            <i class="fas fa-chevron-down"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="personalDetailsCollapse"
                    class="accordion-collapse collapse show"
                    aria-labelledby="personalDetailsHeading"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="">
                      <div
                        class="d-flex flex-row gap-5"
                        :style="{ display: flexDivDisplay3 }"
                      >
                        <div class="form-box">
                          <div class="login-page4">
                            <div class="login-box">
                              <div>
             
                                <div class="vcip-options-div V2Test">
                                  <div>
                                    <div
                                      class="vcip-options"
                                      v-if="
                                        !caseData4?.riskMappingCheck
                                          ?.ovdsOcrCorrectness
                                      "
                                    >
                                      <div class="vcip-options-items">
                                        OVDs OCR correctness
                                      </div>
                                      <div
                           
                                    >
                                 
                                    <div
                                      @click="rctest"
                                      :class="{
                                        disabled: rctestDivDisabled,
                                        loading: acisLoading,
                                      }"
                                      :style="{ display: acdisplayValue }"
                                    >
                                      <div v-if="!rcisLoading" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>
                                    </div>
                                    </div>

                                    <div
                                      class="vcip-options"
                                      v-if="
                                        caseData4?.riskMappingCheck
                                          ?.ovdsOcrCorrectness
                                      "
                                    >
                                      <div class="vcip-options-items">
                                        <span class="right-icon bg-good"
                                          ><span
                                            class="material-symbols-outlined"
                                          >
                                            done
                                          </span></span
                                        >
                                        OVDs OCR correctness
                                      </div>
                                      <div class="vcip-options-items good">
                                        {{
                                        caseData4?.riskMappingCheck
                                          ?.ovdsOcrCorrectness
                                      }} &nbsp;&nbsp;
                                        <span class="material-symbols-outlined">
                                          chevron_right
                                        </span>
                                      </div>
                                    </div>





                               
                                  </div>

          

                                  <div>
                                    <div
                                      class="vcip-options"
                                      v-if="
                                        !caseData4?.riskMappingCheck
                                          ?.ovdsMasking
                                      "
                                    >
                                      <div class="vcip-options-items">
                                        OVDs masking
                                      </div>
                                      <div
                                      @click="rctest2"
                                      :class="{
                                        disabled: rctestDivDisabled,
                                        loading: rcisLoading1,
                                      }"
                                      :style="{ display: rcdisplayValue1 }"
                                    >
                                      <div v-if="!rcisLoading1" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>
                                    </div>

                                    <div
                                      class="vcip-options"
                                      v-if="
                                        caseData4?.riskMappingCheck?.ovdsMasking
                                      "
                                    >
                                      <div class="vcip-options-items">
                                        <span class="right-icon bg-good"
                                          ><span
                                            class="material-symbols-outlined"
                                          >
                                            done
                                          </span></span
                                        >
                                        OVDs masking
                                      </div>
                                      <div class="vcip-options-items good">
                                        Good &nbsp;&nbsp;
                                        <span class="material-symbols-outlined">
                                          chevron_right
                                        </span>
                                      </div>
                                    </div>
                                  </div>


                                  <div>
                                    <div
                                      class="vcip-options"
                                      v-if="
                                        !caseData4?.riskMappingCheck
                                          ?.xmlValidityCheck
                                      "
                                    >
                                      <div class="vcip-options-items">
                                        XML validity check
                                      </div>

                                      <div
                                      @click="rctest3"
                                      :class="{
                                        disabled: rctestDivDisabled1,
                                        loading: rcisLoading2,
                                      }"
                                      :style="{ display: rcdisplayValue2 }"
                                    >
                                      <div v-if="!rcisLoading2" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>
                                    </div>

                                    <div
                                      class="vcip-options"
                                      v-if="
                                        caseData4?.riskMappingCheck
                                          ?.xmlValidityCheck
                                      "
                                    >
                                      <div class="vcip-options-items">
                                        <span class="right-icon bg-good"
                                          ><span
                                            class="material-symbols-outlined"
                                          >
                                            done
                                          </span></span
                                        >
                                        XML validity check
                                      </div>
                                      <div class="vcip-options-items good">
                                        Good &nbsp;&nbsp;
                                        <span class="material-symbols-outlined">
                                          chevron_right
                                        </span>
                                      </div>
                                    </div>
                                  </div>


                                  <div>
                                    <div
                                      class="vcip-options"
                                      v-if="
                                        !caseData4?.riskMappingCheck
                                          ?.panVerification
                                      "
                                    >
                                      <div class="vcip-options-items">
                                        PAN verification
                                      </div>
                                      <div
                                      @click="rctest4"
                                      :class="{
                                        disabled: rctestDivDisabled2,
                                        loading: rcisLoading3,
                                      }"
                                      :style="{ display: rcdisplayValue3 }"
                                    >
                                      <div v-if="!rcisLoading3" class="testCSS">
                                        Test
                                      </div>
                                      <div v-else>
                                        <div class="loader1 position-absolute">
                                          <div class="loader-circle"></div>
                                        </div>
                                      </div>
                                    </div>
                                    </div>

                                    <div
                                      class="vcip-options"
                                      v-if="
                                        caseData4?.riskMappingCheck
                                          ?.panVerification
                                      "
                                    >
                                      <div class="vcip-options-items">
                                        <span class="right-icon bg-good"
                                          ><span
                                            class="material-symbols-outlined"
                                          >
                                            done
                                          </span></span
                                        >
                                        PAN verification
                                      </div>
                                      <div class="vcip-options-items good">
                                        Good &nbsp;&nbsp;
                                        <span class="material-symbols-outlined">
                                          chevron_right
                                        </span>
                                      </div>
                                    </div>
                                  </div>
            
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                class="login-button"
                                v-if="!caseData4?.riskMappingCheck"
                              >
                                <button
                                  class="login-form-button thumbnail d-none"
                                  @click="updateCaseDetails4()"
                                >
                                  Proceed
                                </button>
                              </div>
                              <div
                                class="login-button"
                                v-if="caseData4?.riskMappingCheck"
                              >
                                <button
                                  class="login-form-button thumbnail d-none"
                                  @click="navigateToIdentyCheck4()"
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->


            </div>
          </div>

        </div>
      </div>
    </section>
    <section class="form-section2" v-else>
      <div class="row justify-nav">
        <!-- <Sidenav /> -->
        <div class="col-md-12 p-0 form-heading shadow">
          <div>
            <div class="card-title mb-2 me-1">
              <h5 class="mb-0 text-lorem">Join Room</h5>
              <p class="mb-0 text-size">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- WebRTC Component -->
              <div class="webrtc-container mb-2">
                <vue-webrtc id="call-canvas" :roomId="roomId" ref="webrtc" v-on:share-started="shareStarted"
                  class="w-100" cameraHeight="300" v-on:share-stopped="leftRoom" v-on:left-room="leftRoom"
                  v-on:joined-room="joinedRoom" width="100%" />
              </div>

              <!-- Action Buttons -->
              <div class="text-left mb-2">
                <button id="toggleid" @click="toggleRoom" class="btn btn-primary mx-2">
                  {{ hasJoined ? "Leave Room" : "Join Room" }}
                </button>
                <button @click="startScreenCapture1()" class="btn btn-primary mx-2">
                  Capture
                </button>
              </div>

              <div id="permissionPopup" class="popup">
                <div class="popup-content">
                  <span class="close-btn" @click="closePermissionPopup">&times;</span>
                  <div class="popup-header">
                    <h3 class="popup-title">Permission Required</h3>
                    <p class="popup-subtitle">To proceed, please allow the necessary permissions.</p>
                  </div>
                  <p id="popupMessage" class="popup-message"></p>
                  <button @click="closePermissionPopup" class="btn btn-primary popup-close-btn">Close</button>
                </div>
              </div>

              <!-- Status Message -->
              <div class="text-center mb-4" v-if="roomJoined1">
                <span class="status-message">You have joined the room successfully</span>
              </div>

            </div>

            <div class="col-md-6">
              <div class="tips-section p-4 shadow-sm rounded"
                style="border-left: 4px solid #7367f0; background-color: #f9f9ff; text-align: justify;">
                <h6 class="tips-title" style="color: #7367f0; font-weight: bold;">Video Call Verification Tips:</h6>

                <div class="tip-item mb-3">
                  <p><strong>Prepare Your Documents:</strong> Have your identification documents, such as your ID or
                    passport, ready before the call begins. This will help streamline the verification process.</p>
                </div>

                <div class="tip-item mb-3">
                  <p><strong>Choose a Quiet Location:</strong> Ensure you are in a quiet and well-lit environment to
                    avoid
                    distractions and ensure the video call is clear for both you and the representative.</p>
                </div>

                <div class="tip-item mb-3">
                  <p><strong>Check Your Internet Connection:</strong> A stable internet connection is essential for a
                    smooth
                    video call. Consider using a wired connection or positioning yourself closer to your Wi-Fi router.
                  </p>
                </div>

                <!-- <div class="tip-item mb-3">
                <p><strong>Use a Compatible Device:</strong> Make sure your device's camera and microphone are working
                  correctly. A laptop or smartphone with a good camera will provide the best experience.</p>
              </div> -->

                <!-- <div class="tip-item mb-3">
                <p><strong>Maintain Eye Contact:</strong> During the call, look directly at the camera when speaking.
                  This
                  helps in creating a more personal and engaging interaction.</p>
              </div>

              <div class="tip-item mb-3">
                <p><strong>Be Ready to Answer Questions:</strong> The representative may ask you questions to verify
                  your
                  identity. Answer clearly and confidently to ensure a smooth verification process.</p>
              </div>

              <div class="tip-item mb-3">
                <p><strong>Dress Appropriately:</strong> Even though it's a remote interaction, dressing professionally
                  can make a positive impression during the verification process.</p>
              </div>

              <div class="tip-item mb-3">
                <p><strong>Stay Patient:</strong> The verification process is crucial for your loan approval. Stay
                  patient
                  and follow the instructions provided by the representative.</p>
              </div> -->
              </div>
            </div>



            <canvas ref="screenshotCanvas" style="display: none"></canvas>
            <img ref="screenshotImage" alt="Screenshot" v-if="captured" />
          </div>
        </div>

      </div>

    </section>
    <div>
      <div v-if="modalVisible" class="modal">
        <div class="modal-content">
          <span class="close" @click="closePopup">&times;</span>
          <span>
            <h1 style="font-size: 21px">Random Questions</h1>
          </span>
          <table id="customers">
            <thead>
              <tr>
                <!-- <th>ID</th> -->
                <th>Questions</th>
                <th>Answer</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in modalData" :key="index">
                <!-- <td>{{ item.id }}</td> -->
                <td>{{ item.title }}</td>
                <td :class="{
                  good: item.answer === 'Good',
                  recheck: item.answer === 'recheck',
                }">
                  {{ item.answer }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="loader-container" v-if="loader">
      <div class="loader"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import toWav from "audiobuffer-to-wav";
import eventBus from "../eventBus";
// import LiveAcceptance from "./liveAcceptanceCheck.vue";
import liveIdentityCheck from "./liveIdentityCheck.vue";
import LiveAcceptance from "./liveAcceptanceCheck.vue";
import RiskIdentityCheck from "./riskMappingCheck.vue";
// import livenessCheck from "./livenessCheck.vue";
import { VueWebRTC } from "vue-webrtc";
import { io } from "socket.io-client";
import TopNav from '@/components/topNav.vue';
// import Sidenav from '@/components/sidenav.vue';

// eslint-disable-next-line
import Chat from "./chatBox.vue";
//import { VueWebRTC } from "vue-webrtc";
export default {
  name: "vcipIn",
  name2: "audioVideo",

  watch: {
    // Watch for changes in someDataId and call getDataById when it changes
    someDataId: {
      handler(newValue) {
        // Call getDataById passing the new id
        this.getDataById(newValue);
      },
      immediate: true, // Call the handler immediately on component mount
    },

    caseData2: {
      handler(newVal) {
        console.log("caseData2 updated!", newVal);
      },
      deep: true,
    },
  },
  data() {
    return {
      agree: false,
      roomJoined: false,
      hasJoined: false,
      userStream: null,
      mediaRecorder: null,
      chunks: [],
      captured: false,
      progressStep: "live-acceptance",
      step: -1,
      isAuditor: localStorage.getItem("role") == "AUDITOR",
      selectedSteps: {
        Acceptance: false,
        Identity: false,
        Risk: false,
      },
      loader: false,
      caseData: null,
      flexDivDisplay: "flex!important",
      flexDivDisplay2: "flex!important",
      flexDivDisplay3: "flex!important",
      caseData2: {},
      loader2: false,
      acceptanceCheck: {
        lightCheck: "Good",
        audioVideoCheck: "Recheck",
        backgroundCheck: "Good",
        latLongCheck: "Good",
      },
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      show: false,
      toastMessage: "",
      lightQuality: "",
      lightStatus: "",
      roomId: "",
      roomJoined1: false,
      videoId: "",
      personalDetails: {
        name: "",
        lastname: "",
        dob: "",
      },
      url: "",
      lightWarning: "",
      permanentAddress: {
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        pincode: "",
        city: "",
      },
      settings: {},
      latitude: null,
      longitude: null,
      audioContext: {},
      mediaSource: null,
      audioBuffer: null,
      videoElement: null,
      videoWarning: "",
      loader3: false,
      sourceNode: null,
      enableChat: false,
      caseData3: {},
      caseData4: {},
      modalVisible: false,
      modalData: [],
      riskMappingCheck: {
        ovdsOcrCorrectness: "Good",
        ovdsMasking: "Good",
        xmlValidityCheck: "Good",
        panVerification: "Good",
      },
      testDivDisabled: false,
      testDivDisabled1: false,
      testDivDisabled2: false,
      testDivDisabled3: false,
      testDivDisabled4: false,
      testDivDisabled5: false,
      actestDivDisabled: false,
      actestDivDisabled1: false,
      actestDivDisabled2: false,
      actestDivDisabled3: false,

      rctestDivDisabled: false,
      rctestDivDisabled1: false,
      rctestDivDisabled2: false,
      rctestDivDisabled3: false,



      rcisLoading: false,
      rcisLoading1: false,
      rcisLoading2: false,
      rcisLoading3: false,


      isLoading: false,
      isLoading1: false,
      isLoading2: false,
      isLoading3: false,
      isLoading4: false,
      isLoading5: false,
      acisLoading: false,
      acisLoading1: false,
      acisLoading2: false,
      acisLoading3: false,
      acdisplayValue: "flex",
      acdisplayValue1: "none",
      acdisplayValue2: "none",
      acdisplayValue3: "none",


      rcdisplayValue: "flex",
      rcdisplayValue1: "flex",
      rcdisplayValue2: "flex",
      rcdisplayValue3: "flex",



      displayValue: "flex",
      displayValue1: "flex",
      displayValue2: "flex",
      displayValue3: "flex",
      displayValue4: "flex",
      displayValue5: "flex",
      isLoading00: false,
      isButtonVisible0: true,
      isLoading11: false,
      isButtonVisible: true,
      stored: false,
      isLoading22: false,
      isButtonVisible2: true,
      isLoading33: false,
      isButtonVisible3: true,
      isLoading44: false,
      isButtonVisible4: true,
      isLoading55: false,
      isButtonVisible5: true,
      room2Active: true,
      image1: null,
      image2: null,
      imageQualityValue: "40",
      panImageUrl: null,
      // screenshotImage:null

    };
  },
  components: {
    "live-acceptance": LiveAcceptance,
    "live-IdentityCheck": liveIdentityCheck,
    "RiskIdentityCheck": RiskIdentityCheck,
    // livenessCheck,
    "vue-webrtc": VueWebRTC,
    //"vue-webrtc": VueWebRTC,
    // eslint-disable-next-line
    TopNav,
    // Sidenav,
  },
  created() {
    this.fetchCaseById4();
    this.fetchCaseById3();
    this.fetchCaseById();
    this.getDataById();
    this.getData();
    this.liveAcceptanceCheck();
    this.testReload2();
    this.testReload();
    
    let step = this.$route?.query?.step;
    this.roomId = this.$route?.query?.id;
    this[`step${step}`] = true;
    if (step == 2) {
      this.acceptanceCheck.audioVideoCheck = "Good";
    }
    this.roomId = this.$route.query.id
    this.socket = io("http://localhost:3600/", {
      transports: ["websocket"],
    });


    this.socket.emit("joinRoom", this.roomId);

    // Listen for incoming messages
    this.socket.on("captureImage", (socketId) => {
      console.log("captureImage call");
      if (this.socket.id == socketId) this.startScreenCapture1();
    });
  },
  computed: {
    hasSelectedStep() {
      return Object.values(this.selectedSteps).some((step) => step);
    },
    shouldShowTestButton() {
      debugger;
      return this.caseData3?.identityCheck?.livePhotographQuality !== "Good";
    },
    areAllAcceptanceChecksDone() {
      return (
        this.caseData2?.acceptanceCheck?.lightCheck &&
        this.caseData2?.acceptanceCheck?.audioVideoCheck &&
        this.caseData2?.acceptanceCheck?.backgroundCheck &&
        this.caseData2?.acceptanceCheck?.latLongCheck
      );
    },
    flexNone() {
      // Determine whether to display the elements based on isLoading1 and livePhotographQuality
      if (this.isLoading1 || this.caseData3?.identityCheck?.livePhotographQuality !== 'recheck') {
        return 'none'; // Hide the elements
      } else {
        return 'flex'; // Show the elements
      }
    }
  },

  mounted() {
    // this.$refs.liveAcceptance.toggleRoom2();



    document.title = "Vcip";
    // document.title = 'Acceptance Check';
    // document.title = "Live Acceptance Check";
    // document.title = 'Identity Check';
    //  document.title = 'Risk Mapping';
    // this.toggleRoom();
    eventBus.$on("close", this.closePopUp);

    this.navigateToLiveAcceptanceCheck(1);
    // this.navigateToLiveIdentityCheck();
    //  this.toggleRoom()
  },

  methods: {
    navigateToAdditionalDetails() {
      this.$router.push("/additionalDetails");
    },
    pushData(e) {
      this.chunks.push(e.data);
    },
    toggleRoom() {
      this.roomJoined = true;
      if (this.roomId) {
        try {
          if (this.hasJoined) {
            this.$refs.webrtc.leave();
            this.hasJoined = false;
          } else {
            this.checkPermissions().then((permissionsGranted) => {
              if (permissionsGranted) {
                this.socket.emit("joinRoom", this.roomId);
                try {
                  this.$refs.webrtc.join().then(() => {
                    const checkVideoList = () => {
                      this.userStream = this.$refs.webrtc.videoList[0].stream;
                      if (this.$refs.webrtc?.videoList[1]) {
                        this.displayStream = this.$refs.webrtc.videoList[1].stream;
                        console.log("ddd", this.userStream);
                        console.log("ddd1", this.displayStream);
                        const displaynone = document.getElementById(this.displayStream.id);
                          if (displaynone) {
                            displaynone.style.display = 'none';
                          } else {
                            console.error('Element with the given ID not found');
                          }
                        this.analyzeLightQuality(document.getElementById(this.userStream.id));
                        this.videoId = this.userStream.id;
                        this.initializeAudioContext();
                        this.mediaRecorder = new MediaRecorder(this.userStream);
                        this.mediaRecorder.ondataavailable = (e) => this.pushData(e);
                        this.mediaRecorder.onstop = () => this.onStop();
                        this.hasJoined = true;
                      } else {
                        setTimeout(checkVideoList, 500);
                      }
                    };

                    checkVideoList();

                  });
                } catch (error) {
                  this.showPopup("Camera Not Found");
                  return;
                }
              }
            }).catch((error) => {
              this.showPopup(error); // Show custom popup
            });
          }
        } catch (e) {
          this.showPopup(e.message); // Show custom popup
        }
      } else {
        console.log("Room ID not found");
      }
    },
    showPopup(message) {
      const popup = document.getElementById("permissionPopup");
      const popupMessage = document.getElementById("popupMessage");
      popupMessage.innerText = message;
      popup.style.display = "block";
      const storedPermissions = localStorage.getItem("permissionsGranted");
      this.stored = storedPermissions ? JSON.parse(storedPermissions) : false;
    },
    closePermissionPopup() {
      const popup = document.getElementById("permissionPopup");
      popup.style.display = "none";
      const storedPermissions = localStorage.getItem("permissionsGranted");
      this.stored = storedPermissions ? JSON.parse(storedPermissions) : false;
    },
    async checkPermissions() {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            () => resolve(true),
            () => {
              this.showPopup("Location permission is required. Please enable it."); // Use custom popup
              reject("Location permission denied.");
            }
          );
        });
      } catch (error) {
        this.showPopup("Camera and microphone permissions are required. Please enable them."); // Use custom popup
        return false;
      }
    },

    captureScreen() {
      alert("screen Capture")
      this.socket.emit("capture", {
        socketid: this.socket.id,
        roomId: this.roomId,
      });
    },
    startScreenCapture1() {
      debugger;
      alert("Screen Capture");

      const webrtc = this.$refs.webrtc;

      if (webrtc.capture) {
        try {
          // Step 1: Capture the screenshot and get the Base64 data URL
          this.screenshotDataURL = webrtc.capture();

          // Step 2: Convert the Base64 data URL to a Blohttp://192.168.1.72:8080/vcipIn?id=66b47d8ffee7632dc5715c91b
          const screenshotBlob = this.dataURLtoBlob(this.screenshotDataURL);
          this.image1 = screenshotBlob;
          this.image2 = screenshotBlob;

          // // Step 3: Create two image objects from the Blob
          // const image1 = new Image();
          // const image2 = new Image();
          // const url = URL.createObjectURL(screenshotBlob);

          // image1.src = url;
          // image2.src = url;

          // // Append the images to the document body (for demonstration purposes)
          // document.body.appendChild(image1);
          // document.body.appendChild(image2);

          // // Optional: Revoke the object URL after the images are loaded
          // image1.onload = () => URL.revokeObjectURL(image1.src);
          // image2.onload = () => URL.revokeObjectURL(image2.src);

          // // Save the screenshot as two separate files
          // this.saveBlob(screenshotBlob, 'image1.png');
          // this.saveBlob(screenshotBlob, 'image2.png');

          alert("before");
          this.uploadPanCardValue();
          alert("after");

          this.captured = true;
        } catch (error) {
          console.error("Error capturing screenshot:", error);
        }
      } else {
        console.warn("The `capture` method is not available in this vue-webrtc component.");
      }
    },
    dataURLtoBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString });
    },
    saveBlob(blob, filename) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },





    async uploadPanCardValue() {
      const formData = new FormData();
      formData.append("image1", this.image1);
      formData.append("image2", this.image2);
      formData.append("image_quality_value", this.imageQualityValue);

      try {
        this.loader = true;
        const scriptElement = document.querySelector("script[api-url]");
        const pythonApiUrl = scriptElement.getAttribute("python-api-url");
        const response = await axios.post(
          `${pythonApiUrl}/image_quality`,
          formData
        );

        if (response?.data?.error) {
          this.invalidPan = true;
          setTimeout(() => (this.invalidPan = false), 2000);
          this.$refs.panFront.value = "";
          this.$refs.panBack.value = "";
        } else {
          this.$toast.open({
            message: 'Image is uploaded successfully!',
            type: 'success'
          });
        }

        this.apiResponse = response.data;
        this.panCardNumber = this.apiResponse.panNumber;
        this.panMatch = this.apiResponse;

        this.loader = false;
        console.log("API response:", this.apiResponse);
      } catch (error) {
        this.invalidPan = true;
        setTimeout(() => (this.invalidPan = false), 2000);
        this.$refs.panFront.value = "";
        this.$refs.panBack.value = "";
        console.error("API error:", error);
        this.loader = false;
      }
    },
    onLiveComplete() {
      this.progressStep = "identity-check";
      this.fetchCaseById();
      this.fetchCaseById3();
      this.fetchCaseById4();
    },
    navigateToIdentityCheck() {
      this.$router.push("/identityCheck");
    },
    navigateToIdentyCheck() {
      // const id = this.$route.query.id;
      // this.$router.push("/acceptanceCheck?id=" + id);
      this.progressStep = "identity-check";

      this.flexDivDisplay = "flex!important";
      this.flexDivDisplay2 = "flex!important";
      this.flexDivDisplay3 = "flex!important";
    },

    async navigateToAcceptanceCheck() {
      this.loader = true;
      const id = this.$route.query.id;
      const scriptElement = document.querySelector("script[api-url]");
      const api = scriptElement.getAttribute("api-url");
      const apiUrl = `${api}/case/update/${id}`;
      const updateData = {
        selectedSteps: this.selectedSteps,
        status: `Selected Stap Done`,
      };

      try {
        const response = await axios.put(apiUrl, updateData);
        const endpoint = this.selectedSteps.Acceptance
          ? `acceptanceCheck?id=${id}`
          : this.selectedSteps.Identity
            ? `identityCheck?id=${id}`
            : `riskMappingCheck?id=${id}`;
        // const endpoint = `acceptanceCheck?id=${id}`;
        const data = {
          endpoint,
          id: id,
        };
        await axios.post(`${api}/case/sendVkycLink`, data);

        if (response.data.message === "Case successfully updated") {
          this.loader = false;
          this.$router.push(endpoint);
          // this.navigateToIdentyCheck()
        } else {
          this.loader = false;
          console.error("Update failed:", response.data.message);
        }
      } catch (error) {
        this.loader = false;
        console.error("API call error:", error);
      }
    },

    toggleStep(step) {
      this.selectedSteps[step] = !this.selectedSteps[step];
    },

    toggleFlexDiv() {
      this.flexDivDisplay =
        this.flexDivDisplay === "flex!important"
          ? "none!important"
          : "flex!important"; // Toggle the display property
    },
    toggleFlexDiv2() {
      this.flexDivDisplay2 =
        this.flexDivDisplay2 === "flex!important"
          ? "none!important"
          : "flex!important"; // Toggle the display property
    },
    toggleFlexDiv3() {
      this.flexDivDisplay3 =
        this.flexDivDisplay3 === "flex!important"
          ? "none!important"
          : "flex!important"; // Toggle the display property
    },

    navigateToLiveAcceptanceCheck(step) {
      // const id = this.$route.query.id;
      // this.id = id;
      //this.$router.push(`/liveAcceptanceCheck?id=${id}&step=${step}`);
      this.step = step;
    },
    handleClick() {
      if (this.caseData2?.acceptanceCheck?.audioVideoCheck === "Recheck") {
        this.navigateToLiveAcceptanceCheck(2);
      }
    },
    handleClick2() {
      // Set loader to true
      this.loader = true;

      // After 20 seconds, set loader back to false
      setTimeout(() => {
        this.loader = false;
      }, 9500);
    },

    navigateToIdentyCheck2() {
      const id = this.$route.query.id;
      const endpoint = this.caseData2?.selectedSteps?.Identity
        ? `identityCheck?id=${id}`
        : this.caseData2?.selectedSteps?.Risk
          ? `riskMappingCheck?id=${id}`
          : `auditCheck?id=${id}`;
      this.$router.push(endpoint);
    },

    async fetchCaseById() {

      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const api = scriptElement.getAttribute("api-url");
        const apiUrl = `${api}/case/getById/${id}`;
        const response = await axios.post(apiUrl);

        this.caseData2 = response.data;
        console.log("Case data:", this.caseData2);
        this.flexDivDisplay = "flex!important";
        this.flexDivDisplay2 = "flex!important";
        this.flexDivDisplay3 = "flex!important";

      } catch (error) {
        console.error("Error fetching case data:", error);
      }
    },
    async vkyc() {
      this.loader2 = true;
      const endpoint = `vcipin?id
      =${this.$route.query.id}`;
      try {
        this.loader2 = true;
        this.$router.push(endpoint);
      } catch (error) {
        this.loader2 = true;
        console.log(error);
      }
    },
    async liveAcceptanceCheck() {
      const updateData = {
        status: "VKYC_Ongoing",
      };
      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.put(
          `${apiUrl}/case/update/${id}`,
          updateData
        );
        console.log("updatedddddddddddddddddddddddddddddddddddddddddddddd", response);
      } catch (error) {
        console.error("Update error:", error);
      }
    },
    handleEvent() {
      this.enableChat = false;
    },
    closePopUp() {
      this.enableChat = false;
    },
    async navigateToStep(stepNumber) {
      if (stepNumber == "2") {
        this.loader3 = true;
        await this.analyzeLightQuality(document.getElementById(this.videoId));
        this.lightStatus =
          parseInt(this.lightQuality) > parseInt(this.settings.lightThreshold)
            ? "Good"
            : "Bad";
          console.log("lightStatus", this.lightStatus);
        this.lightWarning = this.lightStatus == "Bad" ? "Poor Lighting" : "";
        this.acceptanceCheck.lightCheck = this.lightStatus;
        this.loader3 = false;
        if (this.lightStatus == "Bad") return;
      } else if (stepNumber == "3") {
        this.loader3 = true;
        await this.cutAudio();
        // if (this.audioWarning) return;
        console.log("step3");
      } else if (stepNumber == "4") {
        await this.cutVideo();
        if (this.videoWarning) return;
        console.log("step4");
      } else if (stepNumber == "5") {
        console.log("step5");
      }
      this[`step${stepNumber - 1}`] = false;
      this[`step${stepNumber}`] = true;
    },
    toggleChatbox() {
      this.enableChat = !this.enableChat;
    },
    initializeAudioContext() {
      // Initialize the Web Audio API
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
    },
    async cutAudio() {
      // Get the video element
      const video = document.getElementById(this.videoId);

      // Create an audio context
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();

      if (this.sourceNode) {
        // Disconnect and clear the existing source node
        this.sourceNode.disconnect();
        this.sourceNode = null;
      }

      try {
        // Create a source node from the video
        this.sourceNode = audioContext.createMediaElementSource(video);
      } catch (error) {
        console.log(error);
      }

      // Create a gain node to control the volume
      const gainNode = audioContext.createGain();

      // Connect the source to the gain node
      this.sourceNode.connect(gainNode);

      // Connect the gain node to the destination (output)
      gainNode.connect(audioContext.destination);

      // Set the start and end time for the audio clip (in seconds)
      const startTime = 10; // example start time
      const endTime = 15; // example end time

      // Schedule the stop of the audio after the specified duration
      gainNode.gain.setValueAtTime(1, audioContext.currentTime);
      gainNode.gain.linearRampToValueAtTime(
        0,
        audioContext.currentTime + (endTime - startTime)
      );

      // Set the video source and start playing
      video.src = this.videoSource;
      video.currentTime = startTime;
      video.play();
      await this.uploadAudio();
    },
    async uploadAudio() {
      const startTime = 10; // example start time
      const endTime = 15; // example end time
      return new Promise((resolve) => {
        setTimeout(async () => {
          // Get the audio buffer from the audio context
          const sampleRate = this.audioContext.sampleRate;
          const duration = 10; // seconds
          const numChannels = 1; // mono

          const bufferSize = sampleRate * duration;
          const audioBuffer = this.audioContext.createBuffer(
            numChannels,
            bufferSize,
            sampleRate
          );

          for (let channel = 0; channel < numChannels; channel++) {
            const nowBuffering = audioBuffer.getChannelData(channel);
            nowBuffering.set(this.audioContext, channel);
          }

          this.audioBuffer = audioBuffer;
          await this.uploadToServer();
          this.loader3 = false;
          resolve();
        }, (endTime - startTime) * 1000);
      });
    },
    async uploadToServer() {
      const wav = toWav(this.audioBuffer);
      const blob = new Blob([new Uint8Array(wav)], { type: "audio/wav" });
      const formData = new FormData();
      formData.append("audio_file", blob, "audio.wav");
      try {
        // Make an HTTP request to your Python server
        const response = await axios.post(
          "https://vcipocr.algofolks.com/audio_detection",
          formData
        );

        if (response.data.voice_quality_ratio < 50) {
          this.audioWarning = "Poor audio quality";
        } else {
          this.audioWarning = "";
        }

        // Handle the response from the server (if needed)
        console.log(response.data);
      } catch (error) {
        // Handle errors
        console.error("Error uploading file:", error);
      }
    },
    saveAudio() {
      if (this.audioBuffer) {
        const wav = toWav(this.audioBuffer);
        const blob = new Blob([new Uint8Array(wav)], { type: "audio/wav" });

        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = "audio.wav";

        // Trigger a click on the link to start the download
        downloadLink.click();
      }
    },
    async cutVideo() {
      const videoElement = document.getElementById(this.videoId);
      const videoHeight = videoElement.videoHeight;
      const videoWidth = videoElement.videoWidth;

      const quality = videoElement.getVideoPlaybackQuality();
      if (
        videoWidth < 400 ||
        videoHeight < 400 ||
        quality.corruptedVideoFrames > 50
      ) {
        this.videoWarning = "Poor video quality";
      } else {
        this.videoWarning = "";
      }
      console.log(quality);
    },
    sendToServer(clippedVideo) {
      console.log(clippedVideo);
    },
    // toggleRoom() {
    //   this.roomJoined = true;
    //   if (this.roomId) {
    //     try {
    //       if (this.hasJoined) {
    //         this.$refs.webrtc.leave();
    //         this.hasJoined = false;
    //       } else {
    //         // this.socket.emit("joinRoom", this.roomId);
    //         // setTimeout(() => (this.roomJoined = false), 1000);
    //         try {
    //           this.$refs.webrtc.join().then(() => {
    //             this.userStream = this.$refs.webrtc?.videoList[0]?.stream;
    //             // this.analyzeLightQuality(document.getElementById(this.userStream.id))
    //             this.videoId = this.userStream.id;
    //             this.initializeAudioContext();
    //             this.mediaRecorder = new MediaRecorder(this.userStream);
    //             this.mediaRecorder.ondataavailable = (e) => this.pushData(e);
    //             this.mediaRecorder.onstop = () => this.onStop();
    //             this.hasJoined = true;
    //           });
    //         } catch (error) {
    //           alert("Camera Not Found");
    //           return;
    //         }
    //       }
    //     } catch (e) {
    //       alert(e);
    //     }
    //   } else {
    //     console.log("room id not found");
    //   }
    // },
    async getData() {
      try {
        // this.loader = true;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.get(`${apiUrl}/setting/getSetting`);
        this.settings = response?.data?.settings[0];
        // console.log(response)
      } catch (error) {
        console.log(error);
      }
    },
    async 1(stepNumber) {
      const videoElement = document.getElementById(this.videoId);
      const canvasElement = this.$refs.canvasElement;
      const capturedImageElement = this.$refs.captureArea;

      const canvasContext = canvasElement.getContext("2d");
      canvasElement.width = 300;
      canvasElement.height = 300;

      canvasContext.drawImage(videoElement, 0, 0, 300, 300, 0, 0, 300, 300);
      const imageDataURL = canvasElement.toDataURL("image/jpeg");

      capturedImageElement.src = imageDataURL;
      capturedImageElement.style.display = "block";
      this.show = true;

      let response = await this.uploadPan(imageDataURL);
      if (response == "error") return;
      this.navigateToStep(stepNumber);
    },
    analyzeLightQuality(videoElement) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const width = videoElement.height;
      const height = videoElement.height;

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(videoElement, 0, 0, width, height);

      const imageData = ctx.getImageData(0, 0, width, height);
      const pixelData = imageData.data;
      let totalBrightness = 0;

      for (let i = 0; i < pixelData.length; i += 4) {
        const r = pixelData[i];
        const g = pixelData[i + 1];
        const b = pixelData[i + 2];
        const brightness = (r + g + b) / 3;
        totalBrightness += brightness;
      }

      const averageBrightness = totalBrightness / (width * height);
      this.lightQuality = `${averageBrightness.toFixed(2)}`;
    },
    getCurrentLocation() {
      return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
            },
            (error) => {
              reject(error);
            }
          );
        } else {
          reject(new Error("Geolocation is not supported in this browser."));
        }
      });
    },
    async updateCaseDetails() {
      const position = await this.getCurrentLocation();

      const updateData = {
        acceptanceCheck: this.acceptanceCheck,
        latitude: position.latitude,
        longitude: position.longitude,
        status: "VKYC_Completed",
      };

      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.put(
          `${apiUrl}/case/update/${id}`,
          updateData
        );
        this.$refs.webrtc.leave();
        this.navigateToAcceptanceCheckDone(id);
        console.log("Update response:", response);
      } catch (error) {
        console.error("Update error:", error);
      }
    },
    async uploadPan(url) {
      const formData = new FormData();
      const blob = this.base64toBlob(url, "image/jpeg");

      formData.append("panImage", blob);
      formData.append("panNameRatio", "");
      const scriptElement = document.querySelector("script[python-api-url]");
      const pythonApiUrl = scriptElement.getAttribute("python-api-url");
      const response = await axios.post(`${pythonApiUrl}/panUpload`, formData);
      if (response?.data?.error) {
        this.toastMessage = "Invalid Pan";
        return "error";
      }
      console.log(response);
    },
    base64toBlob(base64String, contentType) {
      const dataURI = "data:image/jpeg;base64,";
      const base64DataUri = base64String.replace(dataURI, "");
      const byteCharacters = atob(base64DataUri);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    },
    navigateToAcceptanceCheckDone(id) {
      this.$router.push("/acceptanceCheck?id=" + id);
    },
    async getDataById() {
      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/case/getById/${id}`);
        const data = response.data;
        console.log("API Response:", data);
        this.personalDetails.name = data.personalDetails.name;
        this.personalDetails.lastname = data.personalDetails.lastname;
        this.personalDetails.dob = data.personalDetails.dob;
        this.permanentAddress.addressLine1 = data.permanentAddress.addressLine1;
        this.permanentAddress.addressLine2 = data.permanentAddress.addressLine2;
        this.permanentAddress.addressLine3 = data.permanentAddress.addressLine3;
        this.permanentAddress.pincode = data.permanentAddress.pincode;
        this.permanentAddress.city = data.permanentAddress.city;
        this.url = data.otp;
      } catch (error) {
        console.error("API Error:", error);
      }
    },
    navigateToIdentyCheck3() {
      // const id = this.$route.query.id;
      // const endpoint = this.caseData3?.selectedSteps?.Risk
      //   ? `riskMappingCheck?id=${id}`
      //   : `auditCheck?id=${id}`;
      // this.$router.push(endpoint);

      //  this.flexDivDisplay2 === "none!important"
      //   ? "flex!important"
      //   : "none!important";

      //    this.flexDivDisplay3 === "flex!important"
      //   ? "none!important"
      //   : "flex!important";

      // this.flexDivDisplay = "none!important";
      // this.flexDivDisplay2 = "none!important";
      // this.flexDivDisplay3 = "flex!important";

      this.$refs.liveIdentity.navigateToStep(2);

      // Toggle the display property
    },
    actest() {


      this.acisLoading = true;
      this.$refs.liveAcceptance.navigateToStep(2);
      setTimeout(() => {
        // After 10 seconds
        // this.acisLoading = true;
        //   this.acdisplayValue1 = this.acdisplayValue1 === "none" ? "flex" : "none";
        //    this.actestDivDisabled = true;
        setTimeout(() => {
          this.acdisplayValue1 = this.acdisplayValue1 === "none" ? "flex" : "none";
          this.acisLoading = false;
        }, 3000);
        this.fetchCaseById();
      }, 5000); //

      // this.$refs.liveIdentity.navigateToIdentityCheckDone()

      // this.actestDivDisabled3 = true;
    },
    async actest2() {
      // Set loading to true initially
      this.acisLoading1 = true;

      this.$refs.liveAcceptance.navigateToStep(3);

      // Simulate a delay of 30 seconds
      setTimeout(async () => {
        // Simulate fetching data
        await this.fetchCaseById();

        // After fetching data, update display and disable values
        this.acdisplayValue2 = this.acdisplayValue2 === "none" ? "flex" : "none";
        this.actestDivDisabled1 = true;

        // Set loading to false after all operations are done
        this.acisLoading1 = false;
      }, 30000);
    }


    , actest3() {


      this.acisLoading2 = true;
      this.$refs.liveAcceptance.navigateToStep(4);
      setTimeout(() => {
        // After 10 seconds
        // this.acisLoading = true;
          this.acdisplayValue1 = this.acdisplayValue1 === "none" ? "flex" : "none";
           this.actestDivDisabled = true;
        setTimeout(() => {
          this.acisLoading2 = false;
          this.acdisplayValue3 = this.acdisplayValue3 === "none" ? "flex" : "none";
          // this.actestDivDisabled3 = true;
        }, 3000);
        this.fetchCaseById();
      }, 5000); //

      // this.$refs.liveIdentity.navigateToIdentityCheckDone()

      // this.actestDivDisabled3 = true;
    },
    actest4() {


      this.acisLoading3 = true;
      this.$refs.liveAcceptance.navigateToStep(5);
      setTimeout(() => {
        // After 10 seconds
        // this.acisLoading = true;
        //   this.acdisplayValue1 = this.acdisplayValue1 === "none" ? "flex" : "none";
        //    this.actestDivDisabled = true;
        setTimeout(() => {
          this.acisLoading3 = true;
          this.acdisplayValue4 = this.acdisplayValue4 === "none" ? "flex" : "none";
          // this.actestDivDisabled4 = true;
        }, 3000);
        this.fetchCaseById();
      }, 5000); //

      // this.$refs.liveIdentity.navigateToIdentityCheckDone()

      // this.actestDivDisabled3 = true;
    },


    rctest() {


      this.rcisLoading = true;
      this.$refs.RiskIdentityCheck.navigateToStep(2);
      setTimeout(() => {
        // After 10 seconds
        // this.acisLoading = true;
        //   this.acdisplayValue1 = this.acdisplayValue1 === "none" ? "flex" : "none";
        //    this.actestDivDisabled = true;
        setTimeout(() => {
          this.rcisLoading = true;
          this.rcdisplayValue1 = this.rcdisplayValue1 === "none" ? "flex" : "none";
          // this.actestDivDisabled = true;
        }, 3000);
        this.fetchCaseById();
      }, 5000); //

      // this.$refs.liveIdentity.navigateToIdentityCheckDone()

      // this.actestDivDisabled3 = true;
    },
    async rctest2() {
      // Set loading to true initially
      this.rcisLoading1 = true;

      this.$refs.RiskIdentityCheck.navigateToStep(4);

      // Simulate a delay of 30 seconds
      setTimeout(async () => {
        // Simulate fetching data
        await this.fetchCaseById();

        // After fetching data, update display and disable values
        this.rcdisplayValue2 = this.rcdisplayValue2 === "none" ? "flex" : "none";
        this.rctestDivDisabled1 = true;

        // Set loading to false after all operations are done
        this.acisLoading1 = false;
      }, 30000);
    }


    , rctest3() {


      this.rcisLoading2 = true;
      this.$refs.RiskIdentityCheck.navigateToStep(3);
      setTimeout(() => {
        // After 10 seconds
        // this.acisLoading = true;
        //   this.acdisplayValue1 = this.acdisplayValue1 === "none" ? "flex" : "none";
        //    this.actestDivDisabled = true;
        setTimeout(() => {
          this.rcisLoading2 = true;
          this.rcdisplayValue3 = this.rcdisplayValue3 === "none" ? "flex" : "none";
          // this.actestDivDisabled3 = true;
        }, 3000);
        this.fetchCaseById3();
      }, 5000); //

      // this.$refs.liveIdentity.navigateToIdentityCheckDone()

      // this.actestDivDisabled3 = true;
    },
    rctest4() {


      this.rcisLoading3 = true;
      this.$refs.RiskIdentityCheck.navigateToStep(5);
      setTimeout(() => {
        // After 10 seconds
        // this.acisLoading = true;
        //   this.acdisplayValue1 = this.acdisplayValue1 === "none" ? "flex" : "none";
        //    this.actestDivDisabled = true;
        setTimeout(() => {
          this.rcisLoading3 = true;
          this.rcdisplayValue4 = this.rcdisplayValue4 === "none" ? "flex" : "none";
          // this.actestDivDisabled4 = true;
        }, 3000);
        this.fetchCaseById3();
      }, 5000); //

      // this.$refs.liveIdentity.navigateToIdentityCheckDone()

      // this.actestDivDisabled3 = true;
    },












    test() {
      debugger
      this.room2Active = false;
      this.$refs.liveAcceptance.toggleRoom2();
      // this.$refs.liveIdentity.toggleRoom100();
      this.$refs.liveIdentity.navigateToStep(2);
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = true;
        // this.displayValue1 = this.displayValue1 === "none" ? "flex" : "none";
        this.testDivDisabled = true;
      }, 3000);
    },

    retest0() {
      // Hide the button
      // this.isButtonVisible0 = false;

      // Show loading spinner
      this.isLoading00 = true;

      // Set a timer to switch back to showing the button after 3 seconds
      setTimeout(() => {
        this.isLoading00 = false;
        // Show the button after 3 seconds
        this.isButtonVisible0 = true;
      }, 3000);

      // Check if live photograph quality is "recheck"
      if (this.caseData3?.identityCheck?.uploadedPanQuality === 'recheck') {
        // Start the testing process
        this.test1();
      }
    },


    test2() {
      this.room2Active = false;
      // this.$refs.liveAcceptance.toggleRoom2();
      this.$refs.liveIdentity.toggleRoom100();
      this.$refs.liveIdentity.navigateToStep(3);
      this.isLoading1 = true;
      setTimeout(() => {
        // this.displayValue2 = this.displayValue2 === "none" ? "flex" : "none";
        this.isLoading1 = true;
        this.testDivDisabled1 = true;

        // Check if live photograph quality is "recheck"

      }, 3000);
      this.isLoading1 = false;
    },
    retest() {
      // Hide the button
      // this.isButtonVisible = false;

      // Show loading spinner
      this.isLoading11 = true;

      // Set a timer to switch back to showing the button after 3 seconds
      setTimeout(() => {
        this.isLoading11 = false;
        // Show the button after 3 seconds
        this.isButtonVisible = true;
      }, 3000);

      // Check if live photograph quality is "recheck"
      if (this.caseData3?.identityCheck?.livePhotographQuality === 'recheck') {
        // Start the testing process
        this.test2();
      }
    }


    ,
    async test3() {
      debugger
      // Call handleClick2 and wait for it to complete

      await this.handleClick2();

      // After handleClick2 completes, set room2Active to false
      this.room2Active = false;

      // Navigate to step 4




      await this.$refs.liveIdentity.navigateToStep(4);


      // Set testDivDisabled2 to true after 3 seconds
      setTimeout(() => {
        this.testDivDisabled2 = true;
      }, 20000);
    },


    async retest1() {
      await this.handleClick2();
      debugger;
      // this.isButtonVisible2 = false;


      // this.isLoading22 = true;


      setTimeout(() => {
        this.isLoading22 = false;

        this.isButtonVisible2 = true;
      }, 40000);


      if (this.caseData3?.identityCheck?.customerLiveliness === 'recheck') {

        this.test3();
      }
    },
    async test4() {
      this.room2Active = false;
      // this.$refs.liveAcceptance.toggleRoom2();
      // this.$refs.liveIdentity.toggleRoom100();
      this.$refs.liveIdentity.navigateToStep(5);
      this.isLoading3 = true;

      setTimeout(() => {
        // this.displayValue4 = this.displayValue4 === "none" ? "flex" : "none";

        this.isLoading3 = true;
        this.testDivDisabled3 = true;
      }, 3000);
    },

    retest2() {
      // Hide the button
      // this.isButtonVisible3 = false;

      // Show loading spinner
      this.isLoading33 = true;

      // Set a timer to switch back to showing the button after 3 seconds
      setTimeout(() => {
        this.isLoading33 = false;
        // Show the button after 3 seconds
        this.isButtonVisible3 = true;
      }, 3000);

      // Check if live photograph quality is "recheck"
      if (this.caseData3?.identityCheck?.liveCapturedPanQuality === 'recheck') {
        // Start the testing process
        this.test4();
      }
    },
    test5() {
      this.room2Active = false;
      // this.$refs.liveAcceptance.toggleRoom2();
      // this.$refs.liveIdentity.toggleRoom100();
      this.$refs.liveIdentity.navigateToStep(6);
      this.isLoading4 = true;

      setTimeout(() => {
        // this.displayValue5 = this.displayValue5 === "none" ? "flex" : "none";
        this.isLoading4 = true;
        this.testDivDisabled4 = true;
      }, 3000);
    },

    retest3() {
      debugger;

      // this.isButtonVisible4 = false;
      this.isLoading44 = true;
      setTimeout(() => {
        this.isLoading44 = false;
        this.isButtonVisible4 = true;
      }, 3000);
      if (this.caseData3?.identityCheck?.faceMatch === 'recheck') {
        this.test5();
      }
    },
    test6() {
      this.room2Active = false;
      // this.$refs.liveAcceptance.toggleRoom2();
      // this.$refs.liveIdentity.toggleRoom100();
      this.$refs.liveIdentity.navigateToStep(7);
      this.isLoading5 = true;

      setTimeout(() => {
        // this.displayValue5 = this.displayValue5 === "none" ? "flex" : "none";

        this.isLoading5 = true;
        this.testDivDisabled5 = true;
      }, 3000);
    },
    retest4() {
      // this.isButtonVisible5 = false;
      this.isLoading55 = true;
      setTimeout(() => {
        this.isLoading55 = false;
        this.isButtonVisible5 = true;
      }, 3000);
      if (this.caseData3?.identityCheck?.randomQuestionnaire === 'recheck') {

        this.test6();
      }
    },


    navigateToLiveIdentityCheck() {
      const id = this.$route.query.id;
      this.id = id;
      this.progressStep = "identity-check";
    },

    async fetchCaseById3() {
      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/case/getById/${id}`);
        this.caseData3 = response.data;
        console.log("Case data:", this.caseData3);
        this.flexDivDisplay = "flex!important";
        this.flexDivDisplay2 = "flex!important";
        this.flexDivDisplay3 = "flex!important";
      } catch (error) {
        console.error("Error fetching case data:", error);
      }
    },

    async updateCaseDetails4() {
      const updateData = {
        riskMappingCheck: this.riskMappingCheck,
      };

      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.put(
          `${apiUrl}/case/update/${id}`,
          updateData
        );
        console.log("Update response:", response);
        this.flexDivDisplay = "flex!important";
        this.flexDivDisplay2 = "flex!important";
        this.flexDivDisplay3 = "flex!important";
        this.fetchCaseById();
      } catch (error) {
        console.error("Update error:", error);
      }
    },

    async fetchCaseById4() {
      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/case/getById/${id}`);
        this.caseData4 = response.data;
        console.log("Case data:", this.caseData4);
      } catch (error) {
        console.error("Error fetching case data:", error);
      }
    },

    logout() {
      localStorage.clear();
      //await this.$keycloak?.logout()
      this.$router.push("/");
    },

    openPopup() {
      // alert("khhjhjhjhjh");
      this.modalVisible = true;
      const data = JSON.parse(localStorage.getItem("RandomQueAnswer"));
      this.modalData = data;
      if (data) {
        this.modalData = data;
        // alert("khhjhjhjhjh", this.modalData);
      }
    },

    closePopup() {
      this.modalVisible = false;
    },

    testReload() {
      let isConditionMet = false;

      const timeoutId = setTimeout(() => {
        if (
          !isConditionMet &&
          this.caseData3 &&
          this.caseData3.identityCheck &&
          this.caseData3.identityCheck.uploadedPanQuality === ""
        ) {
          this.displayValue = this.displayValue === "none" ? "flex" : "none";
          isConditionMet = true;
        } else if (
          !isConditionMet &&
          this.caseData3 &&
          this.caseData3.identityCheck &&
          this.caseData3.identityCheck.livePhotographQuality === ""
        ) {
          this.displayValue1 = this.displayValue1 === "none" ? "flex" : "none";
          isConditionMet = true;
        } else if (
          !isConditionMet &&
          this.caseData3 &&
          this.caseData3.identityCheck &&
          this.caseData3.identityCheck.customerLiveliness === ""
        ) {
          this.displayValue2 = this.displayValue2 === "none" ? "flex" : "none";
          isConditionMet = true;
        } else if (
          !isConditionMet &&
          this.caseData3 &&
          this.caseData3.identityCheck &&
          this.caseData3.identityCheck.liveCapturedPanQuality === ""
        ) {
          this.displayValue3 = this.displayValue3 === "none" ? "flex" : "none";
          isConditionMet = true;
        } else if (
          !isConditionMet &&
          this.caseData3 &&
          this.caseData3.identityCheck &&
          this.caseData3.identityCheck.faceMatch === ""
        ) {
          this.displayValue4 = this.displayValue4 === "none" ? "flex" : "none";
          isConditionMet = true;
        } else if (
          !isConditionMet &&
          this.caseData3 &&
          this.caseData3.identityCheck &&
          this.caseData3.identityCheck.randomQuestionnaire === ""
        ) {
          this.displayValue5 = this.displayValue5 === "none" ? "flex" : "none";
          isConditionMet = true;
        }

        if (isConditionMet) {
          clearTimeout(timeoutId); // Clear the timeout if a condition is met
        }
      }, 1000);
    },

    testReload2() {
      let isConditionMet = false;

      const timeoutId = setTimeout(() => {
        if (
          !isConditionMet &&
          this.caseData2 &&
          this.caseData2.acceptanceCheck &&
          this.caseData2.acceptanceCheck.lightCheck === ""
        ) {
          this.acdisplayValue = "flex";
          isConditionMet = true;
        } else if (
          !isConditionMet &&
          this.caseData2 &&
          this.caseData2.acceptanceCheck &&
          this.caseData2.acceptanceCheck.audioVideoCheck === ""
        ) {
          this.acdisplayValue1 = "flex";
          isConditionMet = true;
        } else if (
          !isConditionMet &&
          this.caseData2 &&
          this.caseData2.acceptanceCheck &&
          this.caseData2.acceptanceCheck.backgroundCheck === ""
        ) {
          this.acdisplayValue2 = "flex";
          isConditionMet = true;
        } else if (
          !isConditionMet &&
          this.caseData2 &&
          this.caseData2.acceptanceCheck &&
          this.caseData2.acceptanceCheck.latLongCheck === ""
        ) {
          this.acdisplayValue3 = "flex";
          isConditionMet = true;
        }

        if (isConditionMet) {
          clearTimeout(timeoutId); // Clear the timeout if a condition is met
        }
      }, 1000);
    },





    navigateToIdentyCheck4() {
      const id = this.$route.query.id;
      this.id = id;
      // this.$router.push("/auditCheck?id=" + id);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .selected-step {
  color: white;
  background: #5b5fd9;
} */

.unselected-step {
  color: gray;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.form-section {
  position: relative;
  padding: 0px 3px;
  background-color: #073991;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
}

.login-page {
  /* background-color: #f4f4f4; */
  /* border: 1px solid rgb(192, 192, 192); */
  padding: 11px !important;
  border-radius: 10px;
  position: relative;
  /* height: 10vh; */
  /* box-shadow: 0 0 10px rgba(174, 173, 173, 0.2); */
  /* Box shadow */
}

.vcip-heading {
  font-size: x-large;
  font-weight: bold;
  text-align: start;
  margin-bottom: 20px;
}

.vcip-options.selected-step {
  background: #5b5fd9;
  color: white;
}

.login-form-button {
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  text-align: center;
  font-size: large;
  border-radius: 6px;
  padding: 5.4px;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
  }

  vcip-options {
    padding: 15px 20px;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  z-index: 999;
  /* Ensure the overlay is on top */
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
}

.form-section {
  background-color: #e8e7e700;
  min-height: auto;
}

.form-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page2 {
  /* background-color: #f4f4f4; */
  /* border: 1px solid rgba(192, 192, 192, 0); */
  /* padding: 30px;
    border-radius: 10px; */
  position: relative;
  width: 30.5vw;
  /* height: 48vh; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  /* z-index: 99999; */
}

.login-page3 {
  /* background-color: #f4f4f4; */
  /* border: 1px solid rgb(192 192 192 / 14%); */
  /* padding: 30px;
    border-radius: 10px; */
  position: relative;
  width: 100vw;
  /* height: 53vh; */

  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  /* z-index: 99999; */
}

.login-page4 {
  /* background-color: #f4f4f4; */
  /* border: 1px solid rgb(192 192 192 / 14%); */
  /* padding: 30px;
    border-radius: 10px; */
  position: relative;
  width: 30.5vw;
  /* height: 47vh; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  /* z-index: 99999; */
}

.vcip-heading {
  font-size: x-large;
  font-weight: bold;
  text-align: start;
  margin-bottom: 20px;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.active-circle {
  background-color: #6a68db;
}

.active-btn {
  color: #6a68db;
}

.inner-circle {
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 50%;
}

.acceptane-options-line {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
}

.acceptance-options {
  color: gray;
  font-weight: bold;
  justify-content: space-between;
  margin: 0px 10px;
  border-top: 2px solid grey;
  margin-top: -5px;
  padding-top: 10px;
}

.login-form-button {
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
  /* width: 100%; */
  text-align: center;
  font-size: large;
}

.form-section[data-v-0c218a7e] {
  position: relative;
  /* padding: 40px 300px; */
  /* background-color: rgb(192, 192, 192); */
  min-height: 70vh !important;
  /* border-radius: 8px;  */
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    min-width: 100vw;
    margin-top: 0px;
  }

  .vcip-options {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
  }
}

.vcip-options {
  padding: 15px 20px !important;
  display: flex;
  justify-content: space-between;
}

.vcip-options-items {
  display: flex;
  align-items: center;
  /* padding: 1px 20px !important; */
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 18;
  font-size: 20px;
}

.recheck {
  color: #f7b519;
}

.good {
  color: #009688;
}

.red {
  color: red;
}

.bg-recheck {
  background-color: #f7b519;
}

.bg-good {
  background-color: #009688;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.right-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  margin-right: 10px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  z-index: 999;
  /* Ensure the overlay is on top */
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gap55 {
  gap: 231px;
}

.gap57 {
  gap: 231px;
}

.gap56 {
  gap: 231px;
}

.login-page-9 {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 30px;
  border-radius: 10px;
  position: relative;
  width: 45vw;
  height: 60vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  /* z-index: 99999; */
}

.navbar {
  padding: 0.5rem 3rem;
}

.navbar-brand {
  font-size: xx-large;
  font-weight: 600;
}

.nav-item {
  align-items: center;
  font-size: small;
}

.vcip-options[data-v-af49e32e] {
  /* margin: -1px 0px; */
  display: flex;
  background-color: #fcfcfc;
  padding: 15px;
  border-radius: 8px;
  /* border-bottom: 2px solid #d4d4d4; */
  /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); */
  justify-content: space-between;
}

.login-page[data-v-3fa77605] {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 11px;
  padding-bottom: 160px !important;

  border-radius: 10px;
  position: relative;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vcip-heading[data-v-af49e32e] {
  font-size: x-large;
  font-weight: bold;
  text-align: start;
  margin-bottom: 20px;
  /* margin-top: 10px; */
  padding: 0 !important;
}

.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  text-align: right;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-bottom: 1px solid #8080804a;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ffc107;
  color: white;
}

.w25 {
  width: 31.8% !important;
}

.w75 {
  width: 65% !important;
}

.vcip-heading[data-v-af49e32e][data-v-af49e32e] {
  font-size: x-large;
  font-weight: bold;
  text-align: start;
  margin-bottom: 39px;
  /* margin-top: 93px !important; */
  padding-left: 10px;
}

.navbar[data-v-af49e32e] {
  padding: 0.5rem 3rem;
  z-index: 1000000000;
}

.thumbnail:hover {
  /* background-color: rgba(72, 72, 72, 0.579); */
  opacity: 0.5;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
  /* width: 100%; */
  text-align: center;
  font-size: large;
}

/* .testCSS {
  background-color: #ee8d33;
  color: white;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 83px;
  cursor: pointer!important;
} */
.testCSS {
  background-color: #7367f0;
  color: white;
  padding-top: 3px;
  padding-bottom: 1px;
  padding-left: 9px;
  padding-right: 10px;
  border-radius: 83px;
  cursor: pointer !important;
  font-size: 12px;
}


.disabled {
  /* You can customize the appearance of the disabled div here */
  /* opacity: 0.5; Example: reduce opacity */
  pointer-events: none;
  display: none;
  /* Example: disable pointer events */
  /* Add any other styles you want to apply when the div is disabled */
}

.loader1 {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  right: 32px;
}

.loader2 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-circle {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #7367f0;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  animation: spin 1s linear infinite;
}

.video-list[data-v-41044b41] {
  background: whitesmoke;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: revert;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.V1Test {
  font-size: 16px;
  color: #7367f0;
}

.V2Test {
  color: #808080f2;
  font-size: 16px;
}



h2 {
  font-weight: 600;
  font-size: 1rem;
  margin-top: 2rem;
  color: black;
}

.form-label {
  margin-bottom: 0.2rem;
}

.form-control {
  background-color: white;
  border: 1px solid #b7b7b7;
}

.process-chart-mobile {
  display: none;
}

.navbar {
  padding: 0.5rem 3rem;
}

.navbar-brand {
  font-size: xx-large;
  font-weight: 600;
}

.nav-item {
  align-items: center;
  font-size: small;
}

.form-section1 {
  position: relative;
  padding: 25px 200px;
  background-color: #eeeeeed4;
  min-height: 100vh;
  padding-top: 100px;
}

.heading-title-circle,
.heading-title {
  padding-right: 2rem;
}

.b-w-msg {
  text-align: start;
  font-size: x-large;
  font-weight: bold;
  margin-top: 3rem;
}

.row {
  justify-content: center;
}

.page-form {
  padding-block: 2rem;
  padding-inline: 4rem;
}

.form-heading {
  padding-left: 20px;
  background-color: white;
  border-radius: 8px;
}

.progress {
  height: 3px;
  margin: -27px 2rem 20px 2rem;
  background-color: rgb(184, 183, 183);
}

.progress-bar {
  width: 50%;
  background-color: black;
}

.process-chart {
  padding: 15px;
}

.input-2 {
  display: flex;
}

.b-box {
  margin: 0px auto;
  width: 30%;
  margin-bottom: 20px;
}

.heading-title {
  color: black;
  list-style: none;
  display: flex;
  justify-content: space-between;
  font-size: x-large;
  font-weight: 600;
}

.form-check-label {
  color: black;
  /* Change the color to your preference */
}

.heading-title-circle {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.vcip {
  margin-left: 4rem;
}

.btn.btn-primary {
  background-color: #7367f0;
  border: 2px solid #7367f0;
  margin-top: 1.5rem;
}

.nav-item {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

form {
  padding: 2rem;
  padding-top: 0rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading-title-circle li {
  width: 17px;
  height: 17px;
  background-color: white;
  border: 2px solid grey;
  border-radius: 50%;
  z-index: 1;
}

.heading-title-circle li.active {
  border: 5px solid black;
  border-radius: 50%;
}

.v-t {
  margin-top: 10px;
  margin-bottom: 2px;
  text-align: start;
}

.form-check {
  display: flex;
  gap: 10px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.input-box .input-1 {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  color: #202124;
  border: 1px solid rgb(0, 0, 0, 0.6);
  padding: 15px;
  transition: 250ms;
}

.btn.btn-primary {
  margin-bottom: 0.85rem;
}

#agreeCheckbox:checked {
  accent-color: #202124;
}

.checkbox-label {
  margin-bottom: 1rem;
}

footer {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  h2 {
    font-weight: bold;
    font-size: large;
    color: black;
    margin-block: 2rem;
  }

  .form-section1 {
    position: relative;
    padding: 0px 0px;
    background-color: white;
    min-height: auto;
  }

  .process-chart {
    display: none;
  }

  .form-container {
    min-height: 70vh;
    margin-top: 9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  form {
    padding-inline: 0.5rem;
  }

  .b-w-msg {
    margin-top: 1rem;
  }

  .v-t {
    margin-top: 1rem;
  }

  .heading-title-circle,
  .heading-title {
    padding-left: 1.2rem;
  }

  .process-chart-mobile {
    display: block;
    position: fixed;
    background-color: rgb(244, 244, 244);
    z-index: 1;
    width: 100%;
    top: 0;
  }

  .heading-title-circle,
  .heading-title {
    padding-right: 0rem;
    padding-left: 1rem;
  }

  .heading-title li {
    font-size: xx-large;
    padding: 1rem;
    margin-right: -1.5rem;
  }

  .heading-title-circle li {
    width: 17px;
    margin-left: 1rem;
    margin-right: -0.5rem;
  }

  .progress.mobile {
    height: 3px;
    margin: -27px 19rem 20px 2rem;
    background-color: rgb(184, 183, 183);
    width: 90%;
  }

  .progress-bar.mobile {
    width: 100%;
  }

  .btn-submit-div {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    right: 2rem;
  }

  .heading-title-circle .li3 {
    margin-left: 2rem;
  }

  .form-check-label {
    font-size: 1.1rem;
  }

  .form-check {
    margin-block: 1rem;
  }

  .btn.btn-primary {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .vcip {
    margin-left: -6rem;
  }

  footer {
    display: block;
    height: 100%;
  }

  .submit-btn-checbox {
    margin-top: 15rem;
  }

  .navbar-nav-footer {
    display: flex;
    column-gap: 22px;
    justify-content: space-between;
    flex-direction: row;
    position: fixed;
    padding: 1rem;
    bottom: 0;
    background-color: rgb(244, 244, 244);
    width: 100%;
  }

  .navbar-nav-footer .nav-link {
    color: grey;
  }

  .navbar-nav-footer .nav-link :nth-child(2) {
    font-size: small;
    font-weight: bold;
  }

  .navbar-nav-footer .nav-link.active {
    color: black;
  }

  .material-symbols-outlined {
    font-size: 34px;
  }



  .video-list[data-v-af49e32e] {
    background: #d8d8d8;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: revert !important;
  }

  [data-v-af49e32e] {
    flex-wrap: revert !important;

  }


  .fa-comment-alt:before,
  .fa-message:before {
    content: "\f27a";
    display: none;
  }


}

.form-section2 {
  position: relative;
  padding: 1rem;
  background-color: #f8f7fa;
  min-height: 100vh;
}

.form-heading {
  padding: 2rem !important;
  background-color: white;
  border-radius: 8px;
}

.form-heading1 {
  padding: 1rem !important;
  background-color: white;
  border-radius: 8px;
}

.form-heading12 {
  padding: 0rem !important;
  background-color: white;
  border-radius: 8px;
  width: 100% !important;
}

.shadow {
  background-clip: padding-box;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
  padding: 1rem 2rem 2rem 2rem;
  width: 75%;
}

.justify-nav {
  justify-content: space-evenly;
}

/* Container Styling */
.webrtc-container {
  border-radius: 12px;
  overflow: hidden;
  background-color: #f4f5f7;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Primary Button Styling */
.btn-primary-custom {
  background-color: #7367f0;
  border-color: #7367f0;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 6px;
  /* transition: background-color 0.3s ease, box-shadow 0.3s ease; */
}

/* Tips Section Styling */
.tips-section {
  background-color: #fff;
  border-radius: 12px;
  border-left: 5px solid #7367f0;
  padding: 20px;
  position: relative;
}

.tips-title {
  font-weight: bold;
  color: #7367f0;
  margin-bottom: 15px;
  font-size: 18px;
}

.tip-item p {
  margin: 0;
  font-size: 15px;
  color: #555;
  line-height: 1.6;
}

.tip-item strong {
  color: #333;
}

/* Status Message */
.status-message {
  font-size: 16px;
  color: #28a745;
  font-weight: bold;
}

.text-lorem {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 36px !important;
  color: rgb(93, 89, 108) !important;
}

.text-size {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #a5a3ae !important;
}

.text-left {
  display: flex;
  justify-content: space-between;
}

.text-lorem {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 36px !important;
  color: rgb(93, 89, 108) !important;
}

.text-size {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #a5a3ae !important;
}

.paddingTop {
  border-left: 4px solid #7367f0;
  background-color: #f9f9ff !important;
}

.popup {
  display: none;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #ffffff;
  margin: 15% auto;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25);
  width: 90%;
  max-width: 400px;
  text-align: center;
  animation: fadeIn 0.4s ease;
  position: relative;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.close-btn {
  color: #333;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: #000;
}

.popup-header {
  margin-bottom: 20px;
}

.popup-title {
  font-size: 24px;
  font-weight: 700;
  color: #333;
  margin-top: 24px;
  margin-bottom: 5px;
  line-height: 1.2;
}

.popup-subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.4;
}

.popup-message {
  font-size: 18px;
  color: #444;
  margin-bottom: 20px;
  line-height: 1.5;
}

.popup-close-btn {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.popup-close-btn:hover {
  background-color: #0056b3;
}
.permission-message {
  text-align: center;
  margin-top: 20px;
  color: #333;
}
.permission-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}
</style>
