<template>
  <section class="form-section">
    <div class="login-page">
      <header class="">
        <div class="vcip-heading d-flex">Identity check</div>
        <div class="acceptane-options-line">
          <div class="acceptance-items">
            <div class="circle active-circle active-check"><div>✔</div></div>
          </div>
          <div class="acceptance-items">
            <div class="circle active-circle">
              <div class="inner-circle"></div>
            </div>
          </div>
          <div class="acceptance-items">
            <div class="circle"><div class="inner-circle"></div></div>
          </div>
        </div>
        <div class="acceptance-options d-flex">
          <div class="active-btn">1</div>
          <div class="active-btn">2</div>
          <div>3</div>
        </div>
        <div class="login-box">
          <div class="vcip-options-div">
            <div
              class="vcip-options pointer"
              @click="navigateToReuploadPanCard"
            >
              <div class="vcip-options-items">
                <div v-if="$route.params.panQuality">
                  <span class="right-icon bg-good">
                    <span class="material-symbols-outlined">done</span>
                  </span>
                </div>
                <div v-else>
                  <span class="right-icon bg-recheck">
                    <span class="material-symbols-outlined">exclamation</span>
                  </span>
                </div>
                Uploaded PAN quality
              </div>
              <div class="vcip-options-items good">
                Good &nbsp;&nbsp;
                <span class="material-symbols-outlined"> chevron_right </span>
              </div>
            </div>
            <div class="vcip-options">
              <div class="vcip-options-items">
                <span class="right-icon bg-good"
                  ><span class="material-symbols-outlined"> done </span></span
                >
                Customer liveliness
              </div>
              <div class="vcip-options-items good">
                Good &nbsp;&nbsp;
                <span class="material-symbols-outlined"> chevron_right </span>
              </div>
            </div>
            <!-- Live photograph quality -->
            <div
              class="vcip-options pointer"
              @click="navigateToLivePhotographQuality"
            >
              <div class="vcip-options-items">
                <span class="right-icon bg-recheck"
                  ><span class="material-symbols-outlined">
                    exclamation
                  </span></span
                >
                Live photograph quality
              </div>
              <div class="vcip-options-items recheck">
                Recheck &nbsp;&nbsp;
                <span class="material-symbols-outlined"> chevron_right </span>
              </div>
            </div>
            <!-- Live captured PAN quality -->
            <div
              class="vcip-options pointer"
              @click="navigateToLiveCapturedOvdQuality"
            >
              <div class="vcip-options-items">
                <span class="right-icon bg-recheck"
                  ><span class="material-symbols-outlined">
                    exclamation
                  </span></span
                >
                Live captured PAN quality
              </div>
              <div class="vcip-options-items recheck">
                Recheck &nbsp;&nbsp;
                <span class="material-symbols-outlined"> chevron_right </span>
              </div>
            </div>
            <div
              class="vcip-options pointer"
              @click="navigateToLiveFaceMatchCheck"
            >
              <div class="vcip-options-items">
                <span class="right-icon bg-recheck"
                  ><span class="material-symbols-outlined">
                    exclamation
                  </span></span
                >
                Face match
              </div>
              <div class="vcip-options-items recheck">
                Recheck &nbsp;&nbsp;
                <span class="material-symbols-outlined"> chevron_right </span>
              </div>
            </div>
            <div class="vcip-options">
              <div class="vcip-options-items">
                <span class="right-icon bg-good"
                  ><span class="material-symbols-outlined"> done </span></span
                >
                Random questionnaire
              </div>
              <div class="vcip-options-items good">
                Good &nbsp;&nbsp;
                <span class="material-symbols-outlined"> chevron_right </span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div class="login-button">
          <button
            class="login-form-button btn"
            @click="navigateToRiskMappingCheck"
            :disabled="agree"
          >
            Next
          </button>
        </div>
      </main>
    </div>
  </section>
</template>

<script>
export default {
  name: "identityCheckDone",
  data() {
    return {
      agree: false,
      panQuality: this.$route.params.panQuality,
      liveliness: this.$route.params.liveliness,
      livePhotoQuality: this.$route.params.livePhotoQuality,
      livePanQuality: this.$route.params.livePanQuality,
      capturedPanQuality: this.$route.params.capturedPanQuality,
      randomQues: this.$route.params.randomQues,
    };
  },
  mounted() {
    document.title = 'Identity Check';
   },
  methods: {
    navigateToReuploadPanCard() {
      this.$router.push("/reuploadPanCard");
    },
    navigateToLivePhotographQuality() {
      this.$router.push("/livePhotographQuality");
    },
    navigateToLiveCapturedOvdQuality() {
      this.$router.push("/liveCapturedOvdQuality");
    },
    navigateToLiveFaceMatchCheck() {
      this.$router.push("/faceMatchCheck");
    },
    navigateToRiskMappingCheck() {
      this.$router.push("/riskMappingCheck");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.right-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  margin-right: 10px;
}
.form-section {
  position: relative;
  padding: 40px 300px;
  background-color: #073991;
  min-height: 100vh;
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 18;
  font-size: 20px;
}

.recheck {
  color: #f7b519;
}
.good {
  color: #009688;
}
.bg-recheck {
  background-color: #f7b519;
}
.bg-good {
  background-color: #009688;
}

.pointer:hover {
  cursor: pointer;
}
.login-page {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 30px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
header.sticky-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #f4f4f4;
  height: 18vh;
}

main {
  overflow-y: auto;
}
.vcip-heading {
  font-size: x-large;
  font-weight: bold;
  text-align: start;
  margin-bottom: 20px;
}
.acceptane-options-line {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
  margin-bottom: 10px;
}
.acceptance-options {
  color: gray;
  font-weight: bold;
  justify-content: space-between;
  margin: 0px 10px;
  margin-left: 14px;
  margin-top: -20px;
  padding-top: 10px;
  background-image: linear-gradient(to right, #6a68db 50%, gray 50%);
  background-size: 100% 2px;
  background-repeat: no-repeat;
}
.acceptance-items {
  display: flex;
  align-items: center;
}
.circle {
  width: 10px;
  height: 10px;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.active-circle {
  background-color: #6a68db;
}
.active-check {
  width: 18px;
  height: 18px;
  color: white;
}
.active-btn {
  color: #6a68db;
}
.inner-circle {
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 50%;
}

.vcip-options {
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fcfcfc;
  padding: 15px;
  border-radius: 8px;
  border-bottom: 2px solid #d4d4d4;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.vcip-options-items {
  display: flex;
  align-items: center;
}
.login-form-button {
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
  text-align: center;
  font-size: large;
  width: 100%;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }
  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
  }
  vcip-options {
    padding: 15px 20px;
  }
  .vcip-options-items {
    font-size: small;
  }
}
</style>
