<template>
  <section class="form-section">    
    <h3 class="">Analytics</h3>
    <div class=" d-flex flex-row gap-3">   
   
      <div class="card-div">
    
     
        <h4>Actual</h4>
        <div class="row borderBottom">
          <div class="col-6  ">No of Videos streamed</div>
          <div class="col-6 text-end">{{ streamedVideoCount }}</div>
        </div>
        <div class="row mt-2 borderBottom">
          <div class="col-6 ">Total Time taken</div>
          <div class="col-6 text-end ">{{ totalTimeTaken }}</div>
        </div>
        <div class="row mt-2 borderBottom ">
          <div class="col-6 ">Average Response Time</div>
          <div class="col-6 text-end">{{ averageResponseTime }}</div>
        </div>
        <div class="row mt-2  borderBottom">
          <div class="col-6 ">Successful Calls</div>
          <div class="col-6 text-end">{{ successCallCount }}</div>
        </div>
        <div class="row mt-2  borderBottom">
          <div class="col-6 ">FaceLiveliness Successful</div>
          <div class="col-6 text-end">{{ successLivelinessCount }}</div>
        </div>
        <div class="row mt-2  borderBottom">
          <div class="col-6 ">Aadhaar Verified</div>
          <div class="col-6 text-end">{{ aadharVerifiedCount }}</div>
        </div>
        <div class="row mt-2  borderBottom">
          <div class="col-6 ">Pan Verified</div>
          <div class="col-6 text-end">{{ panVerifiedCount }}</div>
        </div>
      </div>

      <div class="card-div ">
        <h4>Percentage</h4>
        <div class="row borderBottom">
          <div class="col-6 ">Conversion Rate</div>
          <div class="col-6 text-end">{{ conversionRate }}</div>
        </div>
        <div class="row mt-2 borderBottom">
          <div class="col-6 ">Auto Approval Rate</div>
          <div class="col-6 text-end">{{ autoApprovalRate }}</div>
        </div>
        <div class="row mt-2 borderBottom">
          <div class="col-6 ">Drop Off</div>
          <div class="col-6  text-end">{{ dropOffRate }}</div>
        </div>
        <div class="row mt-2 borderBottom">
          <div class="col-6 ">Successful Calls</div>
          <div class="col-6  text-end">{{ successfullCallRate }}</div>
        </div>
        <div class="row mt-2 borderBottom">
          <div class="col-6 ">Face LiveLiness</div>
          <div class="col-6  text-end">{{ successfullLivelinessRate }}</div>
        </div>
      </div>

      <div class="loader-container" v-if="loader">
        <div class="loader"></div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "analyticsScreen",

  created() {
    this.getAnalyticsCases();
  },

  data() {
    return {
      streamedVideoCount: null,
      totalTimeTaken: null,
      averageResponseTime: null,
      successCallCount: null,
      successLivelinessCount: null,
      aadharVerifiedCount: null,
      panVerifiedCount: null,
      conversionRate: null,
      autoApprovalRate: null,
      dropOffRate: null,
      successfullCallRate: null,
      successfullLivelinessRate: null,
    };
  },
  methods: {
    async getAnalyticsCases() {
      this.loader = true;
      try {
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.get(`${apiUrl}/case/getAnalyticsData`);
        console.log("My data is", response);
        this.streamedVideoCount = response.data.response.streamedVideoCount;
        this.totalTimeTaken = response.data.response.totalTimeTaken;
        this.averageResponseTime = response.data.response.averageResponseTime;
        this.successCallCount = response.data.response.successCallCount;
        this.successLivelinessCount =
          response.data.response.successLivelinessCount;
        this.aadharVerifiedCount = response.data.response.aadharVerifiedCount;
        this.panVerifiedCount = response.data.response.panVerifiedCount;
        this.conversionRate = response.data.response.conversionRate;
        this.autoApprovalRate = response.data.response.autoApprovalRate;
        this.dropOffRate = response.data.response.dropOffRate;
        this.successfullCallRate = response.data.response.successfullCallRate;
        this.successfullLivelinessRate =
          response.data.response.successfullLivelinessRate;

        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.error("Error during API call:", error);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-section {
  padding: 5px 10px;
  background-color: #073991;
  min-height: calc(100vh-80px);
  max-height: calc(100vh-80px);
}
.card-div {
  width: 100%;
  padding: 18px;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  background-color: rgb(255, 255, 255);
  border: 1px solid lightgray;
}
.login-page {
  /* background-color: #f4f4f4; */
  /* border: 1px solid rgb(192, 192, 192); */
  /* padding: 30px; */

  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 90vh; */
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
  z-index: 999; /* Ensure the overlay is on top */
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-primary {
  /* width: 100%; */
  height: 32px;
  padding: 2px 8px;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  /* margin-top: 1.5rem; */
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }
  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 1rem;
  }
  .btn.btn-primary {
    width: 100%;
    /* */
    margin: 1.5rem 0;
  }
}
.borderBottom
{
      border-bottom: 1px solid #f7f5f5!important;

}
.col-6 {
    flex: 0 0 auto;
    width: 50%;
    font-size: 14px;
}
</style>
