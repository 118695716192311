<template>
  <section class="form-section">
    <div class="login-page">
      <div class="login-header p-2">
        <div class="r-live d-flex">
          <div class="r-icon text-black">
            <span class="material-symbols-outlined"> arrow_back_ios_new </span>
          </div>
          <div class="r-live-h fw-bold mx-2">Live photograph quality</div>
        </div>
        <div class="p-heading">
          <small>
            You captured image is blure. <br />
            Please recapture your image
          </small>
        </div>
      </div>
      <div class="camera d-flex">
        <img
          className="photograph"
          src="https://cdn6.dissolve.com/p/D18_249_009/D18_249_009_0004_600.jpg"
          alt=""
        />
        <div v-if="agree">
          <loaderUse />
        </div>
      </div>
      <div class="i mt-2">
        <div class="login-button">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="agree"
            @click="navigateToIdentityCheckDone"
          >
            Recapture Image
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "liveQualityCheck",
  data() {
    return {
      agree: false,
    };
  },
  methods: {
    navigateToIdentityCheckDone() {
      this.agree = true;
      setTimeout(() => {
        this.$router.push("/identityCheckDone");
      }, 2000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
hr {
  margin: 5px 0px;
}
.form-section {
  position: relative;
  padding: 40px 300px;
  background-color: #073991;
  min-height: 100vh;
}
.login-page {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 30px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.camera {
  width: 100%;
  border-radius: 8px;
  align-items: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photograph {
  width: 70%;
}

.btn.btn-primary {
  width: 100%;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  margin-top: 1.5rem;
}

.av-test {
  width: 100%;
  background-color: white;
  height: 10vh;
  align-items: center;
  padding-inline: 1rem;
  border-bottom: 2px solid #e6e6e6;
}
.av-div1 {
  width: 100%;
}
.av-test-btn {
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
}

.i-h {
  color: grey;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }
  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 0px;
    justify-content: center;
  }
  .camera {
    height: 70vh;
    border-radius: 0px;
  }

  .photograph {
    width: 100%;
    height: 50vh;
  }
  .login-button {
    padding-inline: 1rem;
  }

  .p-heading {
    padding-inline: 1rem;
  }
}
</style>
