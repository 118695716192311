<template>
    <section class="form-section">
      <div class="login-page">
       
              <div class="text-black fw-bold">
                <div>
                  <h1>Terms and Conditions</h1>
                  <p>Last update on octuber</p>
                </div>
              </div>
              
          
         
            <div class="p-heading">
            <h4 class=" fw-bold">Acceptance of Terms</h4>
              <p> By accessing or using this website, you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this website. </p>
            </div>
            <div class="p-heading">
            <h4 class=" fw-bold">Use of the Website</h4>
              <p> By accessing or using this website, you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this website. </p>
            </div>
            <div class="p-heading">
            <h4 class=" fw-bold">Privacy Policy</h4>
              <p> By accessing or using this website, you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this website. </p>
            </div>
            <div class="p-heading">
            <h4 class=" fw-bold">Intellectual Property</h4>
              <p> By accessing or using this website, you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this website. </p>
            </div>
            <div class="p-heading">
            <h4 class=" fw-bold">Acceptance of Terms</h4>
              <p> By accessing or using this website, you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this website. </p>
            </div>
            
          
       
        <div class="i mt-2">
          <div class="login-button">
            <button type="button" class="btn btn-primary" @click="navigateToBasicDetails">Back</button>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "TermsAndCondition",
    methods : {
        navigateToBasicDetails(){
        // const id = this.$route.query.id;
        this.$router.push("/basicDetails");
      }
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  hr {
    margin: 5px 0px;
  }
  .form-section {
    position: relative;
    padding: 40px 300px;
    background-color: #073991;
    min-height: 100vh;
  }
  .login-page {
    background-color: #f4f4f4;
    border: 1px solid rgb(192, 192, 192);
    padding: 30px;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 90vh; */
  }
  
  .camera {
    width: 100%;
    height: 50vh;
    background-color: #39bb8f;
    padding: 2rem;
    border-radius: 8px;
    align-items: flex-end;
  }
  
  .btn.btn-primary {
    width: 100%;
    background-color: #ee8d33;
    border: 2px solid #eb780d;
    margin-top: 1.5rem;
  }
  
  .av-test {
    width: 100%;
    background-color: white;
    height: 10vh;
    align-items: center;
    padding-inline: 1rem;
    border-bottom: 2px solid #e6e6e6;
  }
  .av-div1 {
    width: 100%;
  }
  .av-test-btn {
    padding: 5px 20px;
    border-radius: 20px;
    background-color: #ee8d33;
    border: 2px solid #eb780d;
    color: white;
  }
  
  .i-h {
    color: grey;
  }
  
  @media (max-width: 768px) {
    .form-section {
      width: 100%;
      padding: 0px 0px;
    }
    .login-page {
      border: none;
      border-radius: 0px;
      min-height: 100vh;
      padding: 1rem;
    }
    .btn.btn-primary {
    width: 100%;
    /* */
    margin: 1.5rem 0;
  }
    .camera {
      height: 30vh;
      border-radius: 10px;
      margin-bottom: 2rem;
    }
    .login-header{
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-size: larger;
    }
  }
  </style>
  