<template>
  <div class="">
    <section class="form-section">
      <div class="form-box">
        <div class="login-page">
          <div class="login-box">
            <div>
              <div class="vcip-heading d-flex">Risk mapping check</div>
              <div class="acceptane-options-line">
    
                <div class="acceptance-items">
                  <div class="circle active-circle active-check">
                    <div>✔</div>
                  </div>
                </div>
                <div class="acceptance-items">
                  <div class="circle active-circle active-check">
                    <div>✔</div>
                  </div>
                </div>
    
                <div class="acceptance-items">
                  <div class="circle">
                    <div class="inner-circle"></div>
                  </div>
                </div>
              </div>
              <div class="acceptance-options d-flex">
                <div class="active-btn">1</div>
                <div>2</div>
                <div>3</div>
              </div>
              <div class="vcip-options-div">
                <div>
                  <div class="vcip-options" v-if="!caseData?.riskMappingCheck?.ovdsOcrCorrectness">
                    <div class="vcip-options-items">OVDs OCR correctness</div>
                  </div>
    
                  <div class="vcip-options" v-if="caseData?.riskMappingCheck?.ovdsOcrCorrectness">
                    <div class="vcip-options-items">
                      <span class="right-icon bg-good"><span class="material-symbols-outlined">
                          done
                        </span></span>
                      OVDs OCR correctness
                    </div>
                    <div class="vcip-options-items good">
                      Good &nbsp;&nbsp;
                      <span class="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </div>
                </div>
    
                <!-- <div class="vcip-options">
                    <div class="vcip-options-items">OVDs OCR correctness</div>
                  </div> -->
    
    
    
                <div>
                  <div class="vcip-options" v-if="!caseData?.riskMappingCheck?.ovdsMasking">
                    <div class="vcip-options-items">OVDs masking</div>
                  </div>
    
                  <div class="vcip-options" v-if="caseData?.riskMappingCheck?.ovdsMasking">
                    <div class="vcip-options-items">
                      <span class="right-icon bg-good"><span class="material-symbols-outlined">
                          done
                        </span></span>
                      OVDs masking
                    </div>
                    <div class="vcip-options-items good">
                      Good &nbsp;&nbsp;
                      <span class="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </div>
                </div>
    
                <!-- <div class="vcip-options">
                  <div class="vcip-options-items">OVDs masking</div>
                </div> -->
    
    
    
                <div>
                  <div class="vcip-options" v-if="!caseData?.riskMappingCheck?.xmlValidityCheck">
                    <div class="vcip-options-items">XML validity check</div>
                  </div>
    
                  <div class="vcip-options" v-if="caseData?.riskMappingCheck?.xmlValidityCheck">
                    <div class="vcip-options-items">
                      <span class="right-icon bg-good"><span class="material-symbols-outlined">
                          done
                        </span></span>
                      XML validity check
                    </div>
                    <div class="vcip-options-items good">
                      Good &nbsp;&nbsp;
                      <span class="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </div>
                </div>
    
                <!-- <div class="vcip-options">
                  <div class="vcip-options-items">XML validity check</div>
                </div> -->
    
    
    
                <div>
                  <div class="vcip-options" v-if="!caseData?.riskMappingCheck?.panVerification">
                    <div class="vcip-options-items">PAN verification</div>
                  </div>
    
                  <div class="vcip-options" v-if="caseData?.riskMappingCheck?.panVerification">
                    <div class="vcip-options-items">
                      <span class="right-icon bg-good"><span class="material-symbols-outlined">
                          done
                        </span></span>
                      PAN verification
                    </div>
                    <div class="vcip-options-items good">
                      Good &nbsp;&nbsp;
                      <span class="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </div>
                </div>
                <!-- <div class="vcip-options">
                  <div class="vcip-options-items">PAN verification</div>
                </div> -->
    
    
              </div>
            </div>
          </div>
          <div>
            <div class="login-button" v-if="!caseData?.riskMappingCheck">
              <button class="login-form-button btn" @click="updateCaseDetails()">
                Proceed
              </button>
            </div>
            <div class="login-button" v-if="caseData?.riskMappingCheck">
              <button class="login-form-button btn" @click="navigateToIdentyCheck()">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "riskMappingCheck",
  data() {
    return {
      UrlLink:"",
      parsedPanNumber:"",
      caseData: {},
      riskMappingCheck: {
        ovdsOcrCorrectness: "",
        ovdsMasking: "",
        xmlValidityCheck: "",
        panVerification: ""
      }
    };
  },
  created() {

    this.fetchCaseById();
  },
  mounted() {
    document.title = 'Risk Mapping';
   },
  methods: {
    async navigateToStep(stepNumber) {
  this.isLoading = true;
  console.log("Step number:", stepNumber);

  this[`step${stepNumber - 1}`] = false;
  this[`step${stepNumber}`] = true;

  if (stepNumber === 2) {
    alert("Adhar verification");
    debugger;
    const AdharCardVerificationStatus = await this.AdharVerificationCheck(); // Await the async call
    if (AdharCardVerificationStatus === "Good") {
      this.riskMappingCheck = {
        ovdsOcrCorrectness: "Good",
        ovdsMasking: "",
        xmlValidityCheck: "",
        panVerification: ""
      };
    } else {
      this.riskMappingCheck = {
        ovdsOcrCorrectness: "recheck",
        ovdsMasking: "",
        xmlValidityCheck: "",
        panVerification: ""
      };
    }
  }

  if (stepNumber === 3) {
    
   await this.generateDigiLink();
    alert("XML validity check");
    if (this.UrlLink) {
      
      this.riskMappingCheck = {
        ovdsOcrCorrectness: "Good",
        ovdsMasking: "",
        xmlValidityCheck: "Good",
        panVerification: ""
      };

     
      window.open(this.UrlLink, '_blank');
    } 

    else
    {
this.riskMappingCheck = {
        ovdsOcrCorrectness: "Good",
        ovdsMasking: "",
        xmlValidityCheck: "recheck",
        panVerification: ""
      };
    }    

  }

  if (stepNumber === 4) {
    alert("OVDs masking & OCR correctness");
    this.riskMappingCheck = {
      ovdsOcrCorrectness: "Good",
      ovdsMasking: "Good",
      xmlValidityCheck: "Good",
      panVerification: "Good"
    };
  }

  if (stepNumber === 5) {
    alert("PAN verification");
    debugger;
    const panVerificationStatus = await this.PanVerificationCheck(); // Await the async call
    if (panVerificationStatus === "Good") {
      this.riskMappingCheck = {
        ovdsOcrCorrectness: "Good",
        ovdsMasking: "",
        xmlValidityCheck: "Good",
        panVerification: "Good"
      };
    } else {
      this.riskMappingCheck = {
        ovdsOcrCorrectness: "Good",
        ovdsMasking: "",
        xmlValidityCheck: "Good",
        panVerification: "recheck"
      };
    }
  }

  setTimeout(() => {
    this.navigateToIdentityCheckDone();
  }, 3000);
},

async navigateToIdentityCheckDone() {
  let updateData = {
    riskMappingCheck: this.riskMappingCheck,
  };

  try {
    const id = this.$route.query.id;
    const scriptElement = document.querySelector("script[api-url]");
    const apiUrl = scriptElement.getAttribute("api-url");
    const response = await axios.put(
      `${apiUrl}/case/update/${id}`,
      updateData
    );
    this.navigateToIdentyCheck(id);
    console.log("Update response:", response);
  } catch (error) {
    console.error("Update error:", error);
  }
}
,
//     async updateCaseDetails() {
//   try {
//     // Retrieve the stored PAN number from localStorage
//     const storedPanNumber = localStorage.getItem('panCardNumber');
//     const panNumber = storedPanNumber ? JSON.parse(storedPanNumber) : "";

//     // Make a POST request to panVerification endpoint with the stored PAN number
//     const panVerificationResponse = await fetch('http://localhost:3000/case/panVerification', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({ panNumber })
//     });
    
//     // Parse the response from panVerification
//     const panVerificationData = await panVerificationResponse.json();

//     // Check if the PAN numbers match
//     const isPANMatch = panVerificationData.response.essentials.number === panNumber;

//     // Update riskMappingCheck based on the result
//     const riskMappingCheck = {
//       ovdsOcrCorrectness: "Good",
//       ovdsMasking: "Good",
//       xmlValidityCheck: "Good",
//       panVerification: isPANMatch ? "Good" : "Bad" // Update panVerification based on the match
//     };

//     // Prepare data for updating case details
//     const updateData = {
//       riskMappingCheck: riskMappingCheck
//     };

//     // Retrieve case ID
//     const id = this.$route.query.id;

//     // Retrieve API URL
//     const scriptElement = document.querySelector("script[api-url]");
//     const apiUrl = scriptElement.getAttribute("api-url");

//     // Make a PUT request to update case details
//     const updateResponse = await axios.put(
//       `${apiUrl}/case/update/${id}`,
//       updateData
//     );

//     // Log update response
//     console.log("Update response:", updateResponse.data);

//     // Fetch case details after update
//     this.fetchCaseById();
//   } catch (error) {
//     // Handle errors
//     console.error("Update error:", error);
//   }
// },

// async verifyPanNumber(storedPanNumber) {
//   try {
//     const response = await axios.post('http://localhost:3000/case/panVerification', {
//       panNumber: storedPanNumber // Send the PAN number from localStorage in the request body
//     });

  
//     const panNumber = response.data.panNumber;


//     const isPANMatch = panNumber === storedPanNumber;

//     const panVerificationStatus = isPANMatch ? "Good" : "Bad";

//     return panVerificationStatus;
//   } catch (error) {
//     console.error("Error while verifying PAN number:", error);
//     throw error; // rethrowing error to handle it at the caller level if needed
//   }
// },
async PanVerificationCheck() {
  debugger;
  alert("inner 21")
  const storedPanNumber = localStorage.getItem('panNumber');


  
  if (!storedPanNumber) {
    console.error('PAN number not found in localStorage');
    return "Bad";
  }

  try {
    this.loader = true;
    
    const scriptElement = document.querySelector("script[api-url]");
    const pythonApiUrl = scriptElement.getAttribute("api-url");
    
    const formData = new FormData();
    formData.append("panNumber", storedPanNumber);

    const response = await axios.post(`${pythonApiUrl}/case/panVerification`, formData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 200) {
     
      const responsePanNumber = response.data.response.essentials.number;
      const panVerificationStatus = responsePanNumber === storedPanNumber ? "Good" : "Bad";
      return panVerificationStatus;
    } else {
      console.error('PAN verification failed:', response.statusText);
      return "Bad";
    }
  } catch (error) {
    console.error('Error during PAN verification:', error);
    return "Bad";
  } finally {
    this.loader = false;
  }
},


async AdharVerificationCheck() {
  debugger;
  alert("inner 21")
  const storedAdharCard = localStorage.getItem('aadharNumber');
const trimmedAdharCard = storedAdharCard ? storedAdharCard.replace(/\D/g, '') : null;




  
  if (!trimmedAdharCard) {
    console.error('PAN number not found in localStorage');
    return "Bad";
  }

  try {
    this.loader = true;
    
    const scriptElement = document.querySelector("script[api-url]");
    const pythonApiUrl = scriptElement.getAttribute("api-url");
    
    const formData = new FormData();
    formData.append("aadharNumber", trimmedAdharCard);

    const response = await axios.post(`${pythonApiUrl}/case/aadharVerification`, formData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 200) {
     
      const responseAdharCard = response.data.response.essentials.uid;
      const AdharCardVerificationStatus = responseAdharCard === trimmedAdharCard ? "Good" : "Bad";
      return AdharCardVerificationStatus;
    } else {
      console.error('Adhar verification failed:', response.statusText);
      return "Bad";
    }
  } catch (error) {
    console.error('Error during Adhar verification:', error);
    return "Bad";
  } finally {
    this.loader = false;
  }
},



async generateDigiLink() {
  debugger
      try {
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const data = await axios.get(`${apiUrl}/case/generateDigiLink`);
        this.UrlLink = data.data.response.result.url;
       
        console.log("API Response:", this.UrlLink );
      } catch (error) {
        console.error("API Error:", error);
      }
    },







// Function to parse PAN number (example)
    
    // async updateCaseDetails() {

    //   const updateData = {
    //     riskMappingCheck: this.riskMappingCheck,
    //   };

    //   try {
    //     const id = this.$route.query.id;
    //     const scriptElement = document.querySelector("script[api-url]");
    //     const apiUrl = scriptElement.getAttribute("api-url");
    //     const response = await axios.put(
    //       `${apiUrl}/case/update/${id}`,
    //       updateData
    //     );
    //     console.log("Update response:", response);
    //     this.fetchCaseById()
    //   } catch (error) {
    //     console.error("Update error:", error);
    //   }
    // },

    async fetchCaseById() {
      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(
          `${apiUrl}/case/getById/${id}`
        );
        this.caseData = response.data;
        console.log("Case data:", this.caseData);
      } catch (error) {
        console.error("Error fetching case data:", error);
      }
    },

    // navigateToIdentyCheck() {
      // const id = this.$route.query.id;
      // this.$router.push("/vcipin?id=" + id);
    // },
    navigateToIdentyCheck() {


        window.location.reload();


  
},



  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.right-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  margin-right: 10px;
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 18;
  font-size: 20px;
}

.recheck {
  color: #f7b519;
}

.good {
  color: #009688;
}

.bg-recheck {
  background-color: #f7b519;
}

.bg-good {
  background-color: #009688;
}

.form-section {
  background-color: #073991;
  min-height: 100vh;
}

.form-box{
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width:750px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top:30px;
}

.vcip-heading {
  font-size: x-large;
  font-weight: bold;
  text-align: start;
  margin-bottom: 20px;
}

.active-check {
  width: 18px;
  height: 18px;
  color: white;
}

.circle {
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: grey;
}

.active-circle {
  background-color: #6a68db;
}

.active-check {
  width: 18px;
  height: 18px;
  color: white;
}

.right-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  margin-right: 10px;
}

.vcip-options {
  margin: 10px 0px;
  display: flex;
  background-color: #fcfcfc;
  padding: 15px;
  border-radius: 8px;
  border-bottom: 2px solid #d4d4d4;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  /* Box shadow */
}

.active-btn {
  color: #6a68db;
}

.inner-circle {
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 50%;
}

.acceptane-options-line {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
}

.acceptance-options {
  color: gray;
  font-weight: bold;
  justify-content: space-between;
  margin: 0px 10px;
  border-top: 2px solid #6a68db;
  margin-left: 14px;
  margin-top: -10px;
  padding-top: 10px;
}

.acceptance-items {
  display: flex;
  align-items: center;
}

.vcip-options {
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fcfcfc;
  padding: 15px;
  border-radius: 8px;
  border-bottom: 2px solid #d4d4d4;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.vcip-options-items {
  display: flex;
  align-items: center;
}

.login-form-button {
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
  width:100%;
  text-align: center;
  font-size: large;
  margin-top:40px;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    min-width: 100vw;
    margin-top:0px;
  }

  vcip-options {
    padding: 15px 20px;
  }

  .vcip-options-items {
    font-size: small;
  }
}
</style>
