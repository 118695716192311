<template>
  <div class="main-border">
    <div class="d-flex justify-content-between px-2 for-header">
      <div class="fw-bold fs-5">Chat Box</div>
      <div
        class="forcross d-flex justify-content-center align-items-center"
        @click="closePopup"
      >
        X
      </div>
    </div>
    <div class="chat-body">
      <div
        :class="{
          'for-auditor': message.user !== username,
          'for-users': message.user === username,
        }"
        v-for="message in messages"
        :key="message.key"
        style="padding: 10px"
      >
        <div
          :class="{
            'for-user': message.user === username,
            auditor: message.user !== username,
          }"
        >
          <strong>{{ message.user }}:</strong> {{ message.message }}
        </div>
      </div>
      <div
        :class="{
          'for-auditor': typing.user !== username,
          'for-users': typing.user === username,
        }"
        v-if="typing.typing && typing.user != username"
        style="padding: 10px"
      >
        <div
          :class="{
            'for-user': typing.user === username,
            auditor: typing.user !== username,
          }"
        >
          <span
            ><strong>{{ typing.user }}:</strong> {{ "Typing ..." }}</span
          >
        </div>
      </div>
    </div>

    <div class="d-flex for-chat justify-content-center align-items-center">
      <input
        @input="onTyping"
        @keyup.enter="sendMessage"
        @blur="onStopTyping"
        v-model="newMessage"
        placeholder="Type your message"
      />
      <i @click="sendMessage" class="fas fa-paper-plane for-icon"></i>
    </div>
  </div>
</template>

<script>
import {
  ref,
  push,
  serverTimestamp,
  update,
  onChildAdded,
  onValue,
} from "firebase/database";
import { database } from "@/firebase";
import eventBus from "../eventBus";
export default {
  data() {
    return {
      newMessage: "",
      messages: [],
      username: "",
      messagesRef: "",
      group_id: "",
      typing: { user: "", typing: false },
    };
  },
  created() {
    this.username = localStorage.getItem("username");
    this.group_id = this.$route?.query?.id;
    const groupRef = ref(database, `groups/${this.group_id}`);
    update(groupRef, {
      [`users/${this.username}`]: true,
    });
    update(groupRef, {
      [`typing/${this.username}`]: false,
    });
  },
  methods: {
    async sendMessage() {
      try {
        if (!this.newMessage) {
          return;
        }
        const messagesRef = ref(database, `groups/${this.group_id}`);

        push(messagesRef, {
          user: this.username,
          message: this.newMessage,
          timestamp: serverTimestamp(),
        });
        this.newMessage = "";
        this.onStopTyping();
      } catch (error) {
        console.error("Error sending message:", error);
      }
    },
    onTyping() {
      const groupRef = ref(database, `groups/${this.group_id}`);
      update(groupRef, {
        [`typing/${this.username}`]: true,
      });
    },
    onStopTyping() {
      if (!this.newMessage) {
        const groupRef = ref(database, `groups/${this.group_id}`);
        update(groupRef, {
          [`typing/${this.username}`]: false,
        });
      }
    },
    setTypingStatus(userType, isTyping) {
      this.typing["user"] = userType;
      this.typing["typing"] = isTyping || false;
    },
    closePopup() {
      eventBus.$emit("close", "closePopup");
    },
  },
  async mounted() {
    const typingRef = ref(database, `groups/${this.group_id}/typing`);
    const groupRef = ref(database, `groups/${this.group_id}`);

    onValue(typingRef, (snapshot) => {
      const typingData = snapshot.val();

      if (this.username !== "auditor") {
        this.setTypingStatus("auditor", typingData?.auditor);
      }

      if (this.username !== "user") {
        this.setTypingStatus("user", typingData?.user);
      }
    });

    onChildAdded(groupRef, (snapshot) => {
      const message = snapshot.val();
      if (message.message) this.messages.push(message);
    });
  },
  watch: {
    messages: {
      handler(newMessages, oldMessages) {
        console.log("Messages updated");
        console.log("Old Messages:", oldMessages);
        console.log("New Messages:", newMessages);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.for-chat {
  background-color: rgb(54, 64, 99);
  padding: 5px 10px;
  /* position: fixed;
  bottom: 10px; */
  border-end-start-radius: 10px;
  /* right: 27px; */
  /* width: 17.3%; */

  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}
.main-border {
  position: fixed;
  right: 0;
  bottom: 10px;
  z-index: 1;
  width: 22%;
}
.for-users {
  font-size: small;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.for-auditor {
  font-size: small;
  /* background-color:rgb(66, 88, 167); */
  width: 50%;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}
.chat-body {
  background-color: rgb(209, 220, 252);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
}
.for-user {
  background-color: rgb(156, 108, 232);
  width: 50%;
  padding: 5px;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.auditor {
  padding: 5px;
  border-radius: 10px;
  background-color: rgb(254, 254, 254);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.for-icon {
  cursor: pointer;
  color: #fff;
}
.for-chat input {
  outline: none;
  border: none;
  color: #fff;
  background-color: transparent;
  mask-border-outset: none;
}
.for-header {
  background-color: rgb(54, 64, 99);
  padding: 5px 10px;
  color: white;
  /* position: fixed; */
  /* width: 17.3%; */
  /* right: 27px; */
  border-start-start-radius: 10px;
}
.forcross {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: transparent;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.3);
  color: white;
  cursor: pointer;
}
</style>
