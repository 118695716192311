<template>
  <section class="form-section">
    <div class="login-page">
      <div v-if="!fileSended">
        <div class="send">Sending...</div>
      </div>
      <div v-if="!fileSended">
        <div class="progress mt-5" style="height: 10px">
          <div
            class="progress-bar progress-fill"
            role="progressbar"
            style="width: 25%; background-color: #75b800"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
      <div v-if="fileSended">
        <div class="sended">File sended successfully, Thankyou</div>
        <div class="redirect">You will be redirected soon</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "sendingFile",
  data() {
    return {
      fileSended: false
    };
  },
  methods: {
    fileSendedCheck() {
      setTimeout(()=>{
        this.fileSended = true;
        setTimeout(()=>this.$router.push("basicDetails"),3000);
      },5000)
    }
  },
  mounted() {
    document.title = 'Sending File'
    this.fileSendedCheck();
  }
};
</script>


<style scoped>
hr {
  margin: 5px 0px;
}
.form-section {
  position: relative;
  padding: 40px 300px;
  background-color: #073991;
  min-height: 100vh;
}
.login-page {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 30px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
}
.send {
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: x-large;
  font-weight: bold;
}

.sended{
  text-align: center;
  font-weight: bold;
  font-size: x-large;
}

.redirect{
  text-align: center;
  font-weight: bold;
  font-size: large;
}

.progress-fill {
  animation: progress-animation 5s ease forwards;
}
@keyframes progress-animation {
  0% {
    width: 0%;
  }
  50% {
    width: 70%;
  }
  100% {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }
  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 1rem;
  }
}
</style>
