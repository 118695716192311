import { ref } from 'vue';

const eventBus = {
  handlers: ref({}),

  $on(event, handler) {
    if (!this.handlers[event]) {
      this.handlers[event] = [];
    }

    if (typeof handler === 'function') {
      this.handlers[event].push(handler);
    } else {
      console.error('Event handler must be a function');
    }
  },

  $emit(event, ...args) {
    if (this.handlers[event]) {
      this.handlers[event].forEach(handler => {
        if (typeof handler === 'function') {
          handler(...args);
        } else {
          console.error(`Invalid handler for event ${event}`);
        }
      });
    }
  },

  $off(event, handler) {
    if (this.handlers[event]) {
      this.handlers[event] = this.handlers[event].filter(h => h !== handler);
    }
  },
};

export default eventBus;