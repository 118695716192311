<template>
  <!-- <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="#">vCIP</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" style="justify-content: flex-end" id="navbarNavAltMarkup">
        <div class="navbar-nav" style="column-gap: 30px">
          <a class="nav-item nav-link active" href="#">
            <span class="material-symbols-outlined"> border_color </span>
            <span>Apply</span>
          </a>
        </div>
      </div>
    </nav>
  </div> -->
  <div class="row d-flex flex-row w-100  pt-2 bgColour justify-content-center flex-wrap">
    <div class="col-md-7 paddingTop">
      <img src="https://img.freepik.com/premium-photo/bank-loan-illustration-customer-apply-loan-this-illustration-can-be-use-website_1199132-159005.jpg?w=740" alt="Description of the image">
    </div>
    <div class="col-md-5 form-container h-100 pt-format">
      <div class="details-login">
        <!-- <h1><span class="material-symbols-outlined"> person </span></h1> -->
        <div class="app-brand mb-4"><img src="../assets/vcip.png" alt="" style="width: 12%;"></div>
        <h3 class="mb-1 text-font">Welcome Back!</h3>
        <!-- <p>Welcome to our loan login portal. Access funds securely, manage accounts effortlessly.</p> -->
        <p class="mb-4 text-size">Sign Up or Login to get started</p>
        <label for="email-mask" class="form-label email-mask">Email</label>
        <!-- <div class="mb-3">
          <input type="text" class="form-control" id="userId" v-model="email" placeholder="Email" />
        </div> -->
        <div class="input-group mb-3">
              <input
                type="email"
                id="userId"
                class="form-control"
                v-model="email"
                placeholder="Email"
                name="phoneNumber"
              />
            </div>

        <div class="password-container input-group mb-2">
          <input type="password" v-model="password" class="password-input" placeholder=" Password">
          <span class="toggle-password" @click="togglePassword">
            <i class="fa" :class="{ 'fa-eye': !showPassword, 'fa-eye-slash': showPassword }" aria-hidden="true"></i>
          </span>
        </div>
        <div class="mb-3">
          <div class="login-button">
            <button type="button" class="btn btn-primary" :disabled="agree" @click="checkUserIdPassword()">
              Log in
            </button>
          </div>
        </div>
        <p class="text-center text-size"><span>By Continuing, you agree to <i>VCIP</i></span></p>
        <div class="text-center text-size"><span style="color: #7367f0; cursor: pointer;"><a>Terms of Use</a></span> and <span style="color: #7367f0; cursor: pointer;"><a>Privacy Policy</a></span></div>
        <div v-if="!isValid">
          <div class="text-danger"><small>{{ errorMessage }}</small></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';

export default {

  name: 'logIn',
  data() {
    return {
      agree: false,
      email: '',
      password: '',
      isValid: true,
      errorMessage: '',
      showPassword: false
    };
  },
  mounted() {
    document.title = 'Login';
    document.addEventListener('keydown', this.handleKeyPress);
  },
  methods: {
    async checkUserIdPassword() {
      console.log(this.baseUrl, "baseurl")
      const data = {
        email: this.email,
        password: this.password,
      }
      try {
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/auth/logIn`, data);

        if (response.data.token) {
          this.isValid = true;
          this.errorMessage = '';
          localStorage.setItem("username", response.data.username);
          localStorage.setItem("token", response.data.token);
          // Check the username and redirect accordingly
          if (response.data.username === "auditor") {
            localStorage.setItem("role", "AUDITOR");
            this.$router.push("/dashboardScreen");
          } else {
            this.navigateToBasicDetails()
          }
        }
      } catch (error) {
        this.errorMessage = 'Invalid email or password';
        this.isValid = false;
        this.email = '';
        this.password = '';
      }
    },
    navigateToBasicDetails() {
      this.$router.push("/basicDetails");
    },

    togglePassword() {
      this.showPassword = !this.showPassword;
      const inputField = document.querySelector('.password-input');
      if (this.showPassword) {
        inputField.type = 'text'; // change input type to text to show password
      } else {
        inputField.type = 'password'; // change input type back to password to hide password
      }
    },

    handleKeyPress(event) {
  if (event.key === 'Enter' && !this.agree) {
    event.preventDefault(); // This prevents the form from submitting
    this.checkUserIdPassword();
  }
}


  },


  beforeDestroy() {
    // Remove event listener to avoid memory leaks
    document.removeEventListener('keydown', this.handleKeyPress);
  }


};
</script>

<style scoped>
.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

h1 {
  color: black;
  margin-block: 1rem;
}

.form-label {
  margin-bottom: 0.2rem;
}

.form-control {
  background-color: white;
  border: 1px solid #b7b7b7;
}

.process-chart-mobile {
  display: none;
}

.navbar {
  padding: 0.5rem 3rem;
  background-color: #f2f2f2 !important;
}

.navbar-brand {
  font-size: xx-large;
  font-weight: 600;
}

.nav-item {
  align-items: center;
  font-size: small;
}

.form-section {
  position: relative;
  padding: 40px 300px;
  background-color: #073991;
  min-height: 100vh;
}

.heading-title-circle,
.heading-title {
  padding-right: 2rem;
}

.b-w-msg {
  text-align: start;
  font-size: x-large;
  font-weight: bold;
  margin-top: 3rem;
}

.row {
  justify-content: center;
}

.page-form {
  padding-block: 2rem;
  padding-inline: 4rem;
}

.form-heading {
  padding-left: 20px;
  background-color: white;
  border-radius: 8px;
}

.progress {
  height: 3px;
  margin: -27px 2rem 20px 2rem;
  background-color: rgb(184, 183, 183);
}

.progress-bar {
  width: 50%;
  background-color: black;
}

.process-chart {
  padding: 15px;
}

.input-2 {
  display: flex;
}

.b-box {
  margin: 0px auto;
  width: 30%;
  margin-bottom: 20px;
}

.heading-title {
  color: black;
  list-style: none;
  display: flex;
  justify-content: space-between;
  font-size: x-large;
  font-weight: 600;
}

.form-check-label {
  color: black;
  /* Change the color to your preference */
}

.heading-title-circle {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.vcip {
  margin-left: 4rem;
}

.btn.btn-primary {
  width: 100%;
  background-color: #7367f0;
  border: 2px solid #7367f0;
  margin-top: 1.5rem;
}

.login-button {
  margin-top: 0rem;
}

.nav-item {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

form {
  padding-top: 0rem;
}

.form-container {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
}

.heading-title-circle li {
  width: 17px;
  height: 17px;
  background-color: white;
  border: 2px solid grey;
  border-radius: 50%;
  z-index: 1;
}

.heading-title-circle li.active {
  border: 5px solid black;
  border-radius: 50%;
}

.v-t {
  margin-top: 10px;
  margin-bottom: 2px;
  text-align: start;
}

.form-check {
  display: flex;
  gap: 10px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.input-box .input-1 {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  color: #202124;
  border: 1px solid rgb(0, 0, 0, 0.6);
  padding: 15px;
  transition: 250ms;
}

.btn.btn-primary {
  margin-bottom: 0.85rem;
}

#agreeCheckbox:checked {
  accent-color: #202124;
}

.checkbox-label {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .col-sm-6 {
    flex: 0 0 auto;
    width: 60%;
  }

}


@media (max-width: 800px) {

  .row[data-v-4b8269ae] {
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  h2 {
    font-weight: bold;
    font-size: large;
    color: black;
    margin-block: 2rem;
  }

  .logo {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .form-section {
    position: relative;
    padding: 0px 0px;
    background-color: white;
    min-height: auto;
  }

  .process-chart {
    display: none;
  }

  .details-login {
    margin-top: 3rem;
  }

  .form-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  form {
    padding-inline: 0.5rem;
  }

  .login-button {
    margin-top: 0rem;
  }

  .b-w-msg {
    margin-top: 1rem;
  }

  .v-t {
    margin-top: 1rem;
  }

  .heading-title-circle,
  .heading-title {
    padding-left: 1.2rem;
  }

  .process-chart-mobile {
    display: block;
    position: fixed;
    background-color: rgb(244, 244, 244);
    z-index: 1;
    width: 100%;
    top: 0;
  }

  .heading-title-circle,
  .heading-title {
    padding-right: 0rem;
    padding-left: 1rem;
  }

  .heading-title li {
    font-size: xx-large;
    padding: 1rem;
    margin-right: -1.5rem;
  }

  .heading-title-circle li {
    width: 17px;
    margin-left: 1rem;
    margin-right: -0.5rem;
  }

  .progress.mobile {
    height: 3px;
    margin: -27px 19rem 20px 2rem;
    background-color: rgb(184, 183, 183);
    width: 90%;
  }

  .progress-bar.mobile {
    width: 100%;
  }

  .btn-submit-div {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    right: 2rem;
  }

  .heading-title-circle .li3 {
    margin-left: 2rem;
  }

  .form-check-label {
    font-size: 1.1rem;
  }

  .form-check {
    margin-block: 1rem;
  }

  .btn.btn-primary {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .material-symbols-outlined {
    font-size: 34px;
  }

}

.password-container {
  position: relative;
  width: 100%;
}

.password-input {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.bgColour {
  background: #f8f9f9;
  height: 100vh;
}

.paddingTop {
  padding-top: 5.3rem;
}

.bgColour2 {
  background: #ec749c;
}

.pt-format {
    padding: 3rem;
    display: grid;
    align-items: center;
  }

.email-mask {
  margin-bottom: 0.25rem !important;
  font-size: 0.8125rem !important;
  color: #5d596c;
}
.text-size {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
  color: rgb(111, 107, 125);
}
body {
    font-family: 'Lato', 'Public Sans', sans-serif !important;
}
.text-font {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 26px;
  line-height: 36px;
  color: rgb(93, 89, 108);
}
</style>
