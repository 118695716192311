<template>
  <div class="ajaxLoad">
    <div
      v-for="index in numberOfDots"
      :key="index"
      :class="'l l' + index"
    ></div>
  </div>
</template>
<script>
export default {
  name: "loaderUse",
  data() {
    return {
      numberOfDots: 10 
    };
  }
};
</script>
<style scoped>
@keyframes dotColor {
  0%,
  49% {
    background: #DDDDDD;
  }
  50%,
  99% {
    background: #B7B7B7;
  }
}

.ajaxLoad {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.l {
  position: absolute;
  width: 4px;
  height: 4px;
  background: #b98b5a;
  border-radius: 2px;
}

.l1 {
  left: 0;
  top: -15px;
  animation: dotColor 2s infinite 0s;
}
.l2 {
  left: 8px;
  top: -12px;
  animation: dotColor 2s infinite 0.1s;
}
.l3 {
  left: 14px;
  top: -5px;
  animation: dotColor 2s infinite 0.2s;
}
.l4 {
  left: 14px;
  top: 5px;
  animation: dotColor 2s infinite 0.3s;
}
.l5 {
  left: 8px;
  top: 12px;
  animation: dotColor 2s infinite 0.4s;
}
.l6 {
  left: 0;
  top: 15px;
  animation: dotColor 2s infinite 0.5s;
}
.l7 {
  left: -8px;
  top: 12px;
  animation: dotColor 2s infinite 0.6s;
}
.l8 {
  left: -14px;
  top: 5px;
  animation: dotColor 2s infinite 0.7s;
}
.l9 {
  left: -14px;
  top: -5px;
  animation: dotColor 2s infinite 0.8s;
}
.l10 {
  left: -8px;
  top: -12px;
  animation: dotColor 2s infinite 0.9s;
}
</style>
