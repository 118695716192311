<template>
  <section class="form-section">
    <div class="form-box">
      <div class="login-page">
        <div class="login-box">
          <div>
            <div class="vcip-heading d-flex">Acceptance check</div>
            <div class="acceptane-options-line">
              <div class="acceptance-items">
                <div class="circle active-circle">
                  <div class="inner-circle"></div>
                </div>
              </div>
              <div class="acceptance-items">
                <div class="circle">
                  <div class="inner-circle"></div>
                </div>
              </div>
              <div class="acceptance-items">
                <div class="circle">
                  <div class="inner-circle"></div>
                </div>
              </div>
            </div>
            <div class="acceptance-options d-flex">
              <div class="active-btn">1</div>
              <div>2</div>
              <div>3</div>
            </div>
            <div class="vcip-options-div">
              <div>
                <div
                  class="vcip-options"
                  v-if="!caseData?.acceptanceCheck?.lightCheck"
                >
                  <div class="vcip-options-items">Light check</div>
                </div>

                <div
                  class="vcip-options"
                  v-if="caseData?.acceptanceCheck?.lightCheck"
                >
                  <div class="vcip-options-items">
                    <span class="right-icon bg-good"
                      ><span class="material-symbols-outlined">
                        done
                      </span></span
                    >
                    Light check
                  </div>
                  <div class="vcip-options-items good">
                    {{caseData?.acceptanceCheck?.lightCheck}} &nbsp;&nbsp;
                    <span class="material-symbols-outlined">
                      chevron_right
                    </span>
                  </div>
                </div>
              </div>

              <div
                class="vcip-options"
                v-if="!caseData?.acceptanceCheck?.audioVideoCheck"
              >
                <div class="vcip-options-items">Audio & Video check</div>
              </div>

              <div
                class="vcip-options"
                v-if="caseData?.acceptanceCheck?.audioVideoCheck"
                @click="handleClick()"
              >
                <div class="vcip-options-items">
                  <span :class="['right-icon', caseData?.acceptanceCheck?.audioVideoCheck === 'Good' ? 'bg-good' : 'bg-warning']"
                    ><span class="material-symbols-outlined"> {{caseData?.acceptanceCheck?.audioVideoCheck == 'Good'?"done":"exclamation"}} </span></span
                  >
                  Audio & Video check
                </div>
                 <div :class="['vcip-options-items', caseData?.acceptanceCheck?.audioVideoCheck === 'Good' ? 'good' : 'recheck']">
                   {{caseData?.acceptanceCheck?.audioVideoCheck}} &nbsp;&nbsp;
                  <span class="material-symbols-outlined"> chevron_right </span>
                </div>
              </div>

              <div
                class="vcip-options"
                v-if="!caseData?.acceptanceCheck?.backgroundCheck"
              >
                <div class="vcip-options-items">Background check</div>
              </div>

              <div
                class="vcip-options"
                v-if="caseData?.acceptanceCheck?.backgroundCheck"
              >
                <div class="vcip-options-items">
                  <span class="right-icon bg-good"
                    ><span class="material-symbols-outlined"> done </span></span
                  >
                  Background check
                </div>
                <div class="vcip-options-items good">
                  Good &nbsp;&nbsp;
                  <span class="material-symbols-outlined"> chevron_right </span>
                </div>
              </div>

              <div
                class="vcip-options"
                v-if="!caseData?.acceptanceCheck?.latLongCheck"
              >
                <div class="vcip-options-items">Lat long check</div>
              </div>

              <div
                class="vcip-options"
                v-if="caseData?.acceptanceCheck?.latLongCheck"
              >
                <div class="vcip-options-items">
                  <span class="right-icon bg-good"
                    ><span class="material-symbols-outlined"> done </span></span
                  >
                  Lat long check
                </div>
                <div class="vcip-options-items good">
                  Good &nbsp;&nbsp;
                  <span class="material-symbols-outlined"> chevron_right </span>
                </div>
              </div>
              <!-- <div class="vcip-options" @click="vkyc()">
                <div class="vcip-options-items">Vkyc check</div>
              </div> -->
            </div>
          </div>
        </div>
        <div>
          <div class="login-button">
            <button
              class="login-form-button btn"
              @click="navigateToIdentyCheck()"
              v-if="areAllAcceptanceChecksDone"
            >
              Next
            </button>
          </div>
          <div class="login-button">
            <button
              class="login-form-button btn"
              v-if="!areAllAcceptanceChecksDone"
              @click="navigateToLiveAcceptanceCheck(1)"
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
      <div class="loader-container" v-if="loader">
        <div class="loader"></div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "acceptanceCheck",
  mounted() {
    document.title = 'Acceptance Check';
   },
  data() {
    return {
      caseData: {},
      loader: false,
    };
  },

  created() {
    this.fetchCaseById();
  },

  computed: {
    areAllAcceptanceChecksDone() {
      return (
        this.caseData?.acceptanceCheck?.lightCheck &&
        this.caseData?.acceptanceCheck?.audioVideoCheck &&
        this.caseData?.acceptanceCheck?.backgroundCheck &&
        this.caseData?.acceptanceCheck?.latLongCheck
      );
    },
  },

  methods: {
    navigateToLiveAcceptanceCheck(step) {
      const id = this.$route.query.id;
      this.$router.push(`/liveAcceptanceCheck?id=${id}&step=${step}`);
    },
    handleClick(){
      if (this.caseData?.acceptanceCheck?.audioVideoCheck === 'Recheck') {
        this.navigateToLiveAcceptanceCheck(2);
      }
    },

    navigateToIdentyCheck() {
      const id = this.$route.query.id;
      const endpoint = this.caseData?.selectedSteps?.Identity?`identityCheck?id=${id}`:this.caseData?.selectedSteps?.Risk?`riskMappingCheck?id=${id}`:`auditCheck?id=${id}`;
      this.$router.push(endpoint);
    },

    async fetchCaseById() {
      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const api = scriptElement.getAttribute("api-url");
        const apiUrl = `${api}/case/getById/${id}`;
        const response = await axios.post(apiUrl);
        this.caseData = response.data;
        console.log("Case data:", this.caseData);
      } catch (error) {
        console.error("Error fetching case data:", error);
      }
    },
    async vkyc() {
      this.loader = true;
      const endpoint = `vkyc?roomid=${this.$route.query.id}`;
      try {
        this.loader = true;
        this.$router.push(endpoint);
      } catch (error) {
        this.loader = true;
        console.log(error);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
}

.form-section {
  background-color: #073991;
  /* min-height: 100vh; */
}

.form-box{
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 3px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width:750px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top:30px;
}

.vcip-heading {
  font-size: x-large;
  font-weight: bold;
  text-align: start;
  margin-bottom: 20px;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.active-circle {
  background-color: #6a68db;
}

.active-btn {
  color: #6a68db;
}

.inner-circle {
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 50%;
}

.acceptane-options-line {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
}

.acceptance-options {
  color: gray;
  font-weight: bold;
  justify-content: space-between;
  margin: 0px 10px;
  border-top: 2px solid grey;
  margin-top: -5px;
  padding-top: 10px;
}

.vcip-options {
  margin: 10px 0px;
  display: flex;
  background-color: #fcfcfc;
  padding: 15px;
  border-radius: 8px;
  border-bottom: 2px solid #d4d4d4;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  /* Box shadow */
}

.login-form-button {
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
  width: 100%;
  text-align: center;
  font-size: large;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    min-width: 100vw;
    margin-top:0px;
  }

  .vcip-options {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
  }
}

.vcip-options-items {
  display: flex;
  align-items: center;
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 18;
  font-size: 20px;
}

.recheck {
  color: #f7b519;
}
.good {
  color: #009688;
}
.bg-recheck {
  background-color: #f7b519;
}
.bg-good {
  background-color: #009688;
}
.circle {
  width: 10px;
  height: 10px;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.right-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  margin-right: 10px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
  z-index: 999; /* Ensure the overlay is on top */
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
