<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="#">vCIP</a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse"
        style="justify-content: flex-end"
        id="navbarNavAltMarkup"
      >
        <div class="navbar-nav" style="column-gap: 30px">
            Hello Test Singh
        </div>
      </div>
    </nav>
    <section class="form-section">
      <div class="login-page">
        <div>
          <ul>
            <li @click="dashboard">
              <a href="#about">Home</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="open-screen">
        <div class="row" style="text-align: center">
          <div class="col-md-8">
            <div style="margin-top: 80px;">
              <img
                src="https://cdn-icons-png.flaticon.com/512/5754/5754910.png"
                style="width: 200px; height: 150px"
              />
              <h5 style="margin-top: 15px;">You are online</h5>
            </div>
          </div>
          <div class="col-md-4">
            <div style="margin-top: 80px; text-align: justify;">
                <div style="font-weight: 800;">My Stats</div>
                <div> <b>Calls Complete</b> : 2</div>
                <div class="mt-4"> <b>Calls Recieved</b> : 2</div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "customerSupport",
  data() {
    return {};
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  padding: 0.5rem 3rem;
  position: fixed;
  width: 100%;
  height: 80px;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 5px 6px rgba(0, 0, 0, 0.04);
  z-index: 100;
}

.navbar-brand {
  font-size: xx-large;
  font-weight: 600;
}

.nav-item {
  align-items: center;
  font-size: small;
}

/* .form-section {
    position: relative;
    background-color: #073991;
    height: 100%;
  } */
.login-page {
  background-color: rgb(248, 249, 250);
  /* overflow-y: auto; */
  top: 80px;
  position: fixed;
  z-index: 90;
  min-height: calc(100vh - 80px);
  box-shadow: 0 10px 20px 1px rgba(0, 0, 0, 0.06),
    0 1px 5px rgba(11, 11, 11, 0.08);
}
.open-screen {
  top: 80px;
  width: calc(100% - 120px);
  background-color: #fff;
  /* padding: 2rem 2rem 0; */
  position: relative;
  min-height: calc(100vh - 80px);
  float: right;
}
ul {
  list-style: none;
  width: 120px;
  height: 100%;
  margin: 0;
  padding: 0;
}
li {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  color: #05070b;
  cursor: pointer;
  border-bottom: 1px solid #d1d5db;
}
a {
  text-decoration: none;
  color: #05070b;
}

.btn.btn-primary {
  width: 100%;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  margin-top: 1.5rem;
}

.av-test-btn {
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }
  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 1rem;
  }
  .btn.btn-primary {
    width: 100%;
    /* */
    margin: 1.5rem 0;
  }
  .camera {
    height: 30vh;
    border-radius: 10px;
    margin-bottom: 2rem;
  }
  .login-header {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: larger;
  }
}
</style>
