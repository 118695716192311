<template>
  <section class="form-section">
    <div class="login-page">
      <div class="login-header p-2">
        <div class="r-live d-flex">
          <div class="r-icon text-black d-flex">
            <span class="material-symbols-outlined"> arrow_back_ios_new </span>
            <div class="r-live-h fw-bold mx-2 fs-larger">Face match check failed</div>
          </div>
          <div style="color: #eb780d">
            <span class="material-symbols-outlined"> sentiment_satisfied </span>
          </div>
        </div>
        <div class="p-heading">
          <small>
            You captured image and uploaded document is not matched <br />
            Please upload the document and try again.
          </small>
        </div>
      </div>
      <div class="camera">
        <div class="circular-progress mt-4" :style="progressBarStyle">
          <div class="value-container fw-bold"> <div class="text-center">{{ progressValue }}% </div>Match</div>
        </div>
        <div class="scroll-container d-flex">
          <div class="livephoto">
            <img
              src="https://cdn6.dissolve.com/p/D18_249_009/D18_249_009_0004_600.jpg"
              alt="Forest"
            />
            <span class="fw-bold mt-3">Live photograph quality</span>
            <div class="circular-progress2 mt-2" :style="progressBarStyle2">
              <div class="value-container2 fw-bold">{{ progressValue2 }}%</div>
            </div>
          </div>
          <div class="aadhar">
            <img
              src="https://cdn.pixabay.com/photo/2022/11/09/00/44/aadhaar-card-7579588_1280.png"
              alt="Cinque Terre"
            />
            <span class="fw-bold mt-3">Upload OVDs</span>
            <div class="circular-progress2 mt-2" :style="progressBarStyle3">
              <div class="value-container2 fw-bold">{{ progressValue3 }}%</div>
            </div>
          </div>
          <div class="pan">
            <img
              src="https://cdn.pixabay.com/photo/2022/11/09/00/45/pan-card-7579594_1280.png"
              alt="Mountains"
            />
            <span class="fw-bold mt-3">Live captured OVDs</span>
            <div class="circular-progress2 mt-2" :style="progressBarStyle4">
              <div class="value-container2 fw-bold">{{ progressValue4 }}%</div>
            </div>
          </div>
          <div class="liveness">
            <img
              src="https://cdn6.dissolve.com/p/D18_249_009/D18_249_009_0004_600.jpg"
              alt="Forest"
            />
            <span class="fw-bold mt-3">Liveliness check</span>
            <div class="circular-progress2 mt-2" :style="progressBarStyle5">
              <div class="value-container2">{{ progressValue5 }}%</div>
            </div>
          </div>
        </div>
      </div>
      <div class="i mt-4">
        <div class="login-button">
          <button type="submit" class="btn btn-primary" @click="navigateToIdentityCheckDone">Recheck</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "faceMatchCheck",
  data() {
    return {
      progressValue: 0,
      progressEndValue: 42,
      progressValue2: 0,
      progressEndValue2: 98,
      progressValue3: 0,
      progressEndValue3: 34,
      progressValue4: 0,
      progressEndValue4: 65,
      progressValue5: 0,
      progressEndValue5: 21,
      speed: 30,
      progressInterval: null,
      progressInterval2: null,
      progressInterval3: null,
      progressInterval4: null,
      progressInterval5: null,
    };
  },
  computed: {
    progressBarStyle() {
      return {
        background: `conic-gradient(
          red ${this.progressValue * 3.6}deg,
          #DFDFDF ${this.progressValue * 3.6}deg
        )`,
      };
    },
    progressBarStyle2() {
      return {
        background: `conic-gradient(
          green ${this.progressValue2 * 3.6}deg,
          #DFDFDF ${this.progressValue2 * 3.6}deg
        )`,
      };
    },
    progressBarStyle3() {
      return {
        background: `conic-gradient(
          green ${this.progressValue3 * 3.6}deg,
          #DFDFDF ${this.progressValue3 * 3.6}deg
        )`,
      };
    },
    progressBarStyle4() {
      return {
        background: `conic-gradient(
          green ${this.progressValue4 * 3.6}deg,
          #DFDFDF ${this.progressValue4 * 3.6}deg
        )`,
      };
    },
    progressBarStyle5() {
      return {
        background: `conic-gradient(
          green ${this.progressValue5 * 3.6}deg,
          #DFDFDF ${this.progressValue5 * 3.6}deg
        )`,
      };
    },
  },
  methods: {
    navigateToIdentityCheckDone(){
      this.$router.push("/identityCheckDone")
    }
    ,startProgress() {
      this.progressInterval = setInterval(() => {
        this.progressValue++;
        if (this.progressValue >= this.progressEndValue) {
          clearInterval(this.progressInterval);
        }
      }, this.speed);
    },
    startProgress2() {
      this.progressInterval2 = setInterval(() => {
        this.progressValue2++;
        if (this.progressValue2 >= this.progressEndValue2) {
          clearInterval(this.progressInterval2);
        }
      }, this.speed);
    },
    startProgress3() {
      this.progressInterval3 = setInterval(() => {
        this.progressValue3++;
        if (this.progressValue3 >= this.progressEndValue3) {
          clearInterval(this.progressInterval3);
        }
      }, this.speed);
    },
    startProgress4() {
      this.progressInterval4 = setInterval(() => {
        this.progressValue4++;
        if (this.progressValue4 >= this.progressEndValue4) {
          clearInterval(this.progressInterval4);
        }
      }, this.speed);
    },
    startProgress5() {
      this.progressInterval5 = setInterval(() => {
        this.progressValue5++;
        if (this.progressValue5 >= this.progressEndValue5) {
          clearInterval(this.progressInterval5);
        }
      }, this.speed);
    },
  },
  mounted() {
    this.startProgress();
    this.startProgress2();
    this.startProgress3();
    this.startProgress4();
    this.startProgress5();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.circular-progress {
  position: relative;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-bottom: 10px;
}
.circular-progress:before {
  content: "";
  position: absolute;
  height: 88%;
  width: 88%;
  background-color: #ffffff;
  border-radius: 50%;
}
.value-container {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: red;
}
.circular-progress2 {
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-bottom: 10px;
}
.circular-progress2:before {
  content: "";
  position: absolute;
  height: 86%;
  width: 86%;
  background-color: #ffffff;
  border-radius: 50%;
}
.value-container2 {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: green;
}


div.scroll-container {
  overflow: auto;
  white-space: nowrap;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

div.scroll-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
div.scroll-container div img {
  width: 200px;
  height: 200px;
}

hr {
  margin: 5px 0px;
}

.form-section {
  position: relative;
  padding: 40px 300px;
  background-color: #073991;
  min-height: 100vh;
}
.login-page {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 30px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.camera {
  width: 100%;
  height: 75vh;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.match-percent {
  width: 90px;
  height: 90px;
  border: 4px solid red;
  border-radius: 50%;
  color: red;
  margin-bottom: 10px;
}
.quality {
  width: 40px;
  height: 40px;
  border: 4px solid green;
  border-radius: 50%;
  color: green;
  margin-top: 10px;
  font-size: x-small;
}
.display-image {
  height: 40vh;
  background-color: aqua;
  width: 100%;
}

.quality-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.btn.btn-primary {
  width: 100%;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  margin-top: 1.5rem;
}

.av-test {
  width: 100%;
  background-color: white;
  height: 10vh;
  align-items: center;
  padding-inline: 1rem;
  border-bottom: 2px solid #e6e6e6;
}
.av-div1 {
  width: 100%;
}
.av-test-btn {
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
}

.i-h {
  color: grey;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }
  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 0px;
    justify-content: center;
  }
  .camera {
    height: 65vh;
    border-radius: 0px;
  }
  .login-button {
    padding-inline: 1rem;
  }

  .p-heading {
    padding-inline: 1rem;
  }

  div.scroll-container div img {
    width: 300px;
    height: 225px;
  }
}
</style>
