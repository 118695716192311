import { createApp } from "vue";
import App from "./App.vue";
import logIn from "./components/logIn.vue";
import vcipIn from "./components/vcipIn.vue";
import acceptanceCheck from "./components/acceptanceCheck.vue";
import identityCheck from "./components/identityCheck.vue";
import riskMappingCheckDone from "./components/riskMappingCheckDone.vue";
import basicDetails from "./components/basicDetails.vue";
import additionalDetails from "./components/additionalDetails.vue";
import selectSlot from "./components/selectSlot.vue";
import vcipDetails from "./components/vcipDetails.vue";
import liveAcceptanceCheck from "./components/liveAcceptanceCheck.vue";
import livePhotographQuality from "./components/livePhotographQuality.vue";
import termAndCon from "./components/termAndCon.vue";
import dashboardScreen from "./components/dashboardScreen.vue";
import faceMatchCheck from "./components/faceMatchCheck.vue";
import reuploadPanCard from "./components/reuploadPanCard.vue";
import ovdMasking from "./components/ovdMasking.vue";
import auditCheck from "./components/auditCheck.vue";
import sendingFile from "./components/sendingFile.vue";
import acceptanceCheckDone from "./components/acceptanceCheckDone.vue";
import liveIdentityCheck from "./components/liveIdentityCheck.vue";
import customerSupport from "./components/customerSupport.vue";
import thresholdDetails from "./components/thresholdDetails.vue";
import identityCheckDone from "./components/identityCheckDone.vue";
import riskMappingCheck from "./components/riskMappingCheck.vue";
import liveCapturedOvdQuality from "./components/liveCapturedOvdQuality.vue";
import vkyc from "./components/vkyc.vue";
import Chatbox from "./components/chatBox.vue";
import loaderUse from "../src/utility/loaderUse.vue";
import auditorDashboard from "./components/auditorDashboard.vue";
import dashboardTable from "./components/dashboardTable.vue";
import liveCasesScreen from "./components/liveCasesScreen.vue";
import analyticsScreen from "./components/analyticsScreen.vue";
import livenessCheck from "./components/livenessCheck";
import { createRouter, createWebHistory } from "vue-router";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
// import Keycloak from "keycloak-js";
// let initOptions = {
//   url: "https://sso.algofolks.com:8443/",
//   realm: "SSO",
//   clientId: "SSO",
//   onLoad: "login-required",
// };
// let keycloak = new Keycloak(initOptions);
// let queryString = window.location.search;
// let params = new URLSearchParams(queryString);
// let type = params.get("type");
// if (type && type == "user") {
//   window.localStorage.setItem("keycloaktoken", "user");
// }
// if (!type && !localStorage.getItem("keycloaktoken")) {
//   let data = await keycloak.init({
//     onLoad: initOptions.onLoad,
//     checkLoginIframe: false,
//   });
//   if (!data) window.location.reload();

//   window.localStorage.setItem("keycloaktoken", keycloak?.token);
//   window.localStorage.setItem(
//     "username",
//     keycloak?.tokenParsed?.preferred_username
//   );
// }

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "",
      name: "Login",
      component: logIn,
    },
    {
      path: "/vkyc",
      name: "Vkyc",
      component: vkyc,
    },
    {
      path: "/vcipIn",
      component: vcipIn,
    },
    {
      path: "/acceptanceCheck",
      component: acceptanceCheck,
    },
    {
      path: "/identityCheck",
      component: identityCheck,
    },
    {
      path: "/riskMappingCheckDone",
      component: riskMappingCheckDone,
    },
    {
      path: "/riskMappingCheck",
      component: riskMappingCheck,
    },
    {
      path: "/basicDetails",
      component: basicDetails,
    },
    {
      path: "/vcipDetails",
      component: vcipDetails,
    },
    {
      path: "/additionalDetails",
      component: additionalDetails,
    },
    {
      path: "/selectSlot",
      component: selectSlot,
    },
    {
      path: "/termAndCon",
      component: termAndCon,
    },
    {
      path: "/dashboardScreen",
      component: dashboardScreen,
    },
    {
      path: "/liveAcceptanceCheck",
      component: liveAcceptanceCheck,
    },
    {
      path: "/livePhotographQuality",
      component: livePhotographQuality,
    },
    {
      path: "/faceMatchCheck",
      component: faceMatchCheck,
    },
    {
      path: "/reuploadPanCard",
      component: reuploadPanCard,
    },
    {
      path: "/ovdMasking",
      component: ovdMasking,
    },
    {
      path: "/auditCheck",
      component: auditCheck,
    },
    {
      path: "/sendingFile",
      component: sendingFile,
    },
    {
      path: "/acceptanceCheckDone",
      component: acceptanceCheckDone,
    },
    {
      path: "/agentDashboard",
      component: customerSupport,
    },
    {
      path: "/liveIdentityCheck",
      component: liveIdentityCheck,
    },
    {
      path: "/thresholdDetails",
      component: thresholdDetails,
    },
    {
      path: "/identityCheckDone",
      component: identityCheckDone,
    },
    {
      path: "/liveCapturedOvdQuality",
      component: liveCapturedOvdQuality,
    },
    {
      path: "/auditorDashboard",
      component: auditorDashboard,
    },
    {
      path: "/chatbox",
      component: Chatbox,
    },
    {
      path: "/dashboardTable",
      component: dashboardTable,
    },
    {
      path: "/liveCasesScreen",
      component: liveCasesScreen,
    },
    {
      path: "/analyticsScreen",
      component: analyticsScreen,
    },
    {
      path: "/livenessCheck",
      component: livenessCheck,
    },
    {
      path: "/identityCheckDone/:panQuality/:liveliness/:livePhotoQuality/:livePanQuality/:capturedPanQuality/:randomQues",
      name: "IdentityCheckDone",
      component: identityCheckDone,
    },
  ],
});

router.beforeEach((to, from, next) => {
  // let keycloakToken = localStorage.getItem("keycloaktoken");
  let username = localStorage.getItem("username");
  console.log("username-username", username);
  if (username != "auditor" && to.path == "/auditorDashboard") {
    next({ path: from.path });
  } else {
    // if (keycloakToken) {
    //   if (to.path == "/") return next({ path: "/basicDetails" });

    //   next();
    // }

    next();
  }
});

const app = createApp(App);
//app.config.globalProperties.$keycloak = keycloak;
app.component("loaderUse", loaderUse);
app.use(router);

app.use(VueToast);

app.mount("#app");
