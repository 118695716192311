<template>
  <div>
    <div>
      <iframe
        ref="iframe"
        title="React Component"
        width="740px"
        height="300px"
        :src="reactAppUrl"
        frameborder="0"
        allow="camera; microphone"
      ></iframe>
    </div>
    <div style="text-align:center">
      <span> Your Liveness Score is : {{ Confidence }} </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reactAppUrl: "https://amplify-liveness.algofolks.com/",
      Confidence: 0,
      openIframeUrl: true,
    };
  },
  created() {
    this.openIframeUrl = true;
  },
  mounted() {
    window.addEventListener("message", (event) => {
      if (
        event.origin === "https://amplify-liveness.algofolks.com/" ||
        event.origin === "http://localhost:3001"
      ) {
        console.log("Received message:", event.data);
        if (event.data && event.data.status === "success") {
          this.Confidence = event.data.data.Confidence || 0;
        } else {
          console.error("Unexpected response structure:", event.data);
        }
        this.openIframeUrl = false;
      }
    });
  },
  methods: {},
};
</script>

<style>
</style>
