<template>
  <div>
    <TopNav />
    <section class="form-section">
      <div class="justify-nav">
        <div class="col-md-3 d-flex flex-column flex-shrink-0 p-3 text-white bg-white bg-color" style="width: 280px; border-radius: 10px;">
          <ul class="nav nav-pills flex-column mb-auto">
            <li class="mr-li">
              <a href="#about" @click="dashboard">
                <i class="ti ti-home me-3 "></i>
                Dashboard
              </a>
            </li>
            <li class="mr-li">
              <a href="#" @click="live">
                <i class="fa-solid fa-chart-area me-3 "></i>
                Live Cases
              </a>
            </li>
            <li class="mr-li">
              <a href="#" @click="analytics">
                <i class="fa-solid fa-arrow-trend-up me-3 "></i>
                Analytics
              </a>
            </li>
            <li class="mr-li">
              <a href="#" @click="setting">
                <i class="ti ti-settings me-3 "></i>
                Setting
              </a>
            </li>
          </ul>
          <hr>
        </div>
        <div class="col-md-9 p-0">
          <div class="open-screen">
            <div v-if="dashboards">
              <dashboard-table></dashboard-table>
            </div>
            <div v-if="lives"><live-cases-screen></live-cases-screen></div>
            <div v-if="analyticss"><analytics-screen></analytics-screen></div>
            <div v-if="settings" class="text-center">
              <My-component></My-component>
            </div>
          </div>
        </div>
      </div>

    </section>

  </div>
</template>

<script>
import DashboardTable from "./dashboardTable.vue";
import LiveCasesScreen from "./liveCasesScreen.vue";
import thresholdDetails from "./thresholdDetails.vue";
import AnalyticsScreen from './analyticsScreen.vue';
import TopNav from '@/components/topNav.vue';

export default {
  components: {
    "My-component": thresholdDetails,
    DashboardTable,
    LiveCasesScreen,
    AnalyticsScreen,
    TopNav,
  },
  name: "dashboardScreen",
  data() {
    return {
      dashboards: true,
      lives: false,
      analyticss: false,
      settings: false,
      popUp: false,

    };
  },
  methods: {

    dashboard() {
      this.dashboards = true;
      this.lives = false;
      this.analyticss = false;
      this.settings = false;
    },
    setting() {
      this.settings = true;
      this.dashboards = false;
      this.lives = false;
      this.analyticss = false;
    },
    analytics() {
      this.analyticss = true;
      this.dashboards = false;
      this.lives = false;

      this.settings = false;
    },
    live() {
      this.lives = true;
      this.dashboards = false;

      this.analyticss = false;
      this.settings = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-section {
  position: relative;
  /* padding: 25px 20px; */
  background-color: #e8e7e7ef;
  /* min-height: 100vh; */
}

.login-page {
  background-color: #fff;
  overflow-y: auto;
  scrollbar-width: none;
  position: fixed;
  z-index: 90;
  height: calc(100vh - 80px);
  top: 80px;
  min-height: calc(100vh - 80px);
  box-shadow: 0 10px 20px 1px rgba(0, 0, 0, 0.06),
    0 1px 5px rgba(11, 11, 11, 0.08);
}

a {
  text-decoration: none;
  color: #05070b;
}

.btn.btn-primary {
  width: 100%;
  background-color: #0053f9;
  border: 2px solid #0053f9;
  margin-top: 1.5rem;
}


.av-test-btn {
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 1rem;
  }

  .btn.btn-primary {
    width: 100%;
    /* */
    margin: 1.5rem 0;
  }

  .camera {
    height: 30vh;
    border-radius: 10px;
    margin-bottom: 2rem;
  }

  .login-header {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: larger;
  }
}

.mr-li {
  margin: 0.125rem 0;
  padding: 1rem !important;
  display: flex;
  align-items: center;
}
.justify-nav {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  padding-bottom: 10rem;
}
/* . {
  color: #7367f0;
} */
</style>
