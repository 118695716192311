<template>
  <section class="form-section">
    <div class="login-page">
      <div class="toster" v-if="popUp">
        <div class="d-flex justify-content-between p-2 px-3 first">
          <div>Success</div>
          <div @click="closePopUp" class="pop-btn">x</div>
        </div>

        <div class="px-3 second">data update successfully</div>
      </div>

      <div class="ms-2 mb-2">
        <div class="row gap-5">
          <div class="col-5 btn" style="border: 1px solid">
            <div @click="openSec = 1" class="">Settings</div>
          </div>

          <div class="col-5 btn" style="border: 1px solid">
            <div @click="openSec = 2">Questions</div>
          </div>
        </div>
      </div>

      <div v-if="this.editModalVisible" class="modal">
        <div class="modal-content">
          <div><span class="close align-self-end" @click="closeEditModal"
            >&times;</span
          >
          <h2 v-if="addData">Add Item</h2>
          <h2 v-else>Edit Item</h2></div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                  <!-- <label for="exampleFormControlInput1" class=" align-self-start">Questions</label> -->
                  <input
                    type="text"
                    placeholder="Questions"
                    class="form-control"
                    id="exampleFormControlInput1"
                    v-model="editedItem.question"
                  />
                </div>
              <!-- <input
                class="w-100"
                v-model="editedItem.question"
                type="text"
                placeholder="Your Question"
              /> -->
            </div>
          </div>

          <div class="row my-2">
            <div class="col-12">
              <div class="form-group">
                  <!-- <label for="exampleFormControlTextarea1"
                    >Example textarea</label
                  > -->
                  <input
                    class="form-control"
                    placeholder="Write your description here"
                    id="exampleFormControlTextarea1"
                   
                    v-model="editedItem.description"
                  />
                </div>
              <!-- <input
                class="w-100"
                v-model="editedItem.description"
                type="text"
                placeholder="Your Description"
              /> -->
            </div>
          </div>
          <div class="row my-2">
            <div class="col-12">
              <button @click="saveItem" class="align-self-end login-form-button rounded border-0 px-3 py-2">Save</button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="openSec == 1">
        <header class="">
          <div class="vcip-heading text-start">Threshold setting</div>

          <div class="login-box">
            <div class="vcip-options-div">
              <div class="row justify-content-evenly">
                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">Background</div>
                  <div class="vcip-options-items good">
                    <div>{{ backgroundThreshold }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="backgroundThreshold"
                      @change="updateThreshold('backgroundThreshold')"
                    />
                  </div>
                </div>
                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">Light</div>
                  <div class="vcip-options-items good">
                    <div>{{ lightThreshold }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="lightThreshold"
                      @change="updateThreshold('lightThreshold')"
                    />
                  </div>
                </div>
                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">Voice</div>
                  <div class="vcip-options-items good">
                    <div>{{ voiceThreshold }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="voiceThreshold"
                      @change="updateThreshold('voiceThreshold')"
                    />
                  </div>
                </div>

                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">Lat Long</div>
                  <div class="vcip-options-items good">
                    <div>{{ latLongThreshold }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="latLongThreshold"
                      @change="updateThreshold('latLongThreshold')"
                    />
                  </div>
                </div>

                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">Pan Quality</div>
                  <div class="vcip-options-items good">
                    <div>{{ uploadedPanQuality }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="uploadedPanQuality"
                      @change="updateThreshold('uploadedPanQuality')"
                    />
                  </div>
                </div>
                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">Customer Liveliness</div>
                  <div class="vcip-options-items good">
                    <div>{{ customerLiveliness }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="customerLiveliness"
                      @change="updateThreshold('customerLiveliness')"
                    />
                  </div>
                </div>

                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">Live PhotographQuality</div>
                  <div class="vcip-options-items good">
                    <div>{{ livePhotographQuality }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="livePhotographQuality"
                      @change="updateThreshold('livePhotographQuality')"
                    />
                  </div>
                </div>

                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">Live Captured PanQuality</div>
                  <div class="vcip-options-items good">
                    <div>{{ liveCapturedPanQuality }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="liveCapturedPanQuality"
                      @change="updateThreshold('liveCapturedPanQuality')"
                    />
                  </div>
                </div>

                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">Face Match</div>
                  <div class="vcip-options-items good">
                    <div>{{ faceMatch }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="faceMatch"
                      @change="updateThreshold('faceMatch')"
                    />
                  </div>
                </div>

                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">Random Questionnaire</div>
                  <div class="vcip-options-items good">
                    <div>{{ randomQuestionnaire }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="randomQuestionnaire"
                      @change="updateThreshold('randomQuestionnaire')"
                    />
                  </div>
                </div>

                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">Ovds Ocr Correctness</div>
                  <div class="vcip-options-items good">
                    <div>{{ ovdsOcrCorrectness }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="ovdsOcrCorrectness"
                      @change="updateThreshold('ovdsOcrCorrectness')"
                    />
                  </div>
                </div>

                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">Ovds Masking</div>
                  <div class="vcip-options-items good">
                    <div>{{ ovdsMasking }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="ovdsMasking"
                      @change="updateThreshold('ovdsMasking')"
                    />
                  </div>
                </div>

                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">xml ValidityCheck</div>
                  <div class="vcip-options-items good">
                    <div>{{ xmlValidityCheck }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="xmlValidityCheck"
                      @change="updateThreshold('xmlValidityCheck')"
                    />
                  </div>
                </div>

                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">Pan Verification</div>
                  <div class="vcip-options-items good">
                    <div>{{ panVerification }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="panVerification"
                      @change="updateThreshold('panVerification')"
                    />
                  </div>
                </div>

                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items">Aadhaar Quality Check</div>
                  <div class="vcip-options-items good">
                    <div>{{ aadhaarQuality }}</div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      v-model="aadhaarQuality"
                      @change="updateThreshold('aadhaarQuality')"
                    />
                  </div>
                </div>
                <div class="vcip-options pointer col-5">
                  <div class="vcip-options-items d-none"></div>
                  <div class="vcip-options-items good d-none">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div
          class="login-button align-self-start pl23"
          style="padding-left: 43px; padding-top: 9px"
        >
          <button
            class="login-form-button btn"
            :disabled="agree"
            @click="submitSettings"
          >
            Update
          </button>
        </div>
      </div>

      <div v-if="openSec == 2">
        <header class="">
          <div class="for-question text-start">
            <div class="d-flex justify-content-between">
              Questions
              <button @click="openAddModal()"  class="login-form-button rounded border-0">
                <i class="fas fa-add"></i>
                Add Data
              </button>
            </div>
          </div>

          <div class="login-box">
            <div class="vcip-options-div">
              <table id="customers">
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Descriptions</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in data" :key="index">
                    <td>{{ item.question }}</td>
                    <td>{{ item.description }}</td>
                    <td>
                      <button @click="editItem(index)">
                        <i class="fas fa-edit"></i>
                        <!-- Edit icon -->
                      </button>
                      <button @click="deleteItem(index)" class="ml-2">
                        <i class="fas fa-trash"></i>
                        <!-- Delete icon -->
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </header>
      </div>

      <div class="loader-container" v-if="loader">
        <div class="loader"></div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "thresholdDetails",
  data() {
    return {
      openSec: 2,
      popUp: false,
      backgroundThreshold: 0,
      aadhaarQuality: 0,
      lightThreshold: 0,
      voiceThreshold: 0,
      latLongThreshold: 0,
      uploadedPanQuality: 0,
      customerLiveliness: 0,
      livePhotographQuality: 0,
      liveCapturedPanQuality: 0,
      faceMatch: 0,
      randomQuestionnaire: 0,
      ovdsOcrCorrectness: 0,
      ovdsMasking: 0,
      xmlValidityCheck: 0,
      panVerification: 0,
      agree: false,
      loader: false,
      data: [
        // { id: 1, question: "First body", description: "First description" },
        // { id: 2, question: "Second body", description: "Second description" },
        // { id: 3, question: "Third body", description: "Third description" },
      ],
      editModalVisible: false,
      editedItem: {
        question: "",
        description: "",
      },
      editedItemIndex: null,
      addData: false,
      editData: false,
      myDataArray:[],
    };
  },

  created() {
    // const jsonData = JSON.stringify(data);

    const jsonData = localStorage.getItem("randomQue");

    // Check if data exists in localStorage
    if (jsonData) {
      // Parse JSON data into JavaScript object
      const parsedData = JSON.parse(jsonData);

      // Store parsed data into Vue data array
      this.data = parsedData;
    }
    this.getData();
  },

  methods: {
    openAddModal() {
      this.addData = true;
      this.editedItem = { question: "", description: "" };
      this.editModalVisible = true;
      console.log("editModalVisible", this.editModalVisible);
    },
    editItem(index) {
      this.editData = true;
      this.editedItem = { ...this.data[index] };
      this.editedItemIndex = index;
      this.editModalVisible = true;
    },
    closeEditModal() {
      this.editModalVisible = false;
      this.addData = false;
      this.editData = false;
    },
    saveItem() {
      if (this.addData) {
        this.addData = false;
        this.data.push({ ...this.editedItem });
      }
      if (this.editData) {
        this.editData = false;
        this.data.splice(this.editedItemIndex, 1, this.editedItem);
        // this.data[this.editedItemIndex] = { ...this.editedItem };
      }

      this.editModalVisible = false;

      localStorage.setItem("randomQue", JSON.stringify( this.data));
    },
    deleteItem(index) {
      this.data.splice(index, 1);

      localStorage.setItem("randomQue", JSON.stringify( this.data));
    },
    closePopUp() {
      this.popUp = false;
    },
    updateThreshold(field) {
      console.log(`Updated ${field} to ${this[field]}`);
    },
    async submitSettings() {
      const settingUpDto = {
        backgroundThreshold: this.backgroundThreshold,
        aadhaarQuality: this.aadhaarQuality,
        lightThreshold: this.lightThreshold,
        voiceThreshold: this.voiceThreshold,
        latLongThreshold: this.latLongThreshold,
        uploadedPanQuality: this.uploadedPanQuality,
        customerLiveliness: this.customerLiveliness,
        livePhotographQuality: this.livePhotographQuality,
        liveCapturedPanQuality: this.liveCapturedPanQuality,
        faceMatch: this.faceMatch,
        randomQuestionnaire: this.randomQuestionnaire,
        ovdsOcrCorrectness: this.ovdsOcrCorrectness,
        ovdsMasking: this.ovdsMasking,
        xmlValidityCheck: this.xmlValidityCheck,
        panVerification: this.panVerification,
      };

      try {
        this.loader = true;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(
          `${apiUrl}/setting/createSetting`,
          settingUpDto
        );
        console.log(response.data.message);
        this.loader = false;
        setTimeout(() => {
          this.popUp = false;
        }, 2000);
        this.popUp = true;
      } catch (error) {
        console.error("Error during API call:", error);
      }
    },

    async getData() {
      try {
        this.loader = true;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.get(`${apiUrl}/setting/getSetting`);
        const data = response.data.settings;
        console.log("API Response:", data);
        if (data && data.length > 0) {
          const setting = data[0];
          this.backgroundThreshold = setting.backgroundThreshold;
          this.aadhaarQuality = setting.aadhaarQuality;
          this.lightThreshold = setting.lightThreshold;
          this.voiceThreshold = setting.voiceThreshold;
          this.latLongThreshold = setting.latLongThreshold;
          this.uploadedPanQuality = setting.uploadedPanQuality;
          this.customerLiveliness = setting.customerLiveliness;
          this.livePhotographQuality = setting.livePhotographQuality;
          this.liveCapturedPanQuality = setting.liveCapturedPanQuality;
          this.faceMatch = setting.faceMatch;
          this.randomQuestionnaire = setting.randomQuestionnaire;
          this.ovdsOcrCorrectness = setting.ovdsOcrCorrectness;
          this.ovdsMasking = setting.ovdsMasking;
          this.xmlValidityCheck = setting.xmlValidityCheck;
          this.panVerification = setting.panVerification;
        } else {
          console.error("No settings found in the response.");
        }
        this.loader = false;
      } catch (error) {
        console.error("API Error:", error);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-content {
  position: relative;
  z-index: 3000;

  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 40%;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.pop-btn {
  cursor: pointer;
}
.toster {
  right: 10px;
  top: 10px;
  position: fixed;
  z-index: 110;
  height: 80px;
  width: 350px;
  background-color: rgb(168, 248, 159);

  border-radius: 10px;
  float: right;
}
.first {
  background-color: rgb(109, 226, 101);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: rgb(0, 96, 0);
  font-weight: bold;
}
.second {
  color: #155724;
  margin-top: 6px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.right-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  margin-right: 10px;
}
.form-section {
  position: relative;
  padding: 20px 30px;
  background-color: #073991;
  min-height: calc(100vh-80px);
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 18;
  font-size: 20px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
  z-index: 999; /* Ensure the overlay is on top */
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

.recheck {
  color: #f7b519;
}
.good {
  color: #009688;
}
.bg-recheck {
  background-color: #f7b519;
}
.bg-good {
  background-color: #009688;
}

.pointer:hover {
  cursor: pointer;
}
.login-page {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 30px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
header.sticky-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #f4f4f4;
  height: 18vh;
}

main {
  overflow-y: auto;
}
.for-question {
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  /* margin-left: 42px; */
}
.vcip-heading {
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 42px;
}
.acceptane-options-line {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
  margin-bottom: 10px;
}
.acceptance-options {
  color: gray;
  font-weight: bold;
  justify-content: space-between;
  margin: 0px 10px;
  margin-left: 14px;
  margin-top: -20px;
  padding-top: 10px;
  background-image: linear-gradient(to right, #6a68db 50%, gray 50%);
  background-size: 100% 2px;
  background-repeat: no-repeat;
}
.acceptance-items {
  display: flex;
  align-items: center;
}
.circle {
  width: 10px;
  height: 10px;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.active-circle {
  background-color: #6a68db;
}
.active-check {
  width: 18px;
  height: 18px;
  color: white;
}
.active-btn {
  color: #6a68db;
}
.inner-circle {
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 50%;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #fcfcfc;
  color: black;
}

.vcip-options {
  margin: 7px 0px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  background-color: #fcfcfc;
  padding: 11px;
  border-radius: 4px;
}
.vcip-options-items {
  display: flex;
  align-items: center;
}
.login-form-button {
  background-color: #ee8d33;
  /* border: 2px solid #eb780d; */
  color: white;
  text-align: center;
  font-size: large;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }
  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
  }
  vcip-options {
    padding: 15px 20px;
  }
  .vcip-options-items {
    font-size: small;
  }

  .vcip-options[data-v-053b37e7] {
    margin: 7px 0px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    background-color: #fcfcfc;
    padding: 7px;
    border-radius: 4px;
    /* border-bottom: 2px solid #d4d4d4; */
    /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); */
  }
  .pl23 {
    padding-left: 42px !important;
    padding-top: 8px !important;
  }
}
</style>
