<template>
  <section class="form-section">
    <div class="form-box">
      <div class="login-page">
          <div class="login-box">
            <div class="vcip-heading d-flex">Risk mapping check</div>
            <div class="acceptane-options-line">
              <div class="acceptance-items">
                <div class="circle active-circle active-check"><div>✔</div></div>
              </div>
              <div class="acceptance-items">
                <div class="circle active-circle active-check"><div>✔</div></div>
              </div>
              <div class="acceptance-items">
                <div class="circle"><div class="inner-circle"></div></div>
              </div>
            </div>
            <div class="acceptance-options d-flex">
              <div class="active-btn">1</div>
              <div>2</div>
              <div>3</div>
            </div>
            <div class="vcip-options-div">
              <div class="vcip-options">
                <div class="vcip-options-items">
                  <span class="right-icon bg-good"
                    ><span class="material-symbols-outlined"> done </span></span
                  >
                  OVDs OCR correctness
                </div>
                <div class="vcip-options-items good">
                  Good &nbsp;&nbsp;
                  <span class="material-symbols-outlined"> chevron_right </span>
                </div>
              </div>
              <div class="vcip-options" @click="navigateToOvdMasking">
                <div class="vcip-options-items">
                  <span class="right-icon bg-good"
                    ><span class="material-symbols-outlined"> done </span></span
                  >
                  OVDs masking
                </div>
                <div class="vcip-options-items good">
                  Good &nbsp;&nbsp;
                  <span class="material-symbols-outlined"> chevron_right </span>
                </div>
              </div>
              <div class="vcip-options">
                <div class="vcip-options-items">
                  <span class="right-icon bg-good"
                    ><span class="material-symbols-outlined"> done </span></span
                  >
                  XML validity check
                </div>
                <div class="vcip-options-items good">
                  Good &nbsp;&nbsp;
                  <span class="material-symbols-outlined"> chevron_right </span>
                </div>
              </div>
              <div class="vcip-options">
                <div class="vcip-options-items">
                  <span class="right-icon bg-good"
                    ><span class="material-symbols-outlined"> done </span></span
                  >
                  PAN verification
                </div>
                <div class="vcip-options-items good">
                  Good &nbsp;&nbsp;
                  <span class="material-symbols-outlined"> chevron_right </span>
                </div>
              </div>
            </div>
          </div>
          <div class="login-button">
            <button class="login-form-button btn" @click="navigateToAuditCheck">
              Next
            </button>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "riskMappingCheckDone",
  mounted() {
    document.title = 'Risk Mapping';
   },
  methods: {
    navigateToOvdMasking() {
      this.$router.push("/ovdMasking");
    },
    navigateToAuditCheck() {
      this.$router.push("/auditCheck");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.right-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  margin-right: 10px;
}
.form-section {
  background-color: #073991;
  min-height: 100vh;
}

.form-box{
  display: flex;
  align-items: center;
  justify-content: center;
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 18;
  font-size: 20px;
}

.recheck {
  color: #f7b519;
}
.good {
  color: #009688;
}
.bg-recheck {
  background-color: #f7b519;
}
.bg-good {
  background-color: #009688;
}

.login-page {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width:750px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top:30px;
}


.vcip-heading {
  font-size: x-large;
  font-weight: bold;
  text-align: start;
  margin-bottom: 20px;
}

.circle {
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: grey;
}

.active-circle {
  background-color: #6a68db;
}

.active-check {
  width: 18px;
  height: 18px;
  color: white;
}

.active-btn {
  color: #6a68db;
}
.inner-circle {
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 50%;
}
.acceptane-options-line {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
}
.acceptance-options {
  color: gray;
  font-weight: bold;
  justify-content: space-between;
  margin: 0px 10px;
  border-top: 2px solid #6a68db;
  margin-left: 14px;
  margin-top: -10px;
  padding-top: 10px;
}
.acceptance-items {
  display: flex;
  align-items: center;
}
.vcip-options {
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fcfcfc;
  padding: 15px;
  border-radius: 8px;
  border-bottom: 2px solid #d4d4d4;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Box shadow */
}
.vcip-options-items {
  display: flex;
  align-items: center;
}

.login-form-button {
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
  text-align: center;
  font-size: large;
  width: 100%;
  margin-top:40px;
}
@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }
  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    min-width: 100vw;
    margin-top:0px;
  }
  .vcip-options {
    padding: 15px 20px;
  }
  .vcip-options-items {
    font-size: small;
  }
}
@media all and (orientation:landscape) and (min-width: 540px) and (max-width: 1024px) {
  .form-section{
    width: 100%;
    padding: 0px 0px;
  }
  .login-form-box {
    border: none;
    border-radius: 0px;
  }
  .vcip-heading{
    font-size: 18px;
    font-weight: 600;
    text-align: start;
    margin-bottom: 5px;
  }
  .acceptance-options{
    padding-top: 13px;
  }
}
</style>
