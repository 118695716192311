import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyBlNIf6Org9JFFd_Htpu9U0ERX59KCzs8A',
  authDomain: 'chatbox-b8c53.firebaseapp.com',
  databaseURL: 'https://chatbox-b8c53-default-rtdb.firebaseio.com/',
  projectId: 'chatbox-b8c53',
  storageBucket: 'chatbox-b8c53.appspot.com',
  messagingSenderId: '499684339939',
  appId: '1:499684339939:web:50817421295fb3078fd5d6',
  measurementId: "G-SXM02Y3HX9"
};

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

export { database };