<template>
  <div>
    <TopNav />

    <section class="form-section ">
      <div class="row d-flex justify-nav">
        <Sidenav />
        <div class="col-9 p-0 form-heading">
          <div class="container shadow">
            <div class="card-title mb-0 me-1">
              <h5 class="mb-0 text-lorem">Required Documents</h5>
              <p class="mb-0 text-size">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
            <div class="row card-title d-flex mb-0 me-1">
              <div class="col-md-6">
                <h5 class="mb-0 text-lorem">Aadhar Upload</h5>
              </div>
              <div class="col-md-6">
                <h5 class="mb-0 text-lorem">FAQ</h5>
              </div>
            </div>
            <div class="row d-flex flex-row">
              <div class="col-md-6">
                <div class="col-sm-12">
                  <div class="padding-content">
                    <div class="col-12 mb-3">
                      <label for="aadharImageFront" class="form-label text-size styleFont">
                        Upload Front Side of Aadhaar Card
                      </label>
                      <input type="file" class="form-control styleFont text-muted widthLess" id="aadharImageFront"
                        ref="aadhaarFront" placeholder="aadhaar card" @change="onAdharCardChange('front')" />
                      <span class="error-message" v-if="invalidAadhaar">Invalid aadhar card</span>
                      <div class="mt-2">
                        <p class="error-message" v-if="errorForName">
                          {{ errorForName }}
                        </p>
                      </div>
                      <div>
                        <div class="d-flex align-items-center">
                          <div class="image-container me-5">
                            <img
                              v-if="this.numericQualityRatio >= this.getAadharQuality && previewFront && !wrongAadhar"
                              :src="previewFront" alt="Aadhaar Front Preview" @click="showImageInModal(previewFront)"
                              class="img-thumbnail"
                              style="cursor: pointer; width: 100px; height: 100px; object-fit: cover" />
                          </div>
                          <div
                            v-if="this.numericQualityRatio >= this.getAadharQuality && isFrontUploaded && !wrongAadhar"
                            class="ml-3" style="font-size: 13px; width: 70%;">
                            <div class="row mb-1">
                              <div class="col-6" style="color: #7367f0; font-weight: 600;">
                                Name:
                              </div>
                              <div class="col-6" style="color: black; font-weight: 500;">
                                {{ aadharNameFront }}
                              </div>
                            </div>
                            <div class="row mb-1">
                              <div class="col-6" style="color: #7367f0; font-weight: 600;">
                                DOB:
                              </div>
                              <div class="col-6" style="color: black; font-weight: 500;">
                                {{ aadharDobFront['Date of Birth:'] }} </div>
                            </div>
                            <div class="row mb-1">
                              <div class="col-6" style="color: #7367f0; font-weight: 600;">
                                No.:
                              </div>
                              <div class="col-6" style="color: black; font-weight: 500;">
                                {{ aadharNoFront }}
                              </div>
                            </div>
                            <div class="row mb-1">
                              <div class="col-6" style="color: #7367f0; font-weight: 600;">
                                Image Quality Ratio:
                              </div>
                              <div class="col-6" style="color: black; font-weight: 500;">
                                {{ this.numericQualityRatio }}%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="loader-container" v-if="loader">
                        <div class="loader"></div>
                      </div>
                    </div>

                    <div class="col-12 mb-3">
                      <label for="aadharImageBack" class="form-label text-size styleFont">
                        Upload Back Side of Aadhaar Card
                      </label>
                      <input type="file" class="form-control styleFont text-muted widthLess" id="aadharImageBack"
                        ref="aadhaarBack" placeholder="aadhaar card" @change="onAdharCardChange('back')" />
                      <span class="error-message" v-if="invalidAadhaar">Invalid aadhar card</span>
                      <div class="mt-2">
                        <p class="error-message" v-if="errorForName">
                          {{ errorForName }}
                        </p>
                      </div>
                      <div>
                        <img
                          v-if="this.numericBackImageQualityRatio >= this.getAadharQuality && previewBack && !wrongAadhar"
                          :src="previewBack" alt="Aadhaar Back Preview" @click="showImageInModal(previewBack)"
                          style="cursor: pointer; width: 100px;" />
                      </div>
                      <div class="loader-container" v-if="loader">
                        <div class="loader"></div>
                      </div>
                    </div>

                    <!-- Image Modal -->
                    <div v-if="isModalVisible" class="modal" @click="closeModal">
                      <div class="modal-content" @click.stop>
                        <span class="close" @click="closeModal">&times;</span>
                        <img :src="modalImage" class="full-image" />
                      </div>
                    </div>

                  </div>
                  <div class="card-title mb-0 me-1">
                    <h5 class="mb-0 text-lorem">PAN Upload</h5>
                  </div>
                  <div class="mb-3 padding-content">
                    <label for="panCard" class="form-label text-size styleFont">Upload PAN Card</label>
                    <input type="file" @change="onPanCardChange" ref="pan"
                      class="form-control widthLess styleFont text-muted" id="panCard" placeholder="pan card" />
                    <span class="error-message" v-if="invalidPan">Invalid pan card</span>
                  </div>
                  <p class="error-message" v-if="errorMessage">
                    {{ errorMessage }}
                  </p>
                  <div class="d-flex align-items-center">
                    <div class="image-container me-5">
                      <img v-if="this.numericPanQualityRatio >= this.uploadedPanQuality" :src="previewPan"
                        alt="Aadhaar Back Preview" @click="showImageInModal(previewPan)"
                        style="cursor: pointer; width: 100px;" />
                    </div>
                    <div v-if="this.numericPanQualityRatio >= this.uploadedPanQuality" class="ml-3"
                      style="font-size: 13px; width: 70%;">
                      <div class="row mb-1">
                        <div class="col-6" style="color: #7367f0; font-weight: 600;">
                          Name:
                        </div>
                        <div class="col-6" style="color: black; font-weight: 500;">
                          {{ panName }}
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-6" style="color: #7367f0; font-weight: 600;">
                          DOB:
                        </div>
                        <div class="col-6" style="color: black; font-weight: 500;">
                          {{ panDob['Date of Birth:'] }} </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-6" style="color: #7367f0; font-weight: 600;">
                          No.:
                        </div>
                        <div class="col-6" style="color: black; font-weight: 500;">
                          {{ panCardNumber }}
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-6" style="color: #7367f0; font-weight: 600;">
                          Image Quality Ratio:
                        </div>
                        <div class="col-6" style="color: black; font-weight: 500;">
                          {{ this.numericPanQualityRatio }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="isPanModalVisible" class="modal" @click="closeModal">
                  <div class="modal-content" @click.stop>
                    <span class="close" @click="closeModal">&times;</span>
                    <img :src="modalImage" class="full-image" />
                  </div>
                </div>

                <div class="col-sm-12 d-flex flex-row pt-2 justify-content-between">
                  <div class="mb-3 w-40 ">
                    <div class="login-button">
                      <button type="submit" @click="goBack()" class="btn btn-primary" style="
    font-size: 13px;
" :disabled="agree">
                        Back
                      </button>
                    </div>
                  </div>
                  <div class="mb-3 w-40 ">
                    <div class="login-button">
                      <button type="submit" @click="updateCaseDetails()" class="btn btn-primary" style="
    font-size: 13px;
" :disabled="agree">
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="">
                  <div id="app" class="col-sm-12 fontSize12px px-0 ">
                    <div class="card-text mb-0 me-1">
                      <p class="mb-0 text-size">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                    </div>
                    <ul class="list-group d-flex flex-column gap-1">
                      <div class="list-group-item cursor-pointer flex-row justify-content-between textAcco"
                        @click="toggle('aadhar')">
                        <div class="display-margin">
                          <i :class="showAadhar ? 'fa fa-minus' : 'fa fa-plus'" aria-hidden="true"
                            style="margin-right: 8px; margin-right: 8px;color: #7367f0;font-weight: bold;"></i>
                          <strong class="text-bolder" style="color: #6f6b7d">Why do you need Aadhar and PAN card
                            uploads?</strong>
                        </div>
                        <div>
                          <p v-if="showAadhar" class="padding-row">We require Aadhar and PAN card uploads for
                            identity verification and regulatory compliance.</p>
                        </div>
                      </div>
                      <div class="list-group-item cursor-pointer flex-row justify-content-between textAcco"
                        @click="toggle('secure')">
                        <div class="display-margin">
                          <i :class="showSecure ? 'fa fa-minus' : 'fa fa-plus'" aria-hidden="true"
                            style="margin-right: 8px; margin-right: 8px;color: #7367f0;font-weight: bold;"></i>
                          <strong class="text-bolder" style="color: #6f6b7d">Is my personal information secure?</strong>
                        </div>
                        <div>
                          <p v-if="showSecure" class="padding-row">Yes, we employ advanced encryption and secure
                            storage practices to protect your data.</p>
                        </div>
                      </div>
                      <div class="list-group-item cursor-pointer flex-row justify-content-between textAcco"
                        @click="toggle('privacy')">
                        <div class="display-margin">
                          <i :class="showPrivacy ? 'fa fa-minus' : 'fa fa-plus'" aria-hidden="true"
                            style="margin-right: 8px; margin-right: 8px;color: #7367f0;font-weight: bold;"></i>
                          <strong class="text-bolder" style="color: #6f6b7d">How is my privacy protected?</strong>
                        </div>
                        <div>
                          <p v-if="showPrivacy" class="padding-row">We prioritize the protection of your privacy by
                            adhering to strict data handling protocols and regulations.</p>
                        </div>
                      </div>
                      <div class="list-group-item cursor-pointer flex-row justify-content-between textAcco"
                        @click="toggle('dolor')">
                        <div class="display-margin">
                          <i :class="showDolor ? 'fa fa-minus' : 'fa fa-plus'" aria-hidden="true"
                            style="margin-right: 8px; margin-right: 8px;color: #7367f0;font-weight: bold;"></i>
                          <strong class="text-bolder" style="color: #6f6b7d">Lorem ipsum dolor sit amet consectetur,
                            adipisicing
                            elit.</strong>
                        </div>
                        <div>
                          <p v-if="showDolor" class="padding-row">Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Error
                            sequi adipisci minus harum quas, voluptatem voluptatum corporis porro odio aliquam
                            temporibus? Sint excepturi molestias soluta. Maiores amet molestias maxime harum.</p>
                        </div>
                      </div>
                      <div class="list-group-item cursor-pointer flex-row justify-content-between textAcco"
                        @click="toggle('amet')">
                        <div class="display-margin">
                          <i :class="showAmet ? 'fa fa-minus' : 'fa fa-plus'" aria-hidden="true"
                            style="margin-right: 8px; margin-right: 8px;color: #7367f0;font-weight: bold;"></i>
                          <strong class="text-bolder" style="color: #6f6b7d">Lorem ipsum dolor sit amet consectetur,
                            adipisicing
                            elit.</strong>
                        </div>
                        <div>
                          <p v-if="showAmet" class="padding-row">Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Error
                            sequi adipisci minus harum quas, voluptatem voluptatum corporis porro odio aliquam
                            temporibus? Sint excepturi molestias soluta. Maiores amet molestias maxime harum.</p>
                        </div>
                      </div>
                      <div class="list-group-item cursor-pointer flex-row justify-content-between textAcco"
                        @click="toggle('ipsum')">
                        <div class="display-margin">
                          <i :class="showIpsum ? 'fa fa-minus' : 'fa fa-plus'" aria-hidden="true"
                            style="margin-right: 8px; margin-right: 8px;color: #7367f0;font-weight: bold;"></i>
                          <strong class="text-bolder" style="color: #6f6b7d">Lorem ipsum dolor sit amet consectetur,
                            adipisicing
                            elit.</strong>
                        </div>
                        <div>
                          <p v-if="showIpsum" class="padding-row">Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Error
                            sequi adipisci minus harum quas, voluptatem voluptatum corporis porro odio aliquam
                            temporibus? Sint excepturi molestias soluta. Maiores amet molestias maxime harum.</p>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
                <!-- <div class="paddingTop pt-3">
                    <img src="../assets/3949134.jpg" alt="Description of the image" class="rounded6" />
                  </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="popup-container" v-if="hidePopup">
      <div class="popup">
        <div class="text-center text-error">
          <div>
            <h4><b>Error</b></h4>
          </div>
        </div>
        <div class="popup-text">
          <p>
            Name does not match as per profile details.Please reupload aadhaar
            or click the "Previous" button to update the information accurately.
          </p>

          <div class="d-flex justify-content-evenly text-center">
            <button class="btn btn-warning" @click="clickPrevious(this.apiId)">
              Go To Previous Page
            </button>
            <button class="btn btn-warning" @click="closePopup()">
              Upload Again
            </button>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <div class="navbar-nav-footer">
        <a class="nav-item nav-link active" href="#">
          <span class="material-symbols-outlined"> border_color </span>
          <span>Apply</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> group </span>
          <span>Users</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> article </span>
          <span>Docs</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> insert_chart </span>
          <span>Reports</span>
        </a>
        <a class="nav-item nav-link" href="#">
          <span class="material-symbols-outlined"> local_police </span>
          <span>Policy</span>
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import TopNav from '@/components/topNav.vue';
import Sidenav from '@/components/sidenav.vue';
// import Toasted from 'vue-toasted';
import {
  aadharMatchThreshold,
  panMatchThreshold,
} from "../../threshhold.config";

export default {
  name: "vcipDetails",
  data() {
    return {
      agree: false,
      panCard: {},
      errorMessage: null,
      aadharNameRatioFront: null,
      aadharNameFront: null,
      aadharDobFront: null,
      aadharNoFront: null,
      aadharNameRatioBack: null,
      panName: null,
      panName_ratio: null,
      panNumber: null,
      panDob: '',
      isFrontUploaded: false,
      isBackUploaded: false,
      errorForName: null,
      apiResponse: null,
      apiResponsAdhar: null,
      panCardImg: "",
      isModalVisible: false,
      isPanModalVisible: false,
      modalImage: '',
      aadharImage: null,
      adharCardImg: "",
      loader: false,
      invalidAadhaar: false,
      wrongAadhar: false,
      invalidPan: false,
      apiResName: null,
      hidePopup: false,
      apiId: null,
      apiFillFirstName: null,
      apiFilLastName: null,
      numericQualityRatio: 0,
      numericBackImageQualityRatio: 0,
      numericPanQualityRatio: 0,
      apiFullName: "",
      aadharMatch: null,
      panMatch: null,
      showAadhar: false,
      showSecure: false,
      showPrivacy: false,
      showDolor: false,
      showAmet: false,
      showIpsum: false,
      adharCardNumber: "",
      panCardNumber: "",
      image1: null,
      image2: null,
      image_quality_value: "70",
      panImageUrl: null,
      getAadharQuality: 0,
      uploadedPanQuality: 0,
    };
  },
  components: {
    TopNav,
    Sidenav,
  },
  mounted() {
    this.apiId = this.$route.query.id;
    document.title = "Vcip Details";
  },
  created() {
    this.apiId = this.$route.query.id;
    this.getDataById();
    this.getSetting();
  },
  methods: {
    // onPanCardChange(event) {
    //   debugger
    //   this.loader = true;
    //   const file = event.target.files[0];
    //   this.uploadPanCard(file);
    //   this.showImagePreview(file);
    // },

    // showImagePreview(file) {
    //   debugger
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     this.panImageUrl = reader.result; 
    //     this.loader = false;
    //   };
    //   reader.readAsDataURL(file);
    // },

    onPanCardChange(event) {
      this.loader = true;
      this.panCard = event.target.files[0];
      const file = event.target.files[0];
      if (file) {
        this.loader = true;
        this.checkPanImageQuality(file);
        // this.uploadPanCard(file);
        this.showImagePreview(file);
      }
    },
    showImagePreview(file) {
      this.panImageUrl = URL.createObjectURL(file);
      console.log("panImageUrl", this.panImageUrl);
      this.loader = false;
    },
    // uploadPanCard(file) {
    //   // Handle the file upload logic here
    //   console.log('Uploading file...', file);
    //   // For example, you might want to use an API to upload the file
    // },
    beforeDestroy() {
      // Revoke the object URL to free up memory
      if (this.panImageUrl) {
        URL.revokeObjectURL(this.panImageUrl);
      }
    },

    async uploadPanCard() {
      this.loader = true;
      const formData = new FormData();
      formData.append("panImage", this.panCard);

      console.log(this.panImageUrl)

      formData.append("panNameRatio", this.apiFullName);
      formData.append("_id", this.apiId);
      formData.append("status", 'Pan Uploaded Success');

      try {
        const scriptElement = document.querySelector("script[api-url]");
        const pythonApiUrl = scriptElement.getAttribute("python-api-url");
        const response = await axios.post(
          `${pythonApiUrl}/panUpload`,
          formData
        );

        if (response?.data?.error) {
          this.invalidPan = true;
          setTimeout(() => (this.invalidPan = false), 2000);
          this.$refs.pan.value = "";
        } else {
          this.$toast.open({
            message: 'Pan Card is uploaded successfully!',
            type: 'success'
          });
        }

        this.apiResponse = response.data;

        this.panCardNumber = this.apiResponse.panNumber;
        localStorage.setItem('panNumber', this.panCardNumber);
        this.panName_ratio = response.data.panName_ratio;
        this.panName = response.data.panName;
        this.panDob = response.data;

        this.panMatch = this.apiResponse;

        this.loader = false;
        console.log("API response:", this.apiResponse);
      } catch (error) {
        this.invalidPan = true;
        setTimeout(() => (this.invalidPan = false), 2000);
        this.$refs.pan.value = "";
        console.error("API error:", error);
        this.loader = false;
      }
    },

    handleFileUpload(event, type) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        if (type === 'front') {
          this.frontImage = file;
          this.invalidPanFront = false;
        } else if (type === 'back') {
          this.backImage = file;
          this.invalidPanBack = false;
        }
      } else {
        if (type === 'front') {
          this.invalidPanFront = true;
          this.frontImage = null;
        } else if (type === 'back') {
          this.invalidPanBack = true;
          this.backImage = null;
        }
      }
    },
    async uploadPanCardValue() {
      debugger
      const formData = new FormData();
      formData.append("image1", this.frontImage);
      formData.append("image2", this.backImage);
      formData.append("image_quality_value", this.imageQualityValue);

      try {
        this.loader = true;
        const scriptElement = document.querySelector("script[api-url]");
        const pythonApiUrl = scriptElement.getAttribute("python-api-url");
        const response = await axios.post(
          `${pythonApiUrl}/image_quality`,
          formData
        );

        if (response?.data?.error) {
          this.invalidPan = true;
          setTimeout(() => (this.invalidPan = false), 2000);
          this.$refs.panFront.value = "";
          this.$refs.panBack.value = "";
        } else {
          this.$toast.open({
            message: 'Image is uploaded successfully!',
            type: 'success'
          });
        }

        this.apiResponse = response.data;
        this.panCardNumber = this.apiResponse.panNumber;
        this.panMatch = this.apiResponse;

        this.loader = false;
        console.log("API response:", this.apiResponse);
      } catch (error) {
        this.invalidPan = true;
        setTimeout(() => (this.invalidPan = false), 2000);
        this.$refs.panFront.value = "";
        this.$refs.panBack.value = "";
        console.error("API error:", error);
        this.loader = false;
      }
    },
    submitData() {
      if (this.frontImage && this.backImage && this.imageQualityValue) {
        this.uploadPanCardValue();
      } else {
        this.errorMessage = 'Please upload both images and provide the image quality value.';
      }
    },



    onAdharCardChange(side) {
      const input = side === 'front' ? this.$refs.aadhaarFront : this.$refs.aadhaarBack;
      const file = input.files[0];
      if (file) {
        this.loader = true;
        if (side === 'front') {
          this.checkImageQuality(file, side);
        } else {
          this.checkBackImageQuality(file, side);
        }
      }
    },
    async checkImageQuality(image, side) {
      const formData = new FormData();
      formData.append("image", image);

      try {
        this.loader = true;
        await new Promise(resolve => setTimeout(resolve, 5000));

        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");

        const qualityResponse = await axios.post(`${apiUrl}/upload`, formData);

        const qualityRatio = qualityResponse.data.qualityRatio;
        this.numericQualityRatio = parseFloat(qualityRatio.replace('%', ''));
        console.log("qualityRatio", this.numericQualityRatio);

        if (this.numericQualityRatio < this.getAadharQuality) {
          this.$toast.error('Please upload a better quality image.', {
            timeout: 3000,
            position: 'top-right',
          });
          this.loader = false;
          this.$refs.aadhaarFront.value = "";
          return;
        } else {
          if (side === 'front') {
            this.aadharImageFront = image;
            this.previewFront = URL.createObjectURL(image);
          }
          this.uploadAdharCard(side);
        }

      } catch (error) {
        console.error('Error checking image quality:', error);
        this.loader = false;
      }
    },
    async checkBackImageQuality(image, side) {
      const formData = new FormData();
      formData.append("image", image);

      try {
        this.loader = true;
        await new Promise(resolve => setTimeout(resolve, 5000));

        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");

        const qualityResponse = await axios.post(`${apiUrl}/upload`, formData);

        const qualityRatio = qualityResponse.data.qualityRatio;
        this.numericBackImageQualityRatio = parseFloat(qualityRatio.replace('%', ''));
        console.log("qualityRatio", this.numericBackImageQualityRatio);

        if (this.numericBackImageQualityRatio < this.getAadharQuality) {
          this.$toast.error('Please upload a better quality image.', {
            timeout: 3000,
            position: 'top-right',
          });
          this.loader = false;
          this.$refs.aadhaarBack.value = "";
          return;
        } else {
          this.aadharImageBack = image;
          this.previewBack = URL.createObjectURL(image);
          this.uploadAdharCard(side);
        }

      } catch (error) {
        console.error('Error checking image quality:', error);
        this.loader = false;
      }
    },
    async checkPanImageQuality(image) {
      const formData = new FormData();
      formData.append("image", image);

      try {
        this.loader = true;
        await new Promise(resolve => setTimeout(resolve, 5000));

        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");

        const qualityResponse = await axios.post(`${apiUrl}/upload`, formData);

        const qualityRatio = qualityResponse.data.qualityRatio;
        this.numericPanQualityRatio = parseFloat(qualityRatio.replace('%', ''));
        console.log("qualityRatio", this.numericPanQualityRatio);

        if (this.numericPanQualityRatio < this.uploadedPanQuality) {
          this.$toast.error('Please upload a better quality image.', {
            timeout: 3000,
            position: 'top-right',
          });
          this.loader = false;
          this.$refs.pan.value = "";
          return;
        } else {
          this.panImageUrl = image;
          this.previewPan = URL.createObjectURL(image);
          this.uploadPanCard(image);
        }

      } catch (error) {
        console.error('Error checking image quality:', error);
        this.loader = false;
      }
    },
    async uploadAdharCard(side) {
      const formData = new FormData();
      const image = side === 'front' ? this.aadharImageFront : this.aadharImageBack;

      formData.append("aadharImage", image);
      formData.append("aadharNameRatio", this.apiFullName);
      formData.append("_id", this.apiId);
      formData.append("status", 'Aadhar Uploaded Success');

      try {
        const scriptElement = document.querySelector("script[api-url]");
        const pythonApiUrl = scriptElement.getAttribute("python-api-url");
        const response = await axios.post(`${pythonApiUrl}/aadharUpload`, formData);

        if (response?.data?.error) {
          this.invalidAadhaar = true;
          this.wrongAadhar = true;
          setTimeout(() => (this.invalidAadhaar = false), 5000);
          this.$toast.error('Invalid Aadhar!', {
            timeout: 5000,
            position: 'top-right',
          });
          this.$refs.aadhaarFront.value = "";
          this.$refs.aadhaarBack.value = "";
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          this.$toast.open({
            message: 'Aadhaar Card uploaded successfully!',
            type: 'success'
          });
          if (side === 'front') {
            this.aadharNameRatioFront = response.data.aadharNameRatio;
            this.aadharNameFront = response.data.aadharName;
            this.aadharDobFront = response.data;
            this.aadharNoFront = response.data.aadharNumber;
            this.isFrontUploaded = true;
          } else {
            this.aadharNameRatioBack = response.data.aadharNameRatio;
            this.isBackUploaded = true;
          }
        }

        this.loader = false;
      } catch (error) {
        this.invalidAadhaar = true;
        this.wrongAadhar = true;
        setTimeout(() => (this.invalidAadhaar = false), 5000);
        this.$toast.error('Failed to upload Aadhaar.', {
          timeout: 5000,
          position: 'top-right',
        });
        console.error("API error:", error);
        this.loader = false;
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    },
    showImageInModal(imageSrc) {
      console.log('Image clicked:', imageSrc);
      this.modalImage = imageSrc;
      this.isModalVisible = true;
      console.log('Modal visibility:', this.isModalVisible);
    },
    showPanImageInModal(imageSrc) {
      console.log('Image clicked:', imageSrc);
      this.modalImage = imageSrc;
      this.isPanModalVisible = true;
      console.log('Modal visibility:', this.isPanModalVisible);
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async Adhaarsave() {
      debugger;

      const id = this.$route.query.id;

      // const convertImageUrlToBase64 = async () => {
      //   const fileInput = document.querySelector('input[type=file]');
      //   const file = fileInput.files[0];

      //   if (!file) {
      //     console.error('No file selected.');
      //     return;
      //   }

      //   return new Promise((resolve, reject) => {
      //     const reader = new FileReader();

      //     reader.onloadend = () => {
      //       const imageUrl = reader.result;
      //       resolve(imageUrl);
      //     };

      //     reader.onerror = reject;

      //     reader.readAsDataURL(file);
      //   });
      // };

      const updateData = {
        adharCardNumber: this.aadharNoFront,
        panCardNumber: this.panCardNumber,
        panImageUrl: this.previewPan
      };

      try {


        // const base64Image = await convertImageUrlToBase64(); 
        // updateData.panImageUrl = base64Image;


        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");


        const response = await axios.put(
          `${apiUrl}/case/update/${id}`,
          updateData
        );

        console.log("Update response:", response);

        if (response.data.message === "Case successfully updated") {
          console.log("Update success: Case successfully updated");
        } else {
          console.error("Update failed:", response.data.message);
        }
      } catch (error) {
        console.error("API call error:", error);
      }
    },

    async updateCaseDetails() {

      if (!this.panCard || !this.previewFront || !this.previewBack) {
        this.errorMessage = "Both card field is required.";
        setTimeout(() => (this.errorMessage = ""), 2000);
        return;
      }

      try {
        this.loader = true;
        const id = this.$route.query.id;

        this.loader = false;
        console.log("aadhar", aadharMatchThreshold.valid);
        console.log("pan", panMatchThreshold.valid);
        this.Adhaarsave();
        this.navigateToSelectDetails(id);

        // console.log(this.aadharMatch.aadharNameRatio);
        // console.log(this.panMatch.aadharNameRatio);
        // if (
        //   this.aadharMatch.aadharNameRatio > aadharMatchThreshold.valid ||
        //   this.panMatch.aadharNameRatio > panMatchThreshold.valid
        // ) {
        //   this.navigateToSelectDetails(id);
        // } else {
        //   // this.errorForName = "Name does't match as aadhar name";
        //   // setTimeout(() => (this.errorForName = ""), 2000);
        //   this.hidePopup = true;
        // }
      } catch (error) {
        console.error("Update error:", error);
        this.loader = false;
      }
    },
    async getSetting() {
      try {
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.get(`${apiUrl}/setting/getSetting`);
        const data = response.data.settings[0];
        console.log("setting Response:", data);
        this.getAadharQuality = data.aadhaarQuality;
        this.uploadedPanQuality = data.uploadedPanQuality;

        console.log("aadhaarQuality", this.getAadharQuality);
        console.log("uploadedPanQuality", this.uploadedPanQuality);

      } catch (error) {
        console.error("API Error:", error);
      }
    },
    async getDataById() {
      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/case/getById/${id}`);
        const data = response.data;
        console.log("API Response:", data);
        this.apiFillFirstName = data.personalDetails.name;
        this.apiFilLastName = data.personalDetails.lastname;
        this.apiFullName = this.apiFillFirstName + " " + this.apiFilLastName;
        console.log("full name", this.apiFullName)

        console.log(this.apiFillFirstName.trim().toLowerCase());
        console.log(this.apiFilLastName.trim().toLowerCase());
      } catch (error) {
        console.error("API Error:", error);
      }
    },
    navigateToSelectDetails(id) {
      this.$router.push("/selectSlot?id=" + id);
    },
    clickPrevious(id) {
      this.$router.push("/additionalDetails?id=" + id);
      this.hidePrevious = false;
    },
    closePopup() {
      this.$refs.aadhaar.value = "";
      this.$refs.pan.value = "";
      this.hidePopup = false;
    },
    toggle(section) {
      if (section === 'aadhar') {
        this.showAadhar = !this.showAadhar;
      } else if (section === 'secure') {
        this.showSecure = !this.showSecure;
      }
      else if (section === 'privacy') {
        this.showPrivacy = !this.showPrivacy;
      } else if (section === 'dolor') {
        this.showDolor = !this.showDolor;
      } else if (section === 'amet') {
        this.showAmet = !this.showAmet;
      } else if (section === 'ipsum') {
        this.showIpsum = !this.showIpsum;
      }
    },
    goBack() {
      // Go back using Vue Router
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  /* Ensure the overlay is on top */
}

.btn-pupup {
  background-color: rgb(201, 222, 245);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
}

.popup-text {
  background-color: rgb(201, 222, 245);
  margin-top: 13px;
  padding: 13px;
}

.pop-up {
  width: 35%;
  height: 30%;
  background-color: red;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  z-index: 999;
  /* Ensure the overlay is on top */
}

.popup {
  border-radius: 10px;
  background: white;
  max-width: 35%;
  padding: 1.5%;
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

h2 {
  font-weight: 600;
  font-size: 1rem;
  margin-top: 2rem;
  color: black;
}

.form-label {
  margin-bottom: 0.2rem;
}

.form-control {
  background-color: white;
  border: 1px solid #b7b7b7;
}

.process-chart-mobile {
  display: none;
}

.navbar {
  padding: 0.5rem 3rem;
}

.navbar-brand {
  font-size: xx-large;
  font-weight: 600;
}

.nav-item {
  align-items: center;
  font-size: small;
}

.form-section {
  position: relative;
  padding: 1rem;
  background-color: #f8f7fa;
  min-height: 100vh;
}

.heading-title-circle,
.heading-title {
  padding-right: 2rem;
}

.b-w-msg {
  text-align: start;
  font-size: x-large;
  font-weight: bold;
  margin-top: 3rem;
}

.row {
  justify-content: center;
}

.page-form {
  padding-block: 2rem;
  padding-inline: 4rem;
}

.form-heading {
  padding-left: 20px;
  background-color: white;
  border-radius: 8px;
}

.progress {
  height: 3px;
  margin: -27px 2rem 20px 2rem;
  background-color: rgb(184, 183, 183);
}

.progress-bar {
  width: 50%;
  background-color: black;
}

.process-chart {
  padding: 15px;
}

.input-2 {
  display: flex;
}

.b-box {
  margin: 0px auto;
  width: 30%;
  margin-bottom: 20px;
}

.heading-title {
  color: black;
  list-style: none;
  display: flex;
  font-size: x-large;
  font-weight: 600;
  padding: 0;
}

.form-check-label {
  color: black;
  /* Change the color to your preference */
}

.heading-title-circle {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.vcip {
  margin-left: 4rem;
}

.btn.btn-primary {
  width: 100%;
  background-color: #7367f0;
  border: 2px solid #7367f0;
  margin-top: 1.5rem;
}

.nav-item {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

form {
  padding: 2rem;
  padding-top: 0rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading-title-circle li {
  width: 17px;
  height: 17px;
  background-color: white;
  border: 2px solid grey;
  border-radius: 50%;
  z-index: 1;
}

.heading-title-circle li.active {
  border: 5px solid black;
  border-radius: 50%;
}

.v-t {
  margin-top: 10px;
  margin-bottom: 2px;
  text-align: start;
}

.form-check {
  display: flex;
  gap: 10px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.input-box .input-1 {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  color: #202124;
  border: 1px solid rgb(0, 0, 0, 0.6);
  padding: 15px;
  transition: 250ms;
}

.btn.btn-primary {
  margin-bottom: 0.85rem;
}

#agreeCheckbox:checked {
  accent-color: #202124;
}

.checkbox-label {
  margin-bottom: 1rem;
}

footer {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  h2 {
    font-weight: bold;
    font-size: large;
    color: black;
    margin-block: 2rem;
  }

  .form-section {
    position: relative;
    padding: 0px 0px;
    background-color: #f8f7fa;
    min-height: auto;
  }

  .process-chart {
    display: none;
  }

  .form-container {
    min-height: 70vh;
    margin-top: 9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  form {
    padding-inline: 0.5rem;
  }

  .b-w-msg {
    margin-top: 1rem;
  }

  .v-t {
    margin-top: 1rem;
  }

  .heading-title-circle,
  .heading-title {
    padding-left: 1.2rem;
  }

  .process-chart-mobile {
    display: block;
    position: fixed;
    background-color: rgb(244, 244, 244);
    z-index: 1;
    width: 100%;
    top: 0;
  }

  .heading-title-circle,
  .heading-title {
    padding-right: 0rem;
    padding-left: 1rem;
  }

  .heading-title li {
    font-size: xx-large;
    padding: 1rem;
    margin-right: -1.5rem;
  }

  .heading-title-circle li {
    width: 17px;
    margin-left: 1rem;
    margin-right: -0.5rem;
  }

  .progress.mobile {
    height: 3px;
    margin: -27px 19rem 20px 2rem;
    background-color: rgb(184, 183, 183);
    width: 90%;
  }

  .progress-bar.mobile {
    width: 100%;
  }

  .btn-submit-div {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    right: 2rem;
  }

  .heading-title-circle .li3 {
    margin-left: 2rem;
  }

  .form-check-label {
    font-size: 1.1rem;
  }

  .form-check {
    margin-block: 1rem;
  }

  .btn.btn-primary {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .vcip {
    margin-left: -6rem;
  }

  footer {
    display: block;
    height: 100%;
  }

  .submit-btn-checbox {
    margin-top: 15rem;
  }

  .navbar-nav-footer {
    display: flex;
    column-gap: 22px;
    justify-content: space-between;
    flex-direction: row;
    position: fixed;
    padding: 1rem;
    bottom: 0;
    background-color: rgb(244, 244, 244);
    width: 100%;
  }

  .navbar-nav-footer .nav-link {
    color: grey;
  }

  .navbar-nav-footer .nav-link :nth-child(2) {
    font-size: small;
    font-weight: bold;
  }

  .navbar-nav-footer .nav-link.active {
    color: black;
  }

  .material-symbols-outlined {
    font-size: 34px;
  }
}

.error-message {
  color: red;
}

.rounded6 {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.Fifty3 {
  width: 53%;
}

.fontSize12px {
  font-size: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.btn.btn-primary[data-v-0d4e262e] {
  width: 100%;
  background-color: #7367f0;
  border: 2px solid #7367f0;
  margin-top: 0rem !important;
}

.styleFont {
  font-size: 13px;
  font-weight: 900;
  color: #000000bd;
}

.widthLess {
  font-weight: 300;
}

.w-40 {
  width: 48%;
}

.textAcco {
  font-size: 11px;
  color: #6f6b7d;
  padding: 0.5rem 0.5rem !important;
  border-width: 1px 1px 1px 5px;
  border-style: solid;
  border-color: #7367f0;
  border-image: initial;
  border-radius: 6px;
}

.vColour {
  color: #198754cf;
  font-size: 22px !important;
}

.justify-nav {
  justify-content: space-evenly;
}

.text-size {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #a5a3ae !important;
}

.text-lorem {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 36px !important;
  color: rgb(93, 89, 108) !important;
}

.padding-content {
  padding: 8px 0px 8px 0px;
}

.card-title {
  padding: 8px 0px 8px 0px;
}

.card-text {
  padding: 6px 0px 6px 0px;
}

.shadow {
  background-clip: padding-box;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
  padding: 1rem 2rem 2rem 2rem;
}

.display-margin {
  margin-bottom: 8px;
  margin-top: 8px;
}

.text-bolder {
  color: #6f6b7d !important;
  font-size: .82rem;
}

.padding-row {
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: 12px;
}

/* Styles for Modal */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Dark background with slight opacity */
  backdrop-filter: blur(5px);
  /* Adds a subtle blur to the background */
}

.modal-content {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  /* Smaller width for a more compact modal */
  position: relative;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
  /* Smooth fade-in animation */
}

.full-image {
  max-width: 100%;
  border-radius: 8px;
  /* Slightly rounded corners for the image */
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #333;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.2s ease;
}

.close:hover,
.close:focus {
  color: #f00;
  /* Change close button color on hover */
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.justify-between {
  justify-content: space-evenly !important;
  align-items: center !important;
}
</style>
