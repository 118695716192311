<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light-text">
        <!-- <a class="navbar-brand" href="#">vCIP</a> -->
        <div class="app-brand"><img src="../assets/vcip.png" alt="" style="width: 45%;"></div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" style="justify-content: flex-end" id="navbarNavAltMarkup">
            <div class="navbar-nav" style="column-gap: 15px">
                <a class="nav-item nav-link active" href="#">
                    <!-- <span class="material-symbols-outlined"> border_color </span> -->
                    <i class="ti ti-marker-alt" style="font-size: 20px;"></i>
                    <span>Apply</span>
                </a>
                <a class="nav-item nav-link" href="#">
                    <!-- <span class="material-symbols-outlined"> group </span> -->
                    <i class="ti ti-user" style="font-size: 20px;"></i>
                    <span>Users Profile</span>
                </a>
                <a class="nav-item nav-link" href="#" @click="logout">
                    <i class="ti ti-export" style="font-size: 20px;"></i>
                    <span>Logout</span>
                </a>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    data() {
        return {

        };
    },
};
</script>

<style scoped>
h2 {
  font-weight: 600;
  font-size: 1rem;
  margin-top: 2rem;
  color: black;
}

.form-label {
  margin-bottom: 0.2rem;
}

.form-control {
  display: block;
    width: 100%;
    padding: 0.422rem 0.875rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6f6b7d;
    appearance: none;
    background-color: #fff;
    background-clip: padding-box;
    border: var(--bs-border-width) solid #dbdade;
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.process-chart-mobile {
  display: none;
}

.navbar {
  position: sticky !important;
  top: 0 !important;
  z-index: 1000 !important;
  padding: 0.5rem 3rem;
}

.navbar-brand {
  font-size: xx-large;
  font-weight: 600;
}

.nav-item {
  align-items: center;
  font-size: small;
}

.form-section {
  position: relative;
  padding: 1rem;
  background-color: #f8f7fa;
  min-height: 100vh;
}

.heading-title-circle,
.heading-title {
  padding-right: 2rem;
}

.b-w-msg {
  text-align: start;
  font-size: x-large;
  font-weight: bold;
  margin-top: 3rem;
}

.row {
  justify-content: center;
}

.page-form {
  padding-block: 2rem;
  padding-inline: 4rem;
}

.form-heading {
  padding-left: 20px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 100px;
}

.progress {
  height: 3px;
  margin: -27px 2rem 20px 2rem;
  background-color: rgb(184, 183, 183);
}

.progress-bar {
  width: 50%;
  background-color: #7367f0;
}

.process-chart {
  padding: 15px;
}

.input-2 {
  display: flex;
}

.b-box {
  margin: 0px auto;
  width: 30%;
  margin-bottom: 20px;
}

.heading-title {
  color: black;
  list-style: none;
  display: flex;
  justify-content: space-between;
  font-size: x-large;
  font-weight: 600;
}

.form-check-label {
  color: black;
  /* Change the color to your preference */
}

.heading-title-circle {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.vcip {
  margin-left: 4rem;
}

.nav-item {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}

form {
  padding: 2rem;
  padding-top: 0rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading-title-circle li {
  width: 17px;
  height: 17px;
  background-color: white;
  border: 2px solid grey;
  border-radius: 50%;
  z-index: 1;
}

.heading-title-circle li.active {
  border: 5px solid #7367f0;
  border-radius: 50%;
}

.v-t {
  margin-top: 10px;
  margin-bottom: 2px;
  text-align: start;
}

.form-check {
  display: flex;
  gap: 10px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.input-box .input-1 {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  color: #202124;
  border: 1px solid rgb(0, 0, 0, 0.6);
  padding: 15px;
  transition: 250ms;
}

.btn.btn-primary {
  margin-bottom: 0.85rem;
}

#agreeCheckbox:checked {
  accent-color: #202124;
}

.checkbox-label {
  margin-bottom: 1rem;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  z-index: 999;
  /* Ensure the overlay is on top */
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

footer {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  h2 {
    font-weight: bold;
    font-size: large;
    color: black;
    margin-block: 2rem;
  }

  .form-section {
    position: relative;
    padding: 0px 0px;
    background-color: white;
    min-height: auto;
  }



  .process-chart {
    display: none;
  }

  .form-container {
    min-height: 70vh;
    margin-top: 9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  form {
    padding-inline: 0.5rem;
  }

  .b-w-msg {
    margin-top: 1rem;
  }

  .v-t {
    margin-top: 1rem;
  }

  .heading-title-circle,
  .heading-title {
    padding-left: 1.2rem;
  }

  .process-chart-mobile {
    display: block;
    position: fixed;
    background-color: rgb(244, 244, 244);
    z-index: 1;
    width: 100%;
    top: 0;
  }

  .heading-title-circle,
  .heading-title {
    padding-right: 0rem;
    padding-left: 1rem;
  }

  .heading-title li {
    font-size: xx-large;
    padding: 1rem;
    margin-right: -1.5rem;
  }

  .heading-title-circle li {
    width: 17px;
    margin-left: 1rem;
    margin-right: -0.5rem;
  }

  .progress.mobile {
    height: 3px;
    margin: -27px 19rem 20px 2rem;
    background-color: rgb(184, 183, 183);
    width: 90%;
  }

  .progress-bar.mobile {
    width: 100%;
  }

  .btn-submit-div {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    right: 2rem;
  }

  .heading-title-circle .li3 {
    margin-left: 2rem;
  }

  .form-check-label {
    font-size: 1.1rem;
  }

  .form-check {
    margin-block: 1rem;
  }

  .btn.btn-primary {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .vcip {
    margin-left: -6rem;
  }

  footer {
    display: block;
    height: 100%;
  }

  .submit-btn-checbox {
    margin-top: 11rem;
  }

  .navbar-nav-footer {
    display: flex;
    column-gap: 22px;
    justify-content: space-between;
    flex-direction: row;
    position: fixed;
    padding: 1rem;
    bottom: 0;
    background-color: rgb(244, 244, 244);
    width: 100%;
  }

  .navbar-nav-footer .nav-link {
    color: grey;
  }

  .navbar-nav-footer .nav-link :nth-child(2) {
    font-size: small;
    font-weight: bold;
  }

  .navbar-nav-footer .nav-link.active {
    color: black;
  }

  .material-symbols-outlined {
    font-size: 34px;
  }
}

.error-message {
  color: red;
}

.rounded5 {
  border-radius: 20px;
  width: 500px;
  object-fit: cover;
  margin-top: 1rem;
  height: 450px;
}

.w40 {
  width: 47%;
}

h2[data-v-1e3b5c44] {
  font-weight: 600;
  font-size: 1rem;
  margin-top: 0.4rem !important;
  color: black;
}

.eight {
  /* width: 85% !important; */
}

.text-font {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 36px !important;
  color: rgb(93, 89, 108) !important;
}

.Basic-text-font {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 26px !important;
  line-height: 36px !important;
  color: #7367f0 !important;
}

.text-size {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #a5a3ae !important;
}

.card {
  background-clip: padding-box;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
}

.email-mask {
  margin-bottom: 0.25rem !important;
  font-size: 0.8125rem !important;
  color: #5d596c;
}

.btn.btn-primary {
  width: 100%;
  background-color: #7367f0;
  border: 2px solid #7367f0;
  margin-top: 1.5rem;
}

.link-muted {
  color: #7367f0;
}

/* .input-text {
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: rgb(111, 107, 125) !important;
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
} */

.display-gap {
  justify-content: space-evenly;
}

/* .text-font {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 1rem !important;
  line-height: 22px !important;
  color: #6f6b7d !important;
} */

.custom-nav-link {
  background-color: white;
  color: #6f6b7d;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s, color 0.3s;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.active-tab {
  background-color: #7367f0;
  color: white;
}

.custom-nav-link i {
  margin-right: 0.5rem;
}
.mr-li {
  margin: 0.125rem 0;
}
.bg-light-text {
  --bs-bg-opacity: 1;
  background-color: white !important;
  background-clip: padding-box;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
}
.border-color {
  border-color: snow;
}
</style>