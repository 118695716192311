<template>
  <section class="form-section">
    <div class="login-page">
      <header class="">
        <div class="vcip-heading d-flex">Identity check</div>
        <div class="acceptane-options-line">
          <div class="acceptance-items">
            <div class="circle active-circle active-check"><div>✔</div></div>
          </div>
          <div class="acceptance-items">
            <div class="circle active-circle">
              <div class="inner-circle"></div>
            </div>
          </div>
          <div class="acceptance-items">
            <div class="circle"><div class="inner-circle"></div></div>
          </div>
        </div>
        <div class="acceptance-options d-flex">
          <div class="active-btn">1</div>
          <div class="active-btn">2</div>
          <div>3</div>
        </div>
        <div class="login-box">
          <div class="vcip-options-div">
            <div>
              <div
                class="vcip-options"
                v-if="!caseData?.identityCheck?.uploadedPanQuality"
              >
                <div class="vcip-options-items">Uploaded PAN quality</div>
              </div>

              <div
                class="vcip-options"
                v-if="caseData?.identityCheck?.uploadedPanQuality"
              >
                <div class="vcip-options-items">
                  <span class="right-icon bg-good"
                    ><span class="material-symbols-outlined"> done </span></span
                  >
                  Uploaded PAN quality
                </div>
                <div class="vcip-options-items good">
                  Good &nbsp;&nbsp;
                  <span class="material-symbols-outlined"> chevron_right </span>
                </div>
              </div>
            </div>
            <!-- <div class="vcip-options">
              <div class="vcip-options-items">Uploaded PAN quality</div>
            </div> -->

            <div>
              <div
                class="vcip-options"
                v-if="!caseData?.identityCheck?.livePhotographQuality"
              >
                <div class="vcip-options-items">Live photograph quality</div>
              </div>

              <div
                class="vcip-options"
                v-if="caseData?.identityCheck?.livePhotographQuality"
              >
                <div class="vcip-options-items">
                  <span class="right-icon bg-good"
                    ><span class="material-symbols-outlined"> done </span></span
                  >
                  Live photograph quality
                </div>
                <div class="vcip-options-items good">
                  Good &nbsp;&nbsp;
                  <span class="material-symbols-outlined"> chevron_right </span>
                </div>
              </div>
            </div>
            <!-- <div class="vcip-options">
              <div class="vcip-options-items">Customer liveliness</div>
            </div> -->

            <div>
              <div
                class="vcip-options"
                v-if="!caseData?.identityCheck?.customerLiveliness"
              >
                <div class="vcip-options-items">Customer liveliness</div>
              </div>

              <div
                class="vcip-options"
                v-if="caseData?.identityCheck?.customerLiveliness"
              >
                <div class="vcip-options-items">
                  <span class="right-icon bg-good"
                    ><span class="material-symbols-outlined"> done </span></span
                  >
                  Customer liveliness
                </div>
                <div class="vcip-options-items good">
                  Good &nbsp;&nbsp;
                  <span class="material-symbols-outlined"> chevron_right </span>
                </div>
              </div>
            </div>
            <!-- <div class="vcip-options">
              <div class="vcip-options-items">Live photograph quality</div>
            </div> -->

            <div>
              <div
                class="vcip-options"
                v-if="!caseData?.identityCheck?.liveCapturedPanQuality"
              >
                <div class="vcip-options-items">Live captured PAN quality</div>
              </div>

              <div
                class="vcip-options"
                v-if="caseData?.identityCheck?.liveCapturedPanQuality"
              >
                <div class="vcip-options-items">
                  <span class="right-icon bg-good"
                    ><span class="material-symbols-outlined"> done </span></span
                  >
                  Live captured PAN quality
                </div>
                <div class="vcip-options-items good">
                  Good &nbsp;&nbsp;
                  <span class="material-symbols-outlined"> chevron_right </span>
                </div>
              </div>
            </div>
            <!-- <div class="vcip-options">
              <div class="vcip-options-items">Live captured PAN quality</div>
            </div> -->

            <div>
              <div
                class="vcip-options"
                v-if="!caseData?.identityCheck?.faceMatch"
              >
                <div class="vcip-options-items">Face match</div>
              </div>

              <div
                class="vcip-options"
                v-if="caseData?.identityCheck?.faceMatch"
              >
                <div class="vcip-options-items">
                  <span class="right-icon bg-good"
                    ><span class="material-symbols-outlined"> done </span></span
                  >
                  Face match
                </div>
                <div class="vcip-options-items good">
                  Good &nbsp;&nbsp;
                  <span class="material-symbols-outlined"> chevron_right </span>
                </div>
              </div>
            </div>

            <!-- <div class="vcip-options">
              <div class="vcip-options-items">Face match</div>
            </div> -->

            <div>
              <div
                class="vcip-options"
                v-if="!caseData?.identityCheck?.randomQuestionnaire"
              >
                <div class="vcip-options-items">Random questionnaire</div>
              </div>

              <div
                class="vcip-options"
                v-if="caseData?.identityCheck?.randomQuestionnaire"
              >
                <div class="vcip-options-items">
                  <span class="right-icon bg-good"
                    ><span class="material-symbols-outlined"> done </span></span
                  >
                  Random questionnaire
                </div>
                <div class="vcip-options-items good">
                  Good &nbsp;&nbsp;
                  <span class="material-symbols-outlined"> chevron_right </span>
                </div>
              </div>
            </div>
            <!-- <div class="vcip-options">
              <div class="vcip-options-items">Random questionnaire</div>
            </div> -->
          </div>
        </div>
      </header>
      <main>
        <div>
          <div class="login-button" v-if="!caseData?.identityCheck">
            <button
              class="login-form-button btn"
              @click="navigateToLiveIdentityCheck"
            >
              Proceed
            </button>
          </div>
          <div class="login-button" v-if="caseData?.identityCheck">
            <button
              class="login-form-button btn"
              @click="navigateToIdentyCheck()"
            >
              Next
            </button>
          </div>
        </div>
      </main>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "identityCheck",
  data() {
    return {
      caseData: {},
    };
  },

  created() {
    this.fetchCaseById();
  },
  mounted() {
    document.title = 'Identity Check';
   },

  methods: {
    navigateToIdentyCheck() {
      const id = this.$route.query.id;
      const endpoint = this.caseData?.selectedSteps?.Risk
        ? `riskMappingCheck?id=${id}`
        : `auditCheck?id=${id}`;
      this.$router.push(endpoint);
    },
    navigateToLiveIdentityCheck() {
      const id = this.$route.query.id;
      this.$router.push(`/liveIdentityCheck?id=${id}`);
    },
    async fetchCaseById() {
      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/case/getById/${id}`);
        this.caseData = response.data;
        console.log("Case data:", this.caseData);
      } catch (error) {
        console.error("Error fetching case data:", error);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.form-section {
  position: relative;
  padding: 40px 300px;
  background-color: #073991;
  min-height: 100vh;
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
}
.login-page {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 30px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
header.sticky-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #f4f4f4;
  height: 18vh;
}

main {
  overflow-y: auto;
}
.vcip-heading {
  font-size: x-large;
  font-weight: bold;
  text-align: start;
  margin-bottom: 20px;
}
.acceptane-options-line {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
  margin-bottom: 10px;
}
.acceptance-options {
  color: gray;
  font-weight: bold;
  justify-content: space-between;
  margin: 0px 10px;
  margin-left: 14px;
  margin-top: -20px;
  padding-top: 10px;
  background-image: linear-gradient(to right, #6a68db 50%, gray 50%);
  background-size: 100% 2px;
  background-repeat: no-repeat;
}
.acceptance-items {
  display: flex;
  align-items: center;
}
.circle {
  width: 10px;
  height: 10px;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.active-circle {
  background-color: #6a68db;
}
.active-check {
  width: 18px;
  height: 18px;
  color: white;
}
.active-btn {
  color: #6a68db;
}
.inner-circle {
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 50%;
}

.vcip-options {
  margin: 10px 0px;
  display: flex;
  background-color: #fcfcfc;
  padding: 15px;
  border-radius: 8px;
  border-bottom: 2px solid #d4d4d4;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.login-form-button {
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
  text-align: center;
  font-size: large;
  width: 100%;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }
  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
  }
  .vcip-options {
    padding: 15px 20px;
  }
  .vcip-options-items {
    font-size: small;
  }
}
.vcip-options-items {
  display: flex;
  align-items: center;
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 18;
  font-size: 20px;
}

.recheck {
  color: #f7b519;
}
.good {
  color: #009688;
}
.bg-recheck {
  background-color: #f7b519;
}
.bg-good {
  background-color: #009688;
}
.circle {
  width: 10px;
  height: 10px;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.right-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  margin-right: 10px;
}
.vcip-options {
  margin: 10px 0px;
  display: flex;
  background-color: #fcfcfc;
  padding: 15px;
  border-radius: 8px;
  border-bottom: 2px solid #d4d4d4;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  /* Box shadow */
}
</style>
