<template>
  <section class="form-section">
    <div class="login-page">
      <div>
        <div class="login-header p-2">
          <div class="r-live d-flex">
            <div class="r-icon text-black">
              <span class="material-symbols-outlined">
                arrow_back_ios_new
              </span>
            </div>
            <div class="r-live-h fw-bold mx-2">Audit check</div>
          </div>
          <div class="p-heading">
            <small> Your recorded VCIP video will be forwarded for concurrent Audit </small>
          </div>
        </div>
      </div>
      <div class="i mt-2">
        <div class="login-button">
          <button type="submit" class="btn btn-primary" @click="navigateToSendingFile">Submit</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "auditCheck",
  mounted() {
    document.title = 'Audit Check';
    this.liveAcceptanceCheck();
   },
  methods : {
    async liveAcceptanceCheck() {
      const updateData = {      
        status:"Auditor_Approved"
      };
      try {      
        const id = this.$route.query.id; 
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.put(
          `${apiUrl}/case/update/${id}`,
          updateData
        );
        console.log("id", id);
        console.log("liveAcceptanceCheck response:", response);
      } catch (error) {
        console.error("Update error:", error);
      }
    },
    navigateToSendingFile(){
      const id = this.$route.query.id;
      this.$router.push("/sendingFile?id=" + id);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
hr {
  margin: 5px 0px;
}
.form-section {
  position: relative;
  padding: 40px 300px;
  background-color: #073991;
  min-height: 100vh;
}
.login-page {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 30px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
}

.camera {
  width: 100%;
  height: 50vh;
  background-color: #39bb8f;
  padding: 2rem;
  border-radius: 8px;
  align-items: flex-end;
}

.btn.btn-primary {
  width: 100%;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  margin-top: 1.5rem;
}

.av-test {
  width: 100%;
  background-color: white;
  height: 10vh;
  align-items: center;
  padding-inline: 1rem;
  border-bottom: 2px solid #e6e6e6;
}
.av-div1 {
  width: 100%;
}
.av-test-btn {
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
}

.i-h {
  color: grey;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }
  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 1rem;
  }
  .camera {
    height: 30vh;
    border-radius: 10px;
    margin-bottom: 2rem;
  }
  .login-header{
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: larger;
  }
}
</style>
